import React from "react";
import PageTitle from "./../../Components/Uitily/PageTitle";
import { BsSearch } from "react-icons/bs";
import { AiOutlineSync } from "react-icons/ai";
const ExcelReciptPage = () => {
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <PageTitle title="ارسال الايصالات من اكسل" />
          <div className="row pb-4">
            <div className="col-sm-6 mb-3 mb-sm-0">
              <button className="btn main-btn">تحميل اكسيل</button>
            </div>
            <div className="col-sm-6">
              <div className="search-box">
                <BsSearch className="search-icon" />
                <input
                  type="text"
                  className="form-control"
                  id="searchMemberList"
                  placeholder="ابحث برقم الايصال "
                />
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <div>
                <input type="checkbox" id="all" name="all" className="ms-3" />
                <label htmlFor="all">تحديد الكل</label>
              </div>
              <hr />
              <div>
                <input type="checkbox" id="33" name="33" className="ms-3" />
                <label htmlFor="33"> 33</label>
              </div>
              <div>
                <input type="checkbox" id="17" name="17" className="ms-3" />
                <label htmlFor="17"> 17</label>
              </div>
              <div>
                <input type="checkbox" id="19" name="19" className="ms-3" />
                <label htmlFor="19"> 19</label>
              </div>
            </div>
          </div>
          <div className="card  mt-4">
            <div className="card-body">
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Recipt id</th>
                    <th scope="col">Date</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Exchange Rate</th>
                    <th scope="col">Item Name</th>
                    <th scope="col">Item Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Recipt id</td>
                    <td>Date</td>
                    <td>Currency</td>
                    <td>Exchange Rate</td>
                    <td>Item Name</td>
                    <td>Item Code</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <div className="d-flex p-4 flex-wrap pt-0">
              <p className="ms-2">
                العميل : <strong>محمد احمد</strong>
              </p>
              <p className="ms-2">
                الصافى : <strong> 1120</strong>
              </p>
              <p className="ms-2">
                اجمالى الايصال : <strong> 1120</strong>
              </p>
              <p className="ms-2">
                اجمالى الضريبة : <strong>0.00</strong>
              </p>
              <p className="ms-2">
                العملة : <strong>EGP</strong>
              </p>
            </div>
          </div>

          <button className=" mt-2 btn-custom">ارسال</button>
        </div>
      </div>
    </>
  );
};

export default ExcelReciptPage;
