import React from "react";

import logo from "../../images/logo-white.png";
const Footer = () => {
  return (
    <footer className="footer">
      <p>جميع الحقوق محفوظة </p>
      <img src={logo} alt="Skylink" />
    </footer>
  );
};

export default Footer;
