 const ClearDecimalPlaces = (data) => {
    const cleanedData = JSON.parse(JSON.stringify(data), (key, value) => {
        if (typeof value === 'number') {
          return parseFloat(value.toFixed(5));
        }
        return value;
      });
    return cleanedData;
}

export default ClearDecimalPlaces;