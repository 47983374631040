import React, { useState } from "react";
import axios from "axios";
import PageTitle from "./../../Components/Uitily/PageTitle";
import baseUrl from "../../Api/baseUrl";
export default function SearchCodingPage() {
  let companyId = localStorage.getItem("companyId");
  let [invoices, setInvoices] = useState([]);

  let [isLoading, setisLoading] = useState(false);
  let [disbaled, setDisbaled] = useState(false);
  let [internalinvoice, setInternalinvoice] = useState({
    companyId,
    codeName: "",
    codeType: "GS1",
    CodeID: "",
    CodeDescription: "",
  });
  function getDatainvoice(e) {
    let currentInvoice = { ...internalinvoice };
    currentInvoice[e.target.name] = e.target.value;
    setInternalinvoice(currentInvoice);
  }
  async function searchCode(e) {
    e.preventDefault();
    try {

      setisLoading(true);
      setDisbaled(true);
      const { data } = await baseUrl.post(
        `/invoices/searchPublishedCode`,
        internalinvoice,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setInvoices(data.data);

      if (data.status === "fail") {
        setInvoices([]);
      }

    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = "/"; // Redirect to home page
      } else {
        console.error(error);
      }
    } finally {
      setisLoading(false);
      setDisbaled(false);
    }
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <PageTitle title="البحث على المنتجات" />
          <form onSubmit={searchCode}>
            <div className="row ">
              <div className="col-xl-2 col-lg-12">
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control border-info"
                      id="name"
                      placeholder="الاسم "
                      name="codeName"
                      onChange={getDatainvoice}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-lg-12">
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <select
                      className="form-select border-info"
                      id="codeType"
                      name="codeType"
                      onChange={getDatainvoice}>
                      <option value="GS1">GS1</option>
                      <option value="EGS">EGS</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-12">
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control border-info"
                      id="CodeID"
                      name="CodeID"
                      placeholder="GPC Code "
                      onChange={getDatainvoice}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-12">
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control border-info"
                      id="CodeDescription"
                      placeholder="الوصف"
                      name="CodeDescription"
                      onChange={getDatainvoice}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-12">
                {disbaled ? (
                  <button
                    disabled={disbaled}
                    className="mt-2 btn logout-btn btn-custom-disable ">
                    <i className="fa fa-spinner fa-spin"></i>
                  </button>
                ) : (
                  <button className="mt-2 btn logout-btn">بحث</button>
                )}
              </div>
            </div>
          </form>

          <div className="card  mt-4">
            <div className="card-body">
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col">GPC</th>
                    <th scope="col">GPC</th>
                    <th scope="col">الاسم</th>
                    <th scope="col">وصف</th>
                    <th scope="col">الفئة</th>
                    <th scope="col">النوع</th>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>

                {isLoading ? (
                  <tbody>
                    <tr>
                      <td className="d-flex mt-5">
                        <i className="fa fa-spinner fa-spin spinner-table"></i>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {invoices.map((invoice) => (
                      <tr key={invoice.codeID}>
                        <td>
                          {invoice.parentCodeLookupValue
                            ? invoice.parentCodeLookupValue
                            : "------"}
                        </td>
                        <td>
                          {invoice.codeLookupValue
                            ? invoice.codeLookupValue
                            : "-----"}
                        </td>
                        <td>
                          {invoice.codeNameSecondaryLang
                            ? invoice.codeNameSecondaryLang
                            : "-----"}
                        </td>
                        <td>
                          {invoice.codeDescriptionSecondaryLang
                            ? invoice.codeDescriptionSecondaryLang
                            : "-----"}
                        </td>
                        <td>
                          {invoice.codeTypeNamePrimaryLang
                            ? invoice.codeTypeNamePrimaryLang
                            : "-----"}
                        </td>
                        <td>
                          {invoice.codeTypeNamePrimaryLang
                            ? invoice.codeTypeNamePrimaryLang
                            : "-----"}
                        </td>
                        <td>
                          {invoice.codeNamePrimaryLang
                            ? invoice.codeNamePrimaryLang
                            : "-----"}
                        </td>
                        <td>
                          {invoice.codeDescriptionPrimaryLang
                            ? invoice.codeDescriptionPrimaryLang
                            : "-----"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
