import React, { useState, useEffect } from "react";
import axios from "axios";
import PageTitle from "./../Uitily/PageTitle";
import { useParams } from "react-router-dom";
import notify from "./../../hook/useNotifaction";
import baseUrl from "./../../Api/baseUrl";
import { useNavigate } from "react-router-dom";
import Select  from "react-select";
const EditProduct = () => {
  let Navigate = useNavigate();
  const { id } = useParams();
  let companyId = localStorage.getItem("companyId");
  let [getunits, setGetunits] = useState([]);
  let [isLoading, setisLoading] = useState(false);
  let [disbaled, setDisbaled] = useState(false);
  let [pageLoading, setPageLoading] = useState(false);
  async function getUnits(e) {
    let { data } = await baseUrl.get(`/json/getUnits`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    let filtered= [];
    data.data.map(el=> {
      if(!filtered.includes(el)){
      filtered.push(el)
    }})
    setGetunits(data.data);
  }

  let unitDesarray = [];
  getunits.forEach((item) => {
    unitDesarray.push(item.desc_en);
  });
  const element = [];
  unitDesarray.forEach((ele) => {
    const regex = /\((.*?)\)/g;
    const matches = ele.match(regex);
    if (matches) {
      matches.forEach((match) => {
        const desstring = match.slice(1, -1);
        element.push(desstring);
      });
    }
  });

let unitsOptions = getunits.map(el=>({
    value: el.code,
    label:el.desc_en
  }))

  const [editProduct, setEditProduct] = useState({
    itemName: "",
    itemPrice: "",
    itemCode: "",
    unitType: "EA",
    itemBarcode: "",
    description: "",
    companyId: companyId,
  });
  async function getDataProduct() {
    setPageLoading(true);
    try {
      const { data } = await baseUrl.get(`/products/getProduct/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setEditProduct({
        itemName: data.data.itemName,
        itemCode: data.data.itemCode,
        itemPrice: data.data.itemPrice,
        unitType: data.data.unitType,
        itemBarcode: data.data.itemBarcode,
        description: data.data.description,
        companyId: companyId,
      });
      console.log(data);
      setPageLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDataProduct();
    getUnits();
  }, []);

  function changeDataProduct(e) {
    let currentProduct = { ...editProduct };
    currentProduct[e.target.name] = e.target.value;
    setEditProduct(currentProduct);
    console.log(currentProduct);
  }
  const  handleUniteChange=(e)=>{
    let currentproduct = {...editProduct};
    currentproduct['unitType'] = e.value;
    setEditProduct(currentproduct)
  }
  async function updateProduct(e) {
    e.preventDefault();
    setDisbaled(true);
    setisLoading(true);

    let data = await baseUrl
      .put(`/products/updateProduct/${id}`, editProduct, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((data) => {
        if (data.status === 200) {
          notify("تم تعديل المنتج بنجاح", "success");
          setTimeout(() => {
            setisLoading(false);
            setDisbaled(false);
            Navigate("/Product");
          }, 1000);
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.map((error) => notify(error.msg, "error"));
          setisLoading(false);
          setDisbaled(false);
        } else if (error.response.data.statusCode == "401") {
          Navigate("/");
        }
      });

    //   if(data.message == "success"){
    //     notify("تم تعديل المنتج بنجاح", "success");
    //     setTimeout(() => {
    //       setisLoading(false);
    //       setDisbaled(false);
    //       Navigate("/Product");
    //     }, 1000);

    // }else  {
    //  response.data.errors.map((error) => notify(error.msg, "error"));
    //     setisLoading(false);
    //    setDisbaled(false);      }
    console.log(data);
  }
  return (
    <div className="page-content">
      {!pageLoading ? (
        <div className="container-fluid">
          <div className="d-flex">
            <PageTitle title="تعديل صنف" />
            <div className="me-2">
              {isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}
            </div>
          </div>
          <div className="card ">
            <div className="card-body m-3">
              <form onSubmit={updateProduct}>
                <div className="row ">
                  <div className="col-12">
                    <div className="row mb-3">
                      <label
                        htmlFor="itemName"
                        className="col-sm-2 col-form-label"
                      >
                        الاسم{" "}
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          id="ProductName"
                          placeholder="الاسم"
                          name="itemName"
                          value={editProduct.itemName}
                          onChange={changeDataProduct}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row mb-3">
                      <label
                        htmlFor="ProductPrice"
                        className="col-sm-2 col-form-label"
                      >
                        السعر{" "}
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="number"
                          className="form-control"
                          id="ProductPrice"
                          placeholder="السعر"
                          value={editProduct.itemPrice}
                          name="itemPrice"
                          onChange={changeDataProduct}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row mb-3">
                      <label
                        htmlFor="ProductCoding"
                        className="col-sm-2 col-form-label"
                      >
                        تكويد الصنف{" "}
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          id="ProductCoding"
                          name="itemCode"
                          placeholder="التكويد على موقع الضرائب"
                          value={editProduct.itemCode}
                          onChange={changeDataProduct}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row mb-3">
                      <label
                        htmlFor="unitCode"
                        className="col-sm-2 col-form-label"
                      >
                        وحدة الصنف
                      </label>

                      <div className="col-sm-10">
                       
                      <Select 
                        name="unitType"
                        onChange={handleUniteChange}
                        options={unitsOptions}
                        isSearchable
                      />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row mb-3">
                      <label
                        htmlFor="description"
                        className="col-sm-2 col-form-label"
                      >
                        وصف بالانجليزية
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          id="description"
                          placeholder="وصف بالانجليزية "
                          name="description"
                          value={editProduct.description}
                          onChange={changeDataProduct}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {disbaled ? (
                  <button
                    disabled={disbaled}
                    className="mt-2 btn-custom btn-custom-disable "
                  >
                    <i className="fa fa-spinner fa-spin"></i>
                  </button>
                ) : (
                  <button className="mt-2 btn-custom">تعديل</button>
                )}
              </form>
            </div>
          </div>
        </div>
      ) : (
        <i className="fa fa-spinner fa-spin"></i>
      )}
    </div>
  );
};

export default EditProduct;
