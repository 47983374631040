import React, { useState, useEffect } from "react";
import PageTitle from "../../../Components/Uitily/PageTitle";
import { FaRegArrowAltCircleDown } from "react-icons/fa";
// import "./advancedReserch.css";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import axios from "axios";
import { useMemo } from "react";
import baseUrl from "./../../../Api/baseUrl";
export default function BackageInvoices() {
  const options = Array.from({ length: 100 }, (_, index) => index + 1);
  const companyId = localStorage.getItem("companyId");
  let [isLoading, setisLoading] = useState(false);
  let [disbaled, setDisbaled] = useState(true);
  const [packageInvoice, setPakageInvoice] = useState([]);
  const [PackageRequests, setPackageRequests] = useState([]);
  const [queryParameters, setQueryParameters] = useState({
    receiverSenderType: "2",
    dateFrom: "2020-10-01",
    dateTo: "2021-12-30",
    statuses: [],
    documentTypeNames: [],
    type:"Summary"
  });

  function getDatainvoice(e) {
    let currentInvoices = { ...queryParameters };
    currentInvoices[e.target.name] = e.target.value;
    setQueryParameters(currentInvoices);
  }

  function handleStatusCheckboxChange(event) {
    const value = event.target.value;
    setQueryParameters(previousState => {
      const statuses = [...previousState.statuses];
      if (event.target.checked) {
        statuses.push(value);
      } else {
        const index = statuses.indexOf(value);
        if (index > -1) {
          statuses.splice(index, 1);
        }
      }

      return {
        ...previousState,
        statuses
      }
    }
 
    );
  }
  function handledocumentTypeNames(e){
    const value = e.target.value;
    setQueryParameters(previousName => {
      const documentTypeNames = [...previousName.documentTypeNames];
      if (e.target.checked) {
        documentTypeNames.push(value);
      } else {
        const index = documentTypeNames.indexOf(value);
        if (index > -1) {
          documentTypeNames.splice(index, 1);
        }
      }

      return {
        ...previousName,
        documentTypeNames
      }
    }
 
    );

  }

  async function getPackageRequests() {
    try {
      let { data } = await baseUrl.post(
        `/invoices/getPackageRequests`,
        { companyId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
console.log(data.data.result)
      setPackageRequests(data.data.result);
    } catch (error) {
      console.log(error);
    }
  }
  console.log(PackageRequests)

  async function getAllpackage(e) {
    e.preventDefault();
    setisLoading(true);
    let { data } = await baseUrl.post(
      `/invoices/getDocumentPackage`,
      { companyId, queryParameters },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    console.log(data)
    if (data.status == "Success") {
      setisLoading(false);
      getPackageRequests();
    } 
  }
  async function config(packageId) {
    const config = {
      method: "POST",
      url: `/invoices/getPackageDetails`,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Accept-Language": "en",
      },
      data: { requestId: packageId, companyId },
    };
    let res = await baseUrl(config);
    saveAs(res.data, `document_${packageId}.zip`);
  }
  const resetProductInvoice = () => {
    setQueryParameters({
      receiverSenderType: "2",
      dateFrom: "2020-10-01",
      dateTo: "2021-12-30",
      statuses: [],
      documentTypeNames: [],
      type:"Summary"
    });
    
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
        <div className="d-flex">
          <PageTitle title="تحميل حزمة بيانات" />
          <div className="me-2">

{isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}
</div>
</div>

<div className="row ">
            <div className="col-4 ">
              <form  onSubmit={getAllpackage}>
              <div className="card card-search">
                <h5 className="card-header d-flex">
                                <div className="col-3 mt-2">

                تصفية</div>
                <div className="col-3 mt-2">
                        <span onClick={resetProductInvoice}>
                          <i className="fa-solid fa-rotate"></i>
                        </span>{" "}
                      </div>
                </h5>
                <div className="card-body " data-bs-spy="scroll">
                  <p className="mt-2 mb-2 filter-btn">
                    <a
                      data-bs-toggle="collapse"
                      href="#collapseExample1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample">
                      الفترة
                      <span>
                        <FaRegArrowAltCircleDown className="me-2" />
                      </span>
                    </a>
                  </p>

                  <div className="collapse" id="collapseExample1">
                    <div className="card card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="row mb-3">
                            <label
                              htmlFor="startInvoice"
                              className="col-sm-12  col-xl-2  col-form-label">
                              من
                            </label>
                            <div className="col-sm-12  col-xl-10">
                              <input
                                type="date"
                                className="form-control border-info"
                                id="startInvoice"
                                name="dateFrom"
                                value={queryParameters.dateFrom}
                                onChange={getDatainvoice}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row mb-3">
                            <label
                              htmlFor="dateTo"
                              className="col-sm-12  col-xl-2  col-form-label">
                              الي
                            </label>
                            <div className="col-sm-12  col-xl-10">
                              <input
                                type="date"
                                className="form-control border-info"
                                id="endInvoice"
                                name="dateTo"
                                value={queryParameters.dateTo}
                                onChange={getDatainvoice}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mt-2 mb-2 filter-btn">
                    <a
                      data-bs-toggle="collapse"
                      href="#collapseExample2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample">
                      نوع المرسل{" "}
                      <span>
                        <FaRegArrowAltCircleDown className="me-2" />
                      </span>
                    </a>
                  </p>

                  <div className="collapse" id="collapseExample2">
                    <div className="card card-body">
                      <div className="row">
                        <select
                          className="form-select border-info"
                          id="status"
                          name="receiverSenderType"
                          value={queryParameters.receiverSenderType}
                          onChange={getDatainvoice}
                          >      
                              <option value="0">Business</option>
                          <option value="1">Person</option>
                          <option value="2">Foreign</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <p className="mt-2 mb-2 filter-btn">
                    <a
                      data-bs-toggle="collapse"
                      href="#collapseExample3"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample">
                      الحالة{" "}
                      <span>
                        <FaRegArrowAltCircleDown className="me-2" />
                      </span>
                    </a>
                  </p>

                  <div className="collapse" id="collapseExample3">
                    <div className="card card-body">
                      <div className="row">
                        <div className="form-check backage">
                      

                      <input className="form-check-input " type="checkbox" value="Valid" name="statuses" id="flexCheckIndeterminate1" onChange={handleStatusCheckboxChange} />
                      Valid
                    </div>


                    <div className="form-check backage">
                    
                      <input className="form-check-input " type="checkbox" value="inValid" name="statuses" id="flexCheckIndeterminate2" onChange={handleStatusCheckboxChange} />
                      inValid
                    </div>
                    <div className="form-check backage">
                    Cancelled

                      <input className="form-check-input" type="checkbox" value="Cancelled" name="statuses" id="flexCheckIndeterminate3" onChange={handleStatusCheckboxChange} />
                    </div>
                    <div className="form-check backage">
                    Rejected

                      <input className="form-check-input" type="checkbox" value="Rejected" name="statuses" id="flexCheckIndeterminate4" onChange={handleStatusCheckboxChange} />
                    </div>

                      </div>
                    </div>
                  </div>
                  <p className="mt-2 mb-2 filter-btn">
                    <a
                      data-bs-toggle="collapse"
                      href="#collapseExample4"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample">
                  حجم الملف
                      <span>
                        <FaRegArrowAltCircleDown className="me-2" />
                      </span>
                    </a>
                  </p>

                  <div className="collapse" id="collapseExample4">
                    <div className="card card-body">
                      <div className="row">
                        <select
                          className="form-select border-info"
                          id="status"
                          name="type"
                          onChange={getDatainvoice}
                          value={queryParameters.type}
                       >
                          <option value="Summary">ملخص</option>
                          <option value="Full">شامل</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <p className="mt-2 mb-2 filter-btn">
                    <a
                      data-bs-toggle="collapse"
                      href="#collapseExample5"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample">
                      نوع الفاتورة{" "}
                      <span>
                        <FaRegArrowAltCircleDown className="me-2" />
                      </span>
                    </a>
                  </p>

                  <div className="collapse" id="collapseExample5">
                    <div className="card card-body">
                      <div className="row">
                        <div className="form-check backage">
                      

                      <input className="form-check-input " type="checkbox" value="I" name="documentTypeNames" id="flexCheckIndeterminate1" onChange={handledocumentTypeNames} />
                      فاتورة بيع
                    </div>


                    <div className="form-check backage">
                    
                      <input className="form-check-input " type="checkbox" value="C" name="documentTypeNames" id="flexCheckIndeterminate2" onChange={handledocumentTypeNames} />
                      اشعار خصم
                    </div>
                    <div className="form-check backage">
                    اشعار اضافة

                      <input className="form-check-input" type="checkbox" value="D" name="documentTypeNames" id="flexCheckIndeterminate3" onChange={handledocumentTypeNames} />
                    </div>


                      </div>
                    </div>
                  </div>
                  <button className="btn btn-primary mt-2 btn-custom">بحث</button>

                </div>

              </div>
        
              </form>
            </div>
            <div className="col-8">
              <div className=" card card-search">
                <div className="card-header">
                  <div className="row ">
                    <div className="col-8">
                      <div className="row align-items-center">
                        <h5 className="text-end">البيانات</h5>
                      </div>
                    </div>
                    <div className="col-4">

                    </div>
                  </div>
                </div>
                <div className="card-body mt-5">
                  <table className="table table-hover table-bordered h-100 overflow-auto">
                    <thead>
                      <tr>
                        <th scope="col"> رقم الطلب</th>
                        <th scope="col">تاريخ الطلب </th>
                        <th scope="col"> الحالة</th>
                        <th scope="col"> تحميل</th>

                      </tr>
                    </thead>

                    {isLoading ? (
                      <tbody>
                        <tr >
                          <td className="d-flex mt-2">
                            <i className="fa fa-spinner fa-spin spinner-table"></i>
                          </td>
                        </tr>
                      </tbody>
                    ) :
                    <tbody>
                    {PackageRequests?.map((request,index) =>(
                      <>
                      <tr key={index}>
                    <td>{request.packageId}</td>
                    <td>{request.submissionDate ? new Date(request.submissionDate).toLocaleDateString() : "-------"}</td>

<td>{request.status === 2 ? "جاهز للتحميل" : "غير جاهز للتحميل"}</td>
<td> {request.status === 2 ? (
                <button className="mt-2 btn-custom" onClick={() => config(request.packageId)}>تحميل</button>
              ):             
                 <button
              disabled={disbaled}
              className="mt-2 btn-custom btn-custom-disable ">
غير جاهز للتحميل                </button>}
</td>
</tr>
</>
                    ))}

                    </tbody>}
                  </table>  
                 

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
