import React, { useState, useEffect } from "react";
import axios from "axios";
import PageTitle from "./../../Components/Uitily/PageTitle";
import { useParams } from "react-router-dom";
import baseUrl from "../../Api/baseUrl";
const SingleProductPage = () => {
  const [Products, setProducts] = useState([]);
  const { id } = useParams();
  async function getDataProduct() {
    const { data } = await baseUrl.get(
      `/products/getProduct/${id}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    setProducts(data.data);
    console.log(Products);
  }
  useEffect(() => {
    getDataProduct();
  }, []);
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <PageTitle title="تفاصيل صنف" />
          <div className="card mt-3 single-info">
            <div>
              <h6
                className="text-center "
                style={{
                  borderTopRightRadius: "0.75rem",
                  borderTopLeftRadius: "0.75rem",
                }}>
                بيانات الصنف
              </h6>

              <div className="overflow-auto">
                <table className=" table table-bordered table-hover">
                  <tbody>
                    <tr>
                      <td style={{ width: "30%" }}>اسم الصنف</td>
                      <td>{Products.itemName}</td>
                    </tr>
                    <tr>
                      <td> السعر</td>
                      <td>{Products.itemPrice}</td>
                    </tr>
                    <tr>
                      <td> تكويد الصنف</td>
                      <td>{Products.itemCode}</td>
                    </tr>

                    <tr>
                      <td>unitCode</td>
                      <td>{Products.unitType}</td>
                    </tr>

                    <tr>
                      <td>الباركود</td>
                      <td>{Products.proBarcode}</td>
                    </tr>
                    <tr>
                      <td>وصف بالانجليزية</td>
                      <td>{Products.proDescription}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProductPage;
