import React from "react";

import AdminEditCompany from "./../../Components/Admin/AdminEditCompany";

const AdminEditCompanyPage = () => {
  return (
    <>
      <AdminEditCompany />
    </>
  );
};

export default AdminEditCompanyPage;
