import React from "react";
import EditAdminUser from "./../../Components/Admin/AdminEditUser";
function AdminEditUserPage() {
  return (
    <>
      <EditAdminUser />
    </>
  );
}

export default AdminEditUserPage;
