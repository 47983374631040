import React, { useState, useEffect } from 'react';
import PageTitle from './../Uitily/PageTitle';
import './SingleCompany.css';
import baseUrl from './../../Api/baseUrl';
import { useNavigate, useParams } from 'react-router-dom';
import env from "react-dotenv";
import axios from 'axios';
const SingleCompany = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [singleCompanyInfo, setSingleCompanyInfo] = useState({});
  let [countries, setcountries] = useState([]);
  async function getCountries(e) {
    let { data } = await baseUrl.get(
      `/json/getCountries`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    );
    setcountries(data.data);
  }
  useEffect(() => {
    getCountries();
  }, []);
  const countryData = Object.values(countries).reduce(
    (acc, curr) => acc.concat(curr),
    []
  );

  useEffect(() => {
    baseUrl
      .get(`/companies/getCompany/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then(res => {
        setSingleCompanyInfo(res.data.data);
        console.log(res.data.data)
      })
      .catch(err => {
        if (err.response.data.statusCode === '401') {
          localStorage.clear();
          navigate('/');
        }
      });
  }, [id]);

  return (
    <>
      <div className='page-content'>
        <div className='container-fluid'>
          <PageTitle title='تفاصيل شركة' />
          <div className='card mt-3 single-info'>
            <div className='single-company '>
              <div className='w-50 ' style={{ borderLeft: '1px solid #eee' }}>
                <h6
                  className='text-center '
                  style={{ borderTopRightRadius: '0.75rem' }}
                >
                  بيانات الشركة
                </h6>

                <div className='overflow-auto'>
                  <table className=' table table-bordered table-hover'>
                    <tbody>
                      <tr>
                        <td>الاسم</td>
                        <td>
                          {singleCompanyInfo
                            ? singleCompanyInfo.companyName
                            : null}
                        </td>
                      </tr>

                      <tr>
                        <td>تسجيل ضريبى</td>
                        <td>
                          {singleCompanyInfo
                            ? singleCompanyInfo.taxNumber
                            : null}
                        </td>
                      </tr>

                      <tr>
                        <td> رقم الفرع</td>
                        <td>
                          {singleCompanyInfo.address
                            ? singleCompanyInfo.address.brancheId
                            : null}
                        </td>
                      </tr>
                      <tr>
                        <td> البلد</td>
                        <td>
                          {singleCompanyInfo.address
                            ? countryData.map(ele =>
                                ele.code === singleCompanyInfo.address.country
                                  ? ele.Desc_ar
                                  : null
                              )
                            : null}
                        </td>
                      </tr>

                      <tr>
                        <td>المحافظة</td>
                        <td>
                          {singleCompanyInfo.address
                            ? singleCompanyInfo.address.governorate
                            : null}
                        </td>
                      </tr>
                      <tr>
                        <td>المدينة</td>
                        <td>
                          {singleCompanyInfo.address
                            ? singleCompanyInfo.address.regionCity
                            : null}
                        </td>
                      </tr>
                      <tr>
                        <td>الشارع</td>
                        <td>
                          {singleCompanyInfo.address
                            ? singleCompanyInfo.address.street
                            : null}
                        </td>
                      </tr>
                      <tr>
                        <td>رقم المبنى</td>
                        <td>
                          {singleCompanyInfo.address
                            ? singleCompanyInfo.address.buildingNumber
                            : null}
                        </td>
                      </tr>

                      <tr>
                        <td>هاتف</td>
                        <td>
                          {singleCompanyInfo ? singleCompanyInfo.phone : null}
                        </td>
                      </tr>

                      <tr>
                        <td>فاكس</td>
                        <td>
                          {singleCompanyInfo
                            ? singleCompanyInfo.faxNumber
                            : null}
                        </td>
                      </tr>

                      <tr>
                        <td>الايميل</td>
                        <td>
                          {singleCompanyInfo ? singleCompanyInfo.email : null}
                        </td>
                      </tr>
                      <tr>
                        <td>الصورة</td>
                        <td>
                          {singleCompanyInfo ? (
                            singleCompanyInfo.companyImage ? (
                              <img
                                src={`${env.API_URL}/${singleCompanyInfo.companyImage}`}
                                style={{ width: '40px' }}
                                alt='img'
                              />
                            ) : null
                          ) : null}
                        </td>
                      </tr>

                      <tr>
                        <td>المستخدم</td>
                        <td>
                          {singleCompanyInfo
                            ? singleCompanyInfo.users
                              ? singleCompanyInfo.users.map(item => (
                                  <p className='username' key={item._id}>
                                    {' '}
                                    {item.username}{' '}
                                  </p>
                                ))
                              : null
                            : null}
                        </td>
                      </tr>
                      <tr>
                        <td>الحالة</td>
                        <td>
                          {singleCompanyInfo
                            ? singleCompanyInfo.isActive === true
                              ? 'نشط'
                              : 'غير نشط'
                            : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='w-50 '>
                <h6
                  className='text-center '
                  style={{ borderTopLeftRadius: '0.75rem' }}
                >
                  بيانات الفاتورة
                </h6>
                <div className='overflow-auto'>
                  <table className=' table table-bordered table-hover'>
                    <tbody>
                      <tr>
                        <td>كود النشاط</td>
                        <td>
                          {singleCompanyInfo
                            ? singleCompanyInfo.activityCode
                            : null}
                        </td>
                      </tr>

                      <tr>
                        <td>البيئة</td>
                        <td>
                          {singleCompanyInfo.companyEnvironment === 'prod'
                            ? 'الفعلية'
                            : 'التجريبية'}
                        </td>
                      </tr>
                      <tr>
                        <td>Client Id</td>
                        <td>
                          {singleCompanyInfo.companyEnvironment === 'prod'
                            ? singleCompanyInfo.clientIdProd
                            : singleCompanyInfo.clientIdPreProd}
                        </td>
                      </tr>

                      <tr>
                        <td>Client Secret</td>
                        <td>
                          {singleCompanyInfo.companyEnvironment === 'prod'
                            ? singleCompanyInfo.clientSecretProd
                            : singleCompanyInfo.clientSecretPreProd}
                        </td>
                      </tr>

                      <tr>
                        <td>Token Pin</td>
                        <td>{singleCompanyInfo.tokenPin}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleCompany;
