import React from "react";
import AdminMangeCompany from "./../../Components/Admin/AdminMangeCompany";

const AdminMangeCompanyPage = () => {
  return (
    <>
      <AdminMangeCompany />
    </>
  );
};

export default AdminMangeCompanyPage;
