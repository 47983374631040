import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaFileInvoice, FaShoppingBasket } from 'react-icons/fa';
import { SiMicrosoftexcel } from 'react-icons/si';
import { TbReportSearch, TbReportAnalytics } from 'react-icons/tb';
import { FiUsers } from 'react-icons/fi';
import { BsFillMenuAppFill } from 'react-icons/bs';
import './Invoicepage.css';
export default function Invoicepage() {
  let invoiceData = [
    {
      id: 0,
      title: 'ارسال الفاتورة يدوى',
      icon: <FaFileInvoice />,
      classname: 'card ',
      path: '/manulinvoicepage',
    },
    {
      id: 1,
      title: 'ربط الفاتورة مع شيت اكسيل',
      icon: <SiMicrosoftexcel />,
      classname: 'card  ',
      path: '/excelinvoice',
    },
    {
      id: 2,
      title: ' تحميل من سيستم ERP',
      icon: <BsFillMenuAppFill />,
      classname: 'card ',
      path: '/erpinvoice',
    },
    {
      id: 3,
      title: 'قائمة العملاء',
      icon: <FiUsers />,
      classname: 'card  ',
      path: '/clients',
    },
    {
      id: 4,
      title: ' جميع الاصناف',
      icon: <FaShoppingBasket />,
      classname: ' card  ',
      path: '/Product',
    },
    {
      id: 5,
      title: 'تقارير الفواتير',
      icon: <TbReportAnalytics />,
      classname: 'card ',
      path: '/invoicereports',
    },
  ];
  // let reciptData = [
  //   {
  //     id: 5,
  //     title: "ارسال الايصال يدوى",
  //     icon: <FaFileInvoice />,
  //     classname: "card ",
  //     path: "",
  //   },
  //   {
  //     id: 6,
  //     title: "ربط الايصال مع شيت اكسيل",
  //     icon: <SiMicrosoftexcel />,
  //     classname: "card ",
  //     path: "",
  //   },
  //   {
  //     id: 7,
  //     title: "تقارير الايصالات",
  //     icon: <TbReportAnalytics />,
  //     classname: "card ",
  //     path: "",
  //   },
  //   {
  //     id: 8,
  //     title: " ربط الايصال بنظام ERP",
  //     icon: <BsFillMenuAppFill />,
  //     classname: "card ",
  //     path: "",
  //   },
  //   {
  //     id: 9,
  //     title: " متابعة الايصال",
  //     icon: <TbReportSearch />,
  //     classname: " card  ",
  //     path: "",
  //   },
  // ];
  return (
    <>
      <div className='page-content dashboard_container'>
        <div className='container-fluid'>
          <h1 style={{ textAlign:'center', width:'100%' , margin:'20px 0px', fontWeight:'bold'}}>شاشات النظام</h1>
          <div className='row pb-4'>
            {invoiceData?.map(invoice => (
              <div key={invoice.id} className='col-sm-6 col-xl-4 mb-3 mb-sm-0 '>
                <div className='Homecard card p-3'>
                  <NavLink to={invoice.path}>
                    <div className='card-body text-center'>
                      <div>
                        <span className='homeCard-icon'>{invoice.icon}</span>
                      </div>
                      <h6 className='card-title '>{invoice.title}</h6>
                    </div>
                  </NavLink>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="row pb-4">
            {reciptData?.map((reciptData) => (
              <div
                key={reciptData.id}
                className="col-sm-6 col-xl-4 mb-3 mb-sm-0 ">
                <div className="Homecard card p-3">
                  <NavLink to={reciptData.path}>
                    <div className="card-body text-center">
                      <div>
                        <span>{reciptData.icon}</span>
                      </div>
                      <h6 className="card-title ">{reciptData.title}</h6>
                    </div>
                  </NavLink>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
}
