import React from "react";

import LoginCompoment from "./../../Components/Auth/LoginCompoment";
import Logo from "../../images/LOGO-colored.png"
const LoginPage = () => {
  return (
    <div className="container">
      <h3 className="mb-4 text-center login-logo">
        <img src={Logo} alt='skylink' width="280px"/>
      </h3>
      <div className="login-card">
        <LoginCompoment />
      </div>
    </div>
  );
};

export default LoginPage;
