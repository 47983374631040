import React, { useState } from "react";
import PageTitle from "./../../Components/Uitily/PageTitle";
import Joi from "joi";
import { AiOutlineDelete } from "react-icons/ai";
import notify from "./../../hook/useNotifaction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import baseUrl from "../../Api/baseUrl";
export default function ManulCode() {
  const Navigate = useNavigate();
  let [validationError, setvalidationError] = useState([]);
  let companyId = localStorage.getItem("companyId");

  let [isLoading, setisLoading] = useState(false);
  let [disbaled, setDisbaled] = useState(false);
  let [items, setItems] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  let [codeIteams, setCodeIteams] = useState({
    codeType: "EGS",
    parentCode: "",
    itemCode: "",
    codeName: "",
    codeNameAr: "",

    description: "",
    descriptionAr: "",
  });

  function getDataClient(e) {
    let currentUser = { ...codeIteams };
    currentUser[e.target.name] = e.target.value;
    setCodeIteams(currentUser);
  }
  function vaildation() {
    let schema = Joi.object({
      parentCode: Joi.string()
        .required()
        .messages({ "string.empty": " من فضلك ادخل كود الاب" }),
      itemCode: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل  تكويد الصنف" }),
      codeName: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل  اسم الصنف بالانجليزية " }),
      codeNameAr: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل اسم الصنف بالعربية" }),
      description: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل وصف الصنف انجليزى" }),
      descriptionAr: Joi.string()
        .required()
        .messages({ "string.empty": " من فضلك  وصف الصنف عربي" }),

      codeType: Joi.string().required(),
    });
    let res = schema.validate(codeIteams, { abortEarly: false });
    console.log(res);
    if (res.error) {
      setvalidationError(res.error.details);
      return false;
    } else {
      return true;
    }
  }
  async function createEGSCode(e) {
    e.preventDefault();

    if (vaildation()) {
      let newCodeItems = codeIteams;
      newCodeItems.activeFrom = startDate.toISOString();
      setItems([...items, newCodeItems]);
      console.log(items);
      setvalidationError([]);
      setStartDate(new Date());
      setCodeIteams({
        codeType: "EGS",
        parentCode: "",
        itemCode: "",
        codeName: "",
        codeNameAr: "",

        description: "",
        descriptionAr: "",
      });
    }
  }
  function removeItem(index) {
    let currentArray = [...items];
    currentArray.splice(index, 1);
    setItems(currentArray);
  }
  async function sendEGSCode(e) {
    e.preventDefault();
    if (items.length < 1) {
      notify("ادخل التكويد اولا", "error");
      return;
    }
    console.log(items);
    const body = { companyId, items };
    // console.log(body);
    setisLoading(true);
    setDisbaled(true);
    await baseUrl
      .post(`/invoices/createEGSCode`, body, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setisLoading(false);
          setDisbaled(false);
          notify("تمت الارسال لمنظومة الضرائب بنجاح", "success");
          setTimeout(() => {
            setItems([]);
          }, 1000);
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          err.response.data.errors.map((error) => notify(error.msg, "error"));
          setisLoading(false);
          setDisbaled(false);
        } else if (err.response.data.statusCode === "401") {
          localStorage.clear();
          Navigate("/");
        }
      });
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex">
            <PageTitle title=" تكويد الصنف" />
            <div className="me-2">
              {isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}
            </div>
          </div>
          <form onSubmit={createEGSCode}>
            <div className="row pb-4">
              <div className="card ">
                <div className="card-body m-3">
                  <div className="row ">
                    <div className="col-xl-6 col-lg-12">
                      <div className="row mb-3">
                        <label
                          htmlFor="parentCode"
                          className="col-sm-4 col-form-label">
                          كود الاب
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control border-info"
                            id="parentCode"
                            name="parentCode"
                            onChange={getDataClient}
                            value={codeIteams.parentCode}
                          />
                          <div
                            className={
                              validationError.filter(
                                (ele) => ele.context.label === "parentCode"
                              )[0]
                                ? "alert mt-2 alert-danger"
                                : ""
                            }>
                            {
                              validationError.filter(
                                (ele) => ele.context.label === "parentCode"
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12">
                      <div className="row mb-3">
                        <label
                          htmlFor="itemCode"
                          className="col-sm-4 col-form-label">
                          تكويد الصنف
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control border-info"
                            id="itemCode"
                            name="itemCode"
                            onChange={getDataClient}
                            value={codeIteams.itemCode}
                          />
                          <div
                            className={
                              validationError.filter(
                                (ele) => ele.context.label === "itemCode"
                              )[0]
                                ? "alert mt-2 alert-danger"
                                : ""
                            }>
                            {
                              validationError.filter(
                                (ele) => ele.context.label === "itemCode"
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12">
                      <div className="row mb-3">
                        <label
                          htmlFor="codeNameAr"
                          className="col-sm-4 col-form-label">
                          اسم الصنف بالعربية
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control border-info"
                            id="codeNameAr"
                            name="codeNameAr"
                            onChange={getDataClient}
                            value={codeIteams.codeNameAr}
                          />
                          <div
                            className={
                              validationError.filter(
                                (ele) => ele.context.label === "codeNameAr"
                              )[0]
                                ? "alert mt-2 alert-danger"
                                : ""
                            }>
                            {
                              validationError.filter(
                                (ele) => ele.context.label === "codeNameAr"
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12">
                      <div className="row mb-3">
                        <label
                          htmlFor="codeName"
                          className="col-sm-4 col-form-label">
                          اسم الصنف بالانجليزية
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control border-info"
                            id="codeName"
                            name="codeName"
                            onChange={getDataClient}
                            value={codeIteams.codeName}
                          />
                          <div
                            className={
                              validationError.filter(
                                (ele) => ele.context.label === "codeName"
                              )[0]
                                ? "alert mt-2 alert-danger"
                                : ""
                            }>
                            {
                              validationError.filter(
                                (ele) => ele.context.label === "codeName"
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12">
                      <div className="row mb-3">
                        <label
                          htmlFor="descriptionAr"
                          className="col-sm-4 col-form-label">
                          وصف الصنف عربي
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control border-info"
                            id="descriptionAr"
                            name="descriptionAr"
                            onChange={getDataClient}
                            value={codeIteams.descriptionAr}
                          />
                          <div
                            className={
                              validationError.filter(
                                (ele) => ele.context.label === "descriptionAr"
                              )[0]
                                ? "alert mt-2 alert-danger"
                                : ""
                            }>
                            {
                              validationError.filter(
                                (ele) => ele.context.label === "descriptionAr"
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12">
                      <div className="row mb-3">
                        <label
                          htmlFor="description"
                          className="col-sm-4 col-form-label">
                          وصف الصنف انجليزي
                        </label>
                        <div className="col-sm-8">
                          <input
                            value={codeIteams.description}
                            type="text"
                            className="form-control border-info"
                            id="description"
                            name="description"
                            onChange={getDataClient}
                          />
                          <div
                            className={
                              validationError.filter(
                                (ele) => ele.context.label === "description"
                              )[0]
                                ? "alert mt-2 alert-danger"
                                : ""
                            }>
                            {
                              validationError.filter(
                                (ele) => ele.context.label === "description"
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row mb-3">
                        <label
                          htmlFor="activeFrom"
                          className="col-sm-4  col-xl-2  col-form-label">
                          نشط من
                        </label>
                        <div className="col-sm-8  col-xl-10">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="yyyy:MM:dd hh:mm:ss"
                            showTimeSelect
                            // timeFormat="p"
                            className="form-control border-info"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <button className="mt-2 btn-custom">اضافة</button>
                </div>
              </div>
            </div>
          </form>
          <div className="card  mt-4">
            <div className="card-body">
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col">كود الاب </th>
                    <th scope="col">التكويد</th>
                    <th scope="col">الصنف E</th>
                    <th scope="col">الصنف AR</th>
                    <th scope="col"> وصف E</th>
                    <th scope="col">وصف AR</th>
                    <th scope="col">حذف</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td className="d-flex">
                        <i className="fa fa-spinner fa-spin spinner-table"></i>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {items.map((table, index) => (
                      <tr key={index}>
                        <td>{table.parentCode}</td>
                        <td>{table.itemCode}</td>
                        <td>{table.codeName}</td>
                        <td>{table.codeNameAr}</td>
                        <td>{table.description}</td>
                        <td>{table.descriptionAr}</td>

                        <td>
                          <span
                            className=" table-icon delete"
                            onClick={() => removeItem(index)}>
                            <AiOutlineDelete />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
          {disbaled ? (
            <button
              disabled={disbaled}
              className="mt-2 btn-custom btn-custom-disable ">
              <i className="fa fa-spinner fa-spin"></i>
            </button>
          ) : (
            <button className="mt-2 btn-custom" onClick={sendEGSCode}>
              ارسال لمنظومة الضرايب
            </button>
          )}
        </div>
      </div>
    </>
  );
}
