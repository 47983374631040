import React, { useState, useEffect } from "react";
import PageTitle from "../../../Components/Uitily/PageTitle";
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import "./advancedReserch.css";
import { Link, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import axios from "axios";
import baseUrl from "./../../../Api/baseUrl";
import env from "react-dotenv";
import moment from "moment"

export default function AdvancedResearch() {
  const options = Array.from({ length: 100 }, (_, index) => index + 1);
  const navigate = useNavigate();
  const companyId = localStorage.getItem("companyId");
  let [isLoading, setisLoading] = useState(false);
  let [disbaled, setDisbaled] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [productinvoice, setProductinvoice] = useState({
    startInvoice: new Date().toISOString().slice(0, 10),
    endInvoice: moment().add(1,'d').format("YYYY-MM-DD"),
    issueDateFrom: '',
    issueDateTo: '',
    direction: '',
    pageSize: 10,
    status: 'Valid',
    documentType: '',
    receiverType: '',
    receiverId: '',
    issuerType: '',
    issuerId: '',
  });
  function getDatainvoice(e) {
    const { name, value } = e.target;
    setProductinvoice(prev => ({ ...prev, [name]: value }));
  }
  async function searchInvoices() {
    try {
      const {
        startInvoice,
        endInvoice,
        issueDateFrom,
        issueDateTo,
        direction,
        status,
        documentType,
        pageSize,
        receiverType,
        receiverId,
        issuerType,
        issuerId,
      } = productinvoice;
      setisLoading(true);
      setDisbaled(true);
      let { data } = await baseUrl.post(
        `/invoices/searchInvoice?submissionDateFrom=${startInvoice}&submissionDateTo=${endInvoice}&continuationToken&pageSize=${pageSize}&issueDateFrom=${issueDateFrom}&issueDateTo=${issueDateTo}&direction=${direction}&status=${status}&documentType=${documentType}&receiverType=${receiverType}&receiverId=${receiverId}&issuerType=${issuerType}&issuerId=${issuerId}`,
        { companyId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      setInvoices(data.data.result);
      console.log(invoices);
    } catch (error) {
      if (error.response.status === 401) {   
        navigate("/");    }      
    } finally {
      setisLoading(false);
      setDisbaled(false);
    }
  }

  async function refreshInvoices() {
    setisLoading(true);
    setDisbaled(true);
    let { data } = await baseUrl.post(
      `/invoices/searchInvoice?submissionDateFrom=2022-12-01T15:00:59&submissionDateTo=2022-12-31T20:00:00&continuationToken&pageSize=10&issueDateFrom=&issueDateTo=&direction=&status=Valid&documentType=&receiverType=&receiverId=&issuerType=&issuerId=`,

      { companyId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    );
    setisLoading(false);
    setDisbaled(false);
    setInvoices(data.data.result);
  }
  useEffect(() => {
    searchInvoices();
  }, [productinvoice]);

  async function config(uuid) {
    console.log(uuid);
    const config = {
      method: 'POST',
      url: `/invoices/printInvoicePdf`,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Accept-Language': 'en',
      },
      data: { documentUUID: uuid, companyId },
    };
    let res = await baseUrl(config);
    saveAs(res.data, `document_${uuid}.pdf`);
  }

  return (
    <>
      <div className='page-content '>
        <div className='container-fluid'>
          <div className='d-flex'>
            <PageTitle title='بحث متقدم' />
            <div className='me-2'>
              {isLoading ? <i className='fa fa-spinner fa-spin'></i> : ''}
            </div>
          </div>
          <div className='row '>
            <div className='col-4 '>
              <div className='card card-search'>
                <h5 className='card-header'>تصفية</h5>
                <div className='card-body ' data-bs-spy='scroll'>
                  <p className='mt-2 mb-2 filter-btn'>
                    <a
                      data-bs-toggle='collapse'
                      href='#collapseExample1'
                      role='button'
                      aria-expanded='false'
                      aria-controls='collapseExample'
                    >
                      الفترة
                      <span>
                        <FaRegArrowAltCircleDown className='me-2' />
                      </span>
                    </a>
                  </p>

                  <div className='collapse' id='collapseExample1'>
                    <div className='card card-body'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='row mb-3'>
                            <label
                              htmlFor='startInvoice'
                              className='col-sm-12  col-xl-2  col-form-label'
                            >
                              من
                            </label>
                            <div className='col-sm-12  col-xl-10'>
                              <input
                                type='date'
                                className='form-control border-info'
                                id='startInvoice'
                                name='startInvoice'
                                value={productinvoice.startInvoice}
                                onChange={getDatainvoice}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='row mb-3'>
                            <label
                              htmlFor='endInvoice'
                              className='col-sm-12  col-xl-2  col-form-label'
                            >
                              الي
                            </label>
                            <div className='col-sm-12  col-xl-10'>
                              <input
                                type='date'
                                className='form-control border-info'
                                id='endInvoice'
                                name='endInvoice'
                                value={productinvoice.endInvoice}
                                onChange={getDatainvoice}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p className='mt-2 mb-2 filter-btn'>
                    <a
                      data-bs-toggle='collapse'
                      href='#collapseExample3'
                      role='button'
                      aria-expanded='false'
                      aria-controls='collapseExample'
                    >
                      العميل{' '}
                      <span>
                        <FaRegArrowAltCircleDown className='me-2' />
                      </span>
                    </a>
                  </p>

                  <div className='collapse' id='collapseExample3'>
                    <div className='card card-body'>
                      <div className='row'>
                        <div className='col-12'>
                          <input
                            type='text'
                            className='form-control border-info mt-1'
                            placeholder='رقم السجل الضريبي للعميل'
                            name='receiverId'
                            onChange={getDatainvoice}
                          />
                        </div>
                        <div className='col-12'>
                          <input
                            type='text'
                            className='form-control border-info mt-1'
                            placeholder='نوع العميل'
                            name='receiverType'
                            onChange={getDatainvoice}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className='mt-2 mb-2 filter-btn'>
                    <a
                      data-bs-toggle='collapse'
                      href='#collapseExample4'
                      role='button'
                      aria-expanded='false'
                      aria-controls='collapseExample'
                    >
                      نوع الفاتورة{' '}
                      <span>
                        <FaRegArrowAltCircleDown className='me-2' />
                      </span>
                    </a>
                  </p>
                  <div className='collapse' id='collapseExample4'>
                    <div className='card card-body'>
                      <div className='row'>
                        <div className='col-12'>
                          <select
                            className='form-select border-info'
                            id='documentType'
                            name='documentType'
                            value={productinvoice.documentType}
                            onChange={getDatainvoice}
                          >
                            <option value='I'>فاتورة بيع عادية</option>
                            <option value='C'>credit</option>
                            <option value='D'>debit</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className='mt-2 mb-2 filter-btn'>
                    <a
                      data-bs-toggle='collapse'
                      href='#collapseExampleInvalid'
                      role='button'
                      aria-expanded='false'
                      aria-controls='collapseExample'
                    >
                      حالة الفاتورة{' '}
                      <span>
                        <FaRegArrowAltCircleDown className='me-2' />
                      </span>
                    </a>
                  </p>
                  <div className='collapse' id='collapseExampleInvalid'>
                    <div className='card card-body'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='col-12'>
                            <select
                              className='form-select border-info'
                              id='status'
                              name='status'
                              value={productinvoice.name}
                              onChange={getDatainvoice}
                            >
                              <option value='Valid'>Valid</option>
                              <option value='Invalid'>Invalid</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className='mt-2 mb-2 filter-btn'>
                    <a
                      data-bs-toggle='collapse'
                      href='#collapseExample5'
                      role='button'
                      aria-expanded='false'
                      aria-controls='collapseExample'
                    >
                      الفواتير{' '}
                      <span>
                        <FaRegArrowAltCircleDown className='me-2' />
                      </span>
                    </a>
                  </p>
                  <div className='collapse' id='collapseExample5'>
                    <div className='card card-body'>
                      <div className='row'>
                        <div className='col-12'>
                          <select
                            className='form-select border-info'
                            id='direction'
                            name='direction'
                            value={productinvoice.direction}
                            onChange={getDatainvoice}
                          >
                            <option value='Sent'>مبيعات</option>
                            <option value='Received'>مشتريات</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className='mt-2 mb-2 filter-btn'>
                    <a
                      data-bs-toggle='collapse'
                      href='#collapseExample6'
                      role='button'
                      aria-expanded='false'
                      aria-controls='collapseExample'
                    >
                      المورد{' '}
                      <span>
                        <FaRegArrowAltCircleDown className='me-2' />
                      </span>
                    </a>
                  </p>
                  <div className='collapse' id='collapseExample6'>
                    <div className='card card-body'>
                      <div className='row'>
                        <div className='col-12'>
                          <input
                            type='text'
                            className='form-control border-info mt-1'
                            name='issuerType'
                            placeholder='نوع المورد'
                            value={productinvoice.documentType}
                            onChange={getDatainvoice}
                          />
                        </div>
                        <div className='col-12'>
                          <input
                            type='text'
                            className='form-control border-info mt-1'
                            name='issuerId'
                            placeholder='رقم السجل الضريبي للمورد'
                            value={productinvoice.issuerId}
                            onChange={getDatainvoice}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-8'>
              <div className='card card-search'>
                <div className='card-header'>
                  <div className='row '>
                    <div className='col-8'>
                      <div className='row align-items-center'>
                        <h5 className='text-end'>البيانات</h5>
                      </div>
                    </div>
                    <div className='col-4 d-flex'>
                      <div className='col-3 mt-2'>
                        <span onClick={refreshInvoices}>
                          <i className='fa-solid fa-rotate'></i>
                        </span>{' '}
                      </div>
                      <div className='col-9 '>
                        <select
                          className='form-control border-info'
                          id='pageSize'
                          value={productinvoice.pageSize}
                          name='pageSize'
                          onChange={getDatainvoice}
                        >
                          <option>ادخل عدد النواتج</option>
                          {options.map(number => (
                            <option key={number} value={number}>
                              {number}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-body mt-5'>
                  <table className='table table-hover table-bordered h-100 overflow-auto'>
                    <thead>
                      <tr>
                        <th scope='col'>uuid </th>
                        <th scope='col'> معاد الاستقبال</th>
                        <th scope='col'> معاد الاصدار</th>
                        <th scope='col'> الوقت الكلي</th>
                        <th scope='col'> المصدر </th>
                        <th scope='col'> المستورد </th>
                        <th scope='col'> الكود الداخلي </th>
                        <th scope='col'> عرض </th>
                        <th scope='col'> طباعة </th>
                      </tr>
                    </thead>
                    {isLoading ? (
                      <tbody>
                        <tr>
                          <td className='d-flex mt-2'>
                            <i className='fa fa-spinner fa-spin spinner-table'></i>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {invoices && invoices.length > 0 ? (
                          invoices.map(invoice => (
                            <tr key={invoice.uuid}>
                              <td>{invoice.uuid}</td>
                              <td>
                                {invoice.dateTimeReceived
                                  ? new Date(
                                      invoice.dateTimeReceived
                                    ).toLocaleDateString()
                                  : '-------'}
                              </td>
                              <td>
                                {invoice.dateTimeIssued
                                  ? new Date(
                                      invoice.dateTimeIssued
                                    ).toLocaleDateString()
                                  : '-------'}
                              </td>

                              <td>{invoice.total}</td>
                              <td>{invoice.issuerName}</td>
                              <td>{invoice.receiverName}</td>
                              <td>{invoice.internalId}</td>
                              <td>
                                {' '}
                                <Link to={invoice.publicUrl}>عرض</Link>
                              </td>
                              <td>
                                <Link onClick={() => config(invoice.uuid)}>
                                  طباعة
                                </Link>
                              </td>
                              {/* <td onClick={}>{invoice.internalId}</td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan='9'>لا يوجد نتائج.</td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
