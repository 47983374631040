import React from "react";

import MangeProduct from "./../../Components/Product/MangeProduct";

const ManageProductPage = () => {
  return (
    <>
      <MangeProduct />
    </>
  );
};

export default ManageProductPage;
