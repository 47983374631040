import React from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
import PageTitle from "../../../Components/Uitily/PageTitle";
export default function InternalRecipts() {
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
        <PageTitle title="الايصالات الداخلية" />
          <div className="row pb-4">
            <div className="col-sm-6 mb-3 mb-sm-0">
              <input type="date" className="form-control" id="date" />
            </div>
            <div className="col-sm-6">
              <div className="search-box">
                <BsSearch className="search-icon" />
                <input
                  type="text"
                  className="form-control"
                  id="searchMemberList"
                  placeholder="ابحث عن النتائج"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card  mt-4">
          <div className="card-body">
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col">رقم الايصال</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">UUID</th>
                  <th scope="col">Sumisionid</th>
                  <th scope="col">العميل</th>
                  <th scope="col">التاريخ</th>
                  <th scope="col">الاجمالي</th>
                  <th scope="col">اجمالي الضريبة</th>
                  <th scope="col">تاريخ الارسال</th>
                  <th scope="col"> حذف او تعديل</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="col">رقم الايصال</td>
                  <td scope="col">الحالة</td>
                  <td scope="col">UUID</td>
                  <td scope="col">Sumisionid</td>
                  <td scope="col">العميل</td>
                  <td scope="col">التاريخ</td>
                  <td scope="col">الاجمالي</td>
                  <td scope="col">اجمالي الضريبة</td>
                  <td scope="col">تاريخ الارسال</td>
                  <td scope="col">
                    <Link to='/editRecipts/:id'>
                      <AiOutlineEdit />
                    </Link>
                    <a data-bs-toggle="modal" data-bs-target="#deleteform">
                      <AiOutlineDelete />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="deleteform"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header  "></div>
            <div className="modal-body">...</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-custom">
                تاكيد
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal">
                تراجع
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="deleteform"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header  "></div>
            <div className="modal-body">هل انت متاكد من حذف الايصال</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-custom">
                حذف
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal">
                تراجع
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
