import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";

import PageTitle from "./../Uitily/PageTitle";
import { Link } from "react-router-dom";
import baseUrl from "./../../Api/baseUrl";
import notify from "./../../hook/useNotifaction";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../Uitily/Pagination.css";
import useDebounce from "../../hook/useDebounce";

const AdminMangeUser = () => {
  const [users, setUsers] = useState([]);
  const [runUseEffect, setRun] = useState(0);
  const navigate = useNavigate();
  const [PageCount, setPageCount] = useState();
  let pageList = new Array(PageCount).fill().map((ele, i) => i + 1);
  let [isLoading, setisLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(1);

  const Get = async (currentpage) => {
    setisLoading(true);
    await baseUrl
      .get(`/users/getUsers?page=${currentpage}&limit=10`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setisLoading(false);
        setUsers(res.data.data);
        setPageCount(Math.ceil(res.data.results / 10));
      })
      .catch((err) => {
        if (err.response.data.statusCode === "401") {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const [showDelete, setShowDelete] = useState(false);
  const [id, setId] = useState("");

  const handleDeletClose = () => setShowDelete(false);
  const handleDeleteShow = (itemId) => {
    setShowDelete(true);
    setId(itemId);
  };

  const handelDelete = async () => {
    try {
      const res = await baseUrl.delete(`/users/deleteUser/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (res.status === 200) {
        notify("تم حذف المستخدم بنجاح", "success");
        setShowDelete(false);
        setRun((prev) => prev + 1);
      }
    } catch (err) {
      notify("هناك مشكله فى عملية الحذف", "error");
      if (err.response.data.statusCode === "401") {
        localStorage.clear();
        navigate("/");
      }
    }
  };
  const [searchData, setSearchData] = useState("");
  const debouncedValue = useDebounce(searchData, 500)
  async function handleSearch(pageNumber = 1) {
    setisLoading(true);
    await baseUrl
      .post(
        `/users/searchUser?page=${pageNumber}&limit=10`,
        {
          username: debouncedValue,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        setisLoading(false);
        setUsers(res.data.data);
        setPageCount(Math.ceil(res.data.results / 10));
      })
      .catch((err) => {
        if (err.response.data.status === "fail") {
          setUsers([]);
          setisLoading(false);
          setPageCount(0);
        }
        if (err.response.data.statusCode === "401") {
          localStorage.clear();
          navigate("/");
        }
      });
  }

  function handleKeyPress(e) {
    const { value } = e.target;
    setSearchData(value);

  }
  function onPaginate(newPageNumber) {
    setPage(newPageNumber);

    if (debouncedValue) {
      handleSearch(newPageNumber);
    } else {
      Get(newPageNumber);
    }
    setActivePage(newPageNumber);
  }

  const nextPage = () => {
    const newPageNumber = page + 1;
    if (newPageNumber <= PageCount) {
      setPage(newPageNumber);
      onPaginate(newPageNumber);
    }
  };
  const prevPage = () => {
    const newPageNumber = page - 1;
    if (newPageNumber >= 1) {
      setPage(newPageNumber);
      onPaginate(newPageNumber);
    }
  };
  useEffect(() => {
    if (debouncedValue) {
      handleSearch(page);
    } else {
      Get(page);
    }
  }, [runUseEffect, page, debouncedValue]);
  return (
    <div className="page-content">
      {/* Modal For Delete User */}
      <Modal show={showDelete} onHide={handleDeletClose}>
        <Modal.Header>
          <Modal.Title>
            {" "}
            <div>تاكيد الحذف</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>هل انت متاكد من حذف المستخدم </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleDeletClose}>
            تراجع
          </Button>
          <Button variant="dark" onClick={handelDelete}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-fluid">
        <div className="d-flex">
          <PageTitle title="المستخدمين" />
          <div className="me-2">
            {isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-sm-6 mb-3 mb-sm-0">
            <Link to="/admin/adduser" className="btn main-btn">
              اضافة مستخدم
            </Link>
          </div>
          <div className="col-sm-6">
            <div className="search-box">
              <BsSearch className="search-icon" />
              <input
                onChange={handleKeyPress}
                type="text"
                className="form-control"
                id="searchMemberList"
                placeholder="ابحث عن النتائج"
              />
            </div>
          </div>
        </div>
        <div className="card  mt-4">
          <div className="card-body">
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col">اسم المستخدم</th>
                  <th scope="col"> نشط</th>
                  <th scope="col"> ادمن</th>
                  <th scope="col">تعديل</th>
                  <th scope="col">حذف</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td className="d-flex">
                      <i className="fa fa-spinner fa-spin spinner-table"></i>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {users
                    ? users.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>{user.username}</td>
                            <td>{user.isActive ? "نعم" : "لا"}</td>
                            <td>{user.isAdmin ? "نعم" : "لا"}</td>
                            <td>
                              <Link
                                to={`/admin/edituser/${user._id}`}
                                className="table-icon edit">
                                <AiOutlineEdit />
                              </Link>
                            </td>
                            <td>
                              <span className=" table-icon delete">
                                <AiOutlineDelete
                                  onClick={() => {
                                    handleDeleteShow(user._id);
                                  }}
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              )}
            </table>
            <nav aria-label="...">
              <ul className="pagination">
                <li className="page-item ">
                  <a className="page-link" onClick={prevPage}>
                    السابق
                  </a>
                </li>
                {pageList.map((ele) => (
                  <li
                    className={`page-item ${
                      ele === activePage ? "active-page" : ""
                    }`}
                    onClick={() => onPaginate(ele)}
                    key={ele}>
                    <span className="page-link ">{ele}</span>
                  </li>
                ))}

                <li className="page-item">
                  <a className="page-link" onClick={nextPage}>
                    التالي
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMangeUser;
