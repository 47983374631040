import React from "react";
import AddAdminUser from "./../../Components/Admin/AdminAddUser";

const AdminAddUserPage = () => {
  return (
    <>
      <AddAdminUser />
    </>
  );
};

export default AdminAddUserPage;
