import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { Modal, Button } from "react-bootstrap";
import { TbFileExport, TbFileImport } from "react-icons/tb";
import { Link } from "react-router-dom";
import PageTitle from "./../Uitily/PageTitle";
import baseUrl from "./../../Api/baseUrl";
import { useNavigate } from "react-router-dom";
import notify from "./../../hook/useNotifaction";
import "../Uitily/Pagination.css";
import axios from "axios";
import { saveAs } from "file-saver";
import useDebounce from "../../hook/useDebounce";

const AdminMangeCompany = () => {
  let [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const [company, setCompany] = useState([]);
  const [companyAll, setCompanyAll] = useState(0);
  const [runUseEffect, setRun] = useState(0);
  const [PageCount, setPageCount] = useState();
  const [activePage, setActivePage] = useState(1);
  let pageList = new Array(PageCount).fill().map((ele, i) => i + 1);

  const [page, setPage] = useState(1);

  const Get = async (currentpage) => {
    setisLoading(true);
    await baseUrl
      .get(`/companies/getCompanies?page=${currentpage}&limit=10`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setisLoading(false);
        setCompany(res.data.data);
        setCompanyAll(res.data.results);
        setPageCount(Math.ceil(res.data.results / 10));
      })
      .catch((err) => {
        if (err.response.data.statusCode === "401") {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const [showDelete, setShowDelete] = useState(false);
  const [id, setId] = useState("");

  const handleDeletClose = () => setShowDelete(false);
  const handleDeleteShow = (itemId) => {
    setShowDelete(true);
    setId(itemId);
  };

  const handelDelete = async () => {
    try {
      const res = await baseUrl.delete(`/companies/deleteCompany/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (res.status === 200) {
        notify("تم حذف الشركة بنجاح", "success");

        setRun((prev) => prev + 1);

        setShowDelete(false);
        setTimeout(() => {}, 100);
      }
    } catch (err) {
      if (err) {
        notify("هناك مشكله فى عملية الحذف", "error");
        if (err.response.data.statusCode === "401") {
          localStorage.clear();
          navigate("/");
        }
      }
    }
  };
  const [searchData, setSearchData] = useState("");
  const debouncedValue = useDebounce(searchData, 500)
  async function handleSearch(pageNumber = 1) {
    setisLoading(true);
    await baseUrl
      .post(
        `/companies/searchCompany?page=${pageNumber}&limit=10`,
        {
          companyName: debouncedValue,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        setisLoading(false);
        setCompany(res.data.data);
        setCompanyAll(res.data.results);
        setPageCount(Math.ceil(res.data.results / 10));
      })
      .catch((err) => {
        if (err.response.data.status === "fail") {
          setisLoading(false);
          setCompany([]);
          setCompanyAll(0);
          setPageCount(0);
        }
        if (err.response.data.statusCode === "401") {
          localStorage.clear();
          navigate("/");
        }
      });
  }

  function handleKeyPress(e) {
    const { value } = e.target;
    setSearchData(value);
    // console.log("search data",value);
    // console.log("debounced" , debouncedValue)
    // if (debouncedValue) {
    //   handleSearch(1);
    // } else {
    //   Get(page);
    // }
  }
  function onPaginate(newPageNumber) {
    setPage(newPageNumber);

    if (debouncedValue) {
      handleSearch(newPageNumber);
    } else {
      Get(newPageNumber);
    }
    setActivePage(newPageNumber);
  }

  const nextPage = () => {
    const newPageNumber = page + 1;
    if (newPageNumber <= PageCount) {
      setPage(newPageNumber);
      onPaginate(newPageNumber);
    }
  };
  const prevPage = () => {
    const newPageNumber = page - 1;
    if (newPageNumber >= 1) {
      setPage(newPageNumber);
      onPaginate(newPageNumber);
    }
  };
  useEffect(() => {
    if (debouncedValue) {
      handleSearch(page);
    } else {
      Get(page);
    }
  }, [runUseEffect, page, debouncedValue]);

  const handleExport = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    await baseUrl
      .post("/companies/exportCompaniesToExcel", {}, config)
      .then((res) => {
        const url = res.data.data.fileURL;
        saveAs(url);
        if (res.data.status === "Success") {
          notify(res.data.message, "success");
        }
      })
      .catch((err) => {
        if (err) {
          notify("يوجد مشكلة فى تصدير الشركات", "error");
        }
        if (err.response.data.statusCode === "401") {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [selectedFile, setSelectedFile] = useState("");

  const onFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };
  const handleImport = (e) => {
    e.preventDefault();
    if (selectedFile === "") {
      notify("اضف الملف ", "error");
      return;
    }

    const formData = new FormData();
    formData.append("companiesExcel", selectedFile);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    baseUrl
      .post("/companies/importCompaniesFromExcel", formData, config)
      .then((res) => {
        if (res.data.status === "Success") {
          notify(res.data.message, "success");
        }
        setShow(false);
        setRun((prev) => prev + 1);
      })
      .catch((err) => {
        notify("يوجد مشكلة فى استيرد الشركات", "error");
        if (err.response.data.statusCode === "401") {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  return (
    <div className="page-content">
      {/* Modal For Delete Company */}
      <Modal show={showDelete} onHide={handleDeletClose}>
        <Modal.Header>
          <Modal.Title>
            {" "}
            <div>تاكيد الحذف</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            هل انت متاكد من حذف الشركة وجميع البيانات الخاصة بها
            (عملاء,اصناف,فواتير){" "}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleDeletClose}>
            تراجع
          </Button>
          <Button variant="dark" onClick={handelDelete}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal For Import Company Excel */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {" "}
            <div> استيراد من اكسل</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              accept=".xlsx"
              type="file"
              name="file"
              onChange={onFileChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            تراجع
          </Button>
          <Button variant="dark" onClick={handleImport}>
            حفظ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-fluid">
        <div className="d-flex">
          <PageTitle title="الشركات" />
          <div className="me-2">
            {isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-sm-6 mb-3 mb-sm-0">
            <Link to="/admin/addcompany" className="btn main-btn">
              اضافة شركة
            </Link>
          </div>
          <div className="col-sm-6">
            <div className="search-box">
              <BsSearch className="search-icon" />
              <input
                onChange={handleKeyPress}
                type="text"
                className="form-control"
                id="searchMemberList"
                placeholder="ابحث عن النتائج"
              />
            </div>
          </div>
        </div>
        <div className="card  mt-4">
          <div className="card-body">
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col">اسم الشركة</th>
                  <th scope="col">الرقم الضريبى</th>
                  <th scope="col">تعديل</th>
                  <th scope="col">حذف</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td className="d-flex">
                      <i className="fa fa-spinner fa-spin spinner-table"></i>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {company
                    ? company.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Link
                              to={`/admin/company/${item._id}`}
                              className="name">
                              {item.companyName}
                            </Link>
                          </td>
                          <td>{item.taxNumber}</td>

                          <td>
                            <Link
                              to={`/admin/editcompany/${item._id}`}
                              className="table-icon edit">
                              <AiOutlineEdit />
                            </Link>
                          </td>
                          <td>
                            <span className=" table-icon delete">
                              <AiOutlineDelete
                                onClick={() => {
                                  handleDeleteShow(item._id);
                                }}
                              />
                            </span>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              )}
            </table>
            <nav aria-label="...">
              <ul className="pagination">
                <li className="page-item ">
                  <a className="page-link" onClick={prevPage}>
                    السابق
                  </a>
                </li>
                {pageList.map((ele) => (
                  <li
                    className={`page-item ${
                      ele === activePage ? "active-page" : ""
                    }`}
                    onClick={() => onPaginate(ele)}
                    key={ele}>
                    <span className="page-link ">{ele}</span>
                  </li>
                ))}

                <li className="page-item">
                  <a className="page-link" onClick={nextPage}>
                    التالي
                  </a>
                </li>
              </ul>
            </nav>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <p>العدد : {companyAll}</p>
              <div className="d-flex">
                <button
                  className="file-icon"
                  title="تصدير"
                  onClick={handleExport}>
                  <TbFileExport />
                </button>
                <button
                  className="file-icon"
                  title="استيراد"
                  onClick={handleShow}>
                  <TbFileImport />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMangeCompany;
