import React, { useState } from "react";
import PageTitle from "./../../Components/Uitily/PageTitle";
import { Tabs, Tab } from "react-bootstrap";
import { AiOutlineSync } from "react-icons/ai";
import "./ReciptReports.css";
const ReciptReportsPage = () => {
  const [key, setKey] = useState("Recipt");
  return (
    <>
      <div className="page-content reports">
        <div className="container-fluid">
          <PageTitle title="تقارير مباشرة للايصالات والايصالات" />
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3">
            <Tab eventKey="Recipt" title="الايصالات">
              <div className="d-flex justify-content-between align-items-center Recipts-number ">
                <div className="d-flex">
                  <p>احصائيات اخر</p>
                  <input
                    style={{ width: "80px", margin: "0 10px" }}
                    type="number"
                  />
                  <p>ايصال</p>
                </div>
                <AiOutlineSync />
              </div>
              <div className="row ">
                <div className="col-md-6">
                  <h6> احصائيات الايصالات الصحيحة المرسلة (المبيعات)</h6>

                  <table className="table ">
                    <thead>
                      <tr>
                        <th scope="col"> النوع</th>
                        <th scope="col">المدفوع </th>
                        <th scope="col"> الضريبة</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ايصال </td>
                        <th scope="row"> 00.00</th>
                        <th scope="row"> 00.00</th>
                      </tr>
                      <tr>
                        <td>اشعار خصم </td>
                        <th scope="row"> 00.00</th>
                        <th scope="row"> 00.00</th>
                      </tr>
                      <tr>
                        <td>اشعار اضافة </td>
                        <th scope="row"> 00.00</th>
                        <th scope="row"> 00.00</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6">
                  <h6> احصائيات الايصالات الصحيحة المستلمة (المشتريات)</h6>

                  <table className="table ">
                    <thead>
                      <tr>
                        <th scope="col"> النوع</th>
                        <th scope="col">المدفوع </th>
                        <th scope="col"> الضريبة</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ايصال </td>
                        <th scope="row"> 00.00</th>
                        <th scope="row"> 00.00</th>
                      </tr>
                      <tr>
                        <td>اشعار خصم </td>
                        <th scope="row"> 00.00</th>
                        <th scope="row"> 00.00</th>
                      </tr>
                      <tr>
                        <td>اشعار اضافة </td>
                        <th scope="row"> 00.00</th>
                        <th scope="row"> 00.00</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row ">
                <div className="col-12">
                  <h6> احصائيات الايصالات</h6>

                  <table className="table ">
                    <thead>
                      <tr>
                        <th scope="col"> الحالة</th>
                        <th scope="col">الاجمالى </th>
                        <th scope="col"> الضريبة</th>
                        <th scope="col"> العدد</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-success">صحيحة </td>
                        <th scope="row"> 00.00</th>
                        <th scope="row"> 00.00</th>
                        <th scope="row"> 13</th>
                      </tr>
                      <tr>
                        <td className="text-warning">غير صحيحة</td>
                        <th scope="row"> 0</th>
                        <th scope="row"> 0</th>
                        <th scope="row"> 0</th>
                      </tr>
                      <tr>
                        <td className="text-primary"> جديد</td>
                        <th scope="row"> 0</th>
                        <th scope="row"> 0</th>
                        <th scope="row"> 0</th>
                      </tr>
                      <tr>
                        <td className="text-info"> ملغية</td>
                        <th scope="row"> 0</th>
                        <th scope="row"> 0</th>
                        <th scope="row"> 0</th>
                      </tr>
                      <tr>
                        <td className="text-danger"> مرفوضة</td>
                        <th scope="row"> 0</th>
                        <th scope="row"> 0</th>
                        <th scope="row"> 0</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="info" title="الايصالات"></Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default ReciptReportsPage;
