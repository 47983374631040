import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import baseUrl from './../../../Api/baseUrl';
import GenerateSignature from '../../../utils/GenerateSignature';
import moment from 'moment';
import { toast } from 'react-toastify';
import notify from '../../../hook/useNotifaction';
import ClearDecimalPlaces from '../../../utils/ClearDecimalPlaces';

export default function InvoiceDetails() {
  const [invoice, setInvoice] = useState({});
  let [invoiceLines, setInvoiceLines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [remainingTimeToSend, setRemainingTimeToSend] = useState(null);
  const [internalId, setIntervalId] = useState(null);
  const [countDown, setCountDown] = useState(null);
  const timerToResend = localStorage.getItem('timer');

  const { id } = useParams();

  const getRemainingTime = (string, sub) => {
    const index = string.indexOf(sub);
    let remainingSeconds;
    if (index !== -1) {
      const cutString = string.slice(index + sub.length);
      remainingSeconds = cutString.slice(0, 3);
    }
    return remainingSeconds;
  };

  const SaveRemainingTime = async sec => {
    //disable button
    const current = new Date();
    console.log('current', current);
    let timeToSave = current.setSeconds(current.getSeconds() + sec);
    const remaining = new Date(timeToSave);
    //save to db

    let data = {
      data: remaining,
    };
    await baseUrl
      .put(`/invoices/saveTimeToResend/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(res => {
        console.log(res.data.data);
        setLoading(true);
      })
      .catch(err => {
        console.log(err);
      });

    const timer = setInterval(async () => {
      const currentDate = new Date();
      let cd = Math.ceil((remaining - currentDate) / 1000);
      localStorage.setItem('timer', cd);
      setCountDown(cd);
      if (currentDate > remaining) {
        console.log('time is up use');

        let data = {
          data: null,
        };
        await baseUrl
          .put(`/invoices/saveTimeToResend/${id}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
          .then(res => {
            console.log('value of time from nullifiyng', res.data.data);
          })
          .catch(err => {
            console.log(err);
          });
        setLoading(null);
        localStorage.removeItem('timer');
        setRemainingTimeToSend(false);
        clearInterval(timer);
      }
    }, 1000);
  };
  async function getDataInvoice() {
    setLoading(true);
    const { data } = await baseUrl.get(`/invoices/getInternalInvoice/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    setInvoice(data.data);
    setInvoiceLines(data.data.invoiceLines);
    setRemainingTimeToSend(data.data.timeToResend);
    if (data.data.timeToResend) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }

  // Sending invoice and data formatting utility functions 
  async function generateInvoiceData({ withSignature }) {
    // get date with this format 2015-02-13T13:15:00Z
    const date = moment()
      .subtract(2, 'h')
      .subtract(10, 'minute')
      .utc()
      .format();
    // clear any property that not belong to the tax authority because the api will return error
    const mappedInvoiceLines = invoice.invoiceLines.map(invoiceLine => {
      const newItem = {
        description: invoiceLine.description,
        itemType: invoiceLine.itemType,
        itemCode: invoiceLine.itemCode,
        unitType: invoiceLine.unitType,
        quantity: invoiceLine.quantity,
        salesTotal: invoiceLine.salesTotal,
        total: invoiceLine.total,
        valueDifference: invoiceLine.valueDifference,
        totalTaxableFees: invoiceLine.totalTaxableFees,
        netTotal: invoiceLine.netTotal,
        itemsDiscount: invoiceLine.itemsDiscount,
        discount: invoiceLine.discount,
        taxableItems: invoiceLine.taxableItems,
      };
      const unitValue = {
        currencySold: invoiceLine.unitValue.currencySold,
        amountEGP: invoiceLine.unitValue.amountEGP,
      };
      // if the currency is not EGP then add the currencyExchangeRate and amountSold
      if (invoiceLine.unitValue.currencySold !== 'EGP') {
        unitValue.currencyExchangeRate =
          invoiceLine.unitValue.currencyExchangeRate;
        unitValue.amountSold = invoiceLine.unitValue.amountSold;
      }
      return {
        ...newItem,
        unitValue,
      };
    });
    // remove any property that the tax authority not require
    const newInvoice = {
      issuer: {
        ...invoice.issuer,
        address: {
          branchId: invoice.issuer.address.branchID,
          country: invoice.issuer.address.country,
          buildingNumber: invoice.issuer.address.buildingNumber,
          governate: invoice.issuer.address.governate,
          regionCity: invoice.issuer.address.regionCity,
          street: invoice.issuer.address.street,
        },
      },
      receiver: invoice.receiver,
      documentType: invoice.documentType,
      documentTypeVersion: '1.0',
      dateTimeIssued: date,
      taxpayerActivityCode: invoice.taxpayerActivityCode,
      internalID: `${invoice.internalId}:${invoice._id}`,
      invoiceLines: mappedInvoiceLines,
      totalSalesAmount: invoice.totalSalesAmount,
      totalDiscountAmount: invoice.totalDiscountAmount,
      totalItemsDiscountAmount: invoice.totalItemsDiscountAmount,
      netAmount: invoice.netAmount,
      taxTotals: invoice.taxTotals,
      extraDiscountAmount: invoice.extraDiscountAmount,
      totalAmount: invoice.totalAmount,
    };

    const ClearedInvoiceData = ClearDecimalPlaces(newInvoice);
    if (withSignature) {
      return await GenerateSignature(ClearedInvoiceData);
    }

    return ClearedInvoiceData;

  }
  function handleInvoiceSendError(error) {
    console.log('An error occurred while saving the invoice', error);
    if (
      error.response?.data?.message?.includes(
        'Request payload is identical to a previous payload sent in the last 10 Min'
      )
    ) {
      const seconds = getRemainingTime(
        error?.response?.data?.message,
        'after '
      );
      SaveRemainingTime(Number(seconds));
      toast.error(
        `هذة الفاتورة مطابقة لفاتورة تم ارسالها في العشر دقائق الماضية، من فضلك حاول ارسالها مرة اخرى بعد ${seconds} ثانية`
      );
    } else {
      toast.error(error?.response?.data?.message);
    }
  }
  function handleInvoiceRejectionErrors(rejectedInvoices) {
    rejectedInvoices.forEach(i => {
      let invoiceNum = i.internalId;
      i.error.details.forEach(e => {
        console.log('Rejected Invoice Details', e);
        toast.error(
          `عفوا تم رفض الفاتورة رقم ${invoiceNum} : ${e.message} في ${e.propertyPath}`,
          { autoClose: false }
        );
      });
    });
  }

  const sendInvoice = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      setLoading(true);
      const newInvoiceData = await generateInvoiceData({ withSignature: false });
      const response = await baseUrl.post(
        '/invoices/sendInvoice',
        {
          documents: [newInvoiceData],
          company: invoice.company,
        },
        config
      );
      // documentation of the errors can be found on tax authority sdk documentation
      if (response.status === 200) {
        console.log('response', response);
        let data;
        try {
          data = JSON.parse(response.data.data);
        } catch (error) {
          data =  response.data.data
        }
        // let data = JSON.parse(response.data.data);
        let rejectedInvoices = data.rejectedDocuments;
        console.log('rejectedInvoices', rejectedInvoices);
        if (rejectedInvoices.length) {
          handleInvoiceRejectionErrors(rejectedInvoices);
        } else {
          toast.success('تم ارسال الفاتورة بنجاح');
        }
      }
    } catch (error) {
      // Handle error
      handleInvoiceSendError(error); 
    } finally {
      setLoading(false);
    }
  };

  const sendReceipt = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      setLoading(true);
      const newReceiptData = await generateInvoiceData({ withSignature: false });
      const response = await baseUrl.post(
        '/invoices/sendReceipt',
        {
          documents: [newReceiptData],
          company: invoice.company,
        },
        config
      );
      console.log('response', response);
      // documentation of the errors can be found on tax authority sdk documentation
      if (response.status === 200) {
        let data = response.data.data
        let rejectedInvoices = data.rejectedDocuments;
        if (rejectedInvoices.length) {
          handleInvoiceRejectionErrors(rejectedInvoices);
        } else {
          toast.success('تم ارسال الإيصال بنجاح');
        }
      }
    } catch (error) {
      // Handle error
      handleInvoiceSendError(error); 
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDataInvoice();
  }, []);
  //  const SecondInvoiceLine = invoiceLines.unitValue.length > 0 ? invoiceLines.unitValue[0] : null;

  return (
    <>
      <div className='page-content'>
        <div className='container-fluid'>
          <div className='card mt-3 single-info'>
            <div>
              <h6
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingInline: '20px',
                  borderTopRightRadius: '0.75rem',
                  borderTopLeftRadius: '0.75rem',
                }}
              >
                <span>بيانات الفاتورة</span>

               <div className='d-flex' style={{ gap: '10px' }}>
                
               <button
                  id='sendBtn'
                  style={{
                    backgroundColor: '#0d6e',
                    border: 'none',
                    color: 'white',
                    padding: '10px 20px',
                    fontSize: '16px',
                    borderRadius: '5px',
                  }}
                  onClick={sendInvoice}
                  disabled={countDown || invoice?.status?.toLowerCase() !== 'pending'}
                >
                  {loading ? (
                    <i className='fa fa-spinner fa-spin'></i>
                  ) : countDown ? (
                    `اعد الارسال بعد ${countDown} ثانية`
                  ) : (
                    'ارسال كفاتورة'
                  )}
                </button>
                <button
                  id='sendBtn'
                  style={{
                    backgroundColor: '#f37070',
                    border: 'none',
                    color: 'white',
                    padding: '10px 20px',
                    fontSize: '16px',
                    borderRadius: '5px',
                  }}
                  disabled={invoice?.status?.toLowerCase() !== 'pending'}
                  onClick={sendReceipt}
                 
                >
                  {loading ? (
                    <i className='fa fa-spinner fa-spin'></i>
                  ) : (
                    'ارسال كإيصال'
                  )}
                </button>
               </div>
              </h6>

              <div className='overflow-auto'>
                <table className=' table table-bordered table-hover'>
                  <tbody>
                    <tr>
                      <td colSpan='2' className='text-center'>
                        {' '}
                        الفاتورة{' '}
                      </td>
                    </tr>
                    <tr>
                    <td>حالة الفاتورة</td>
                      <td>
                        {invoice.status}
                      </td>
                    </tr>
                    <tr>
                   
                      <td>تاريخ الاصدار</td>
                      <td>
                        {invoice.dateTimeIssued
                          ? new Date(
                            invoice.dateTimeIssued
                          ).toLocaleDateString()
                          : '-------'}
                      </td>
                    </tr>
                    <tr>
                      <td>نوع الوثيقة</td>
                      <td>{invoice.documentType}</td>
                    </tr>
                    <tr>
                      <td>نوع الوثيقة الإصدار</td>
                      <td>{invoice.documentTypeVersion}</td>
                    </tr>
                    <tr>
                      <td>مبلغ الخصم الإضافي</td>
                      <td>{invoice.extraDiscountAmount}</td>
                    </tr>
                    <tr>
                      <td>رقم التعريف الداخلي</td>
                      <td>{invoice.internalId}</td>
                    </tr>
                    <tr>
                      <td>صافي المبلغ</td>
                      <td>{invoice.netAmount}</td>
                    </tr>
                    <tr>
                      <td>رمز نشاط دافع الضرائب</td>
                      <td>{invoice.taxpayerActivityCode}</td>
                    </tr>
                    <tr>
                      <td>المبلغ الإجمالي</td>
                      <td>{invoice.totalAmount}</td>
                    </tr>
                    <tr>
                      <td>إجمالي مبلغ الخصم</td>
                      <td>{invoice.totalDiscountAmount}</td>
                    </tr>
                    <tr>
                      <td>إجمالي مبلغ الخصم الصنف</td>
                      <td>{invoice.totalItemsDiscountAmount}</td>
                    </tr>
                    <tr>
                      <td>إجمالي مبلغ المبيعات</td>
                      <td>{invoice.totalSalesAmount}</td>
                    </tr>
                    <tr>
                      <td>تم التحديث في</td>
                      <td>{invoice.updatedAt}</td>
                    </tr>
                    {invoiceLines?.map((invoiceline, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td colSpan='2' className='text-center'>
                            {' '}
                            عناصر الفاتورة{' '}
                          </td>
                        </tr>
                        <tr>
                          <td scope='col'> الوصف</td>
                          <td>{invoiceline.description}</td>
                        </tr>
                        <tr>
                          <td>كمية التخفيض </td>
                          <td>{invoiceline.discount.amount}</td>
                        </tr>
                        <tr>
                          <td scope='col'> نسبة التخفيض</td>
                          <td>{invoiceline.discount.rate}</td>
                        </tr>
                        <tr>
                          <td scope='col'> الكود الداخلي </td>
                          <td>{invoiceline.internalCode}</td>
                        </tr>
                        <tr>
                          <td scope='col'> كود الصنف </td>
                          <td>{invoiceline.itemCode}</td>
                        </tr>
                        <tr>
                          <td scope='col'> نوع العنصر </td>
                          <td>{invoiceline.itemType}</td>
                        </tr>
                        <tr>
                          {' '}
                          <td scope='col'> البنود الخصم </td>
                          <td>{invoiceline.itemsDiscount}</td>
                        </tr>
                        <tr>
                          <td scope='col'> صافي الإجمالي </td>
                          <td>{invoiceline.netTotal}</td>
                        </tr>
                        <tr>
                          <td scope='col'> كمية </td>
                          <td>{invoiceline.quantity}</td>
                        </tr>

                        <tr>
                          <td scope='col'> إجمالي المبيعات </td>
                          <td>{invoiceline.salesTotal}</td>
                        </tr>
                        <tr>
                          <td scope='col'> المجموع</td>
                          <td>{invoiceline.total}</td>
                        </tr>
                        <tr>
                          <td scope='col'> إجمالي الرسوم الخاضعة للضريبة </td>
                          <td>{invoiceline.totalTaxableFees}</td>
                        </tr>
                        <tr>
                          <td scope='col'>فارق القيمة </td>

                          <td>{invoiceline.valueDifference}</td>
                        </tr>
                        <tr>
                          <td scope='col'> نوع الوحدة </td>
                          <td>{invoiceline.unitType}</td>
                        </tr>
                        <tr>
                          <td colSpan='2' className='text-center'>
                            {' '}
                            قيمة الوحدة{' '}
                          </td>
                        </tr>
                        <tr>
                          <td scope='col'> الكمية بالجنيه </td>
                          <td>{invoiceline.unitValue.amountEGP}</td>
                        </tr>
                        <tr>
                          <td scope='col'> الكمية المباعة </td>
                          <td>{invoiceline.unitValue.amountSold}</td>
                        </tr>
                        <tr>
                          <td scope='col'>سعر صرف العملات</td>
                          <td>{invoiceline.unitValue.currencyExchangeRate}</td>
                        </tr>
                        <tr>
                          <td scope='col'> العملة </td>
                          <td>{invoiceline.unitValue.currencySold}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                    <tr>
                      <td colSpan={2}> لعناصر الخاضعة للضريبة</td>
                    </tr>
                    {invoiceLines?.map((line, index) =>
                      line?.taxableItems?.map((item, itemIndex) => (
                        <React.Fragment key={`${index}-${itemIndex}`}>
                          <tr>
                            <td>الكمية</td>
                            <td>{item.amount}</td>
                          </tr>
                          <tr>
                            <td scope='col'> النسبة </td>
                            <td>{item.rate}</td>
                          </tr>
                          <tr>
                            <td scope='col'> النوع الفرعي </td>

                            <td>{item.subType}</td>
                          </tr>
                          <tr>
                            <td scope='col'> نوع الضريبة </td>
                            <td>{item.taxType}</td>
                          </tr>
                        </React.Fragment>
                      ))
                    )}

                    {invoice && invoice?.rejectionReason?.message && (
                      <>
                        <tr>
                          <td
                            colSpan='2'
                            className='text-center'
                            style={{
                              backgroundColor: 'red',
                              color: 'white',
                            }}
                          >
                            {' '}
                            أسباب رفض الفاتورة{' '}
                          </td>
                        </tr>
                        <tr>
                          <td scope='col'> السبب العام</td>
                          <td>{invoice?.rejectionReason?.message}</td>
                        </tr>{' '}
                        {invoice?.rejectionReason?.details?.map((reason, i) => (
                          <>
                            <tr>
                              <td scope='col'> السبب الخاص {i + 1}</td>
                              <td>{reason?.message}</td>
                            </tr>

                            <tr>
                              <td scope='col'> المسار</td>
                              <td>{reason?.propertyPath}</td>
                            </tr>
                          </>
                        ))}
                      </>
                    )}

                    {/* <tr>
                          <td scope='col'> الكمية المباعة </td>
                          <td>{invoiceline.unitValue.amountSold}</td>
                        </tr>
                        <tr>
                          <td scope='col'>سعر صرف العملات</td>
                          <td>{invoiceline.unitValue.currencyExchangeRate}</td>
                        </tr>
                        <tr>
                          <td scope='col'> العملة </td>
                          <td>{invoiceline.unitValue.currencySold}</td>
                        </tr>

                    {/* <th colSpan="2" className="text-center"> المُصدر </th>
<tr>
<td scope="col">اسم المُصدر</td>
<td>{invoice.issuer.name}</td></tr>
<tr>
<td scope="col">    الرقم التعريفي للفرع  المُصدر </td>

            <td>{invoice.issuer.address.branchID}</td>
            </tr>
            <tr>
            <td scope="col"> رقم المبنى    </td>

            <td>{invoice.issuer.address.buildingNumber}</td>
            </tr>
            <tr>
            <td scope="col">   البلد  </td>

            <td>{invoice.issuer.address.country}</td>
            </tr>
            <tr>
            <td scope="col">   المحافظة  </td>
            <td>{invoice.issuer.address.governate}</td>
            </tr>
            <tr>
            <td scope="col">   المنطقة  </td>

            <td>{invoice.issuer.address.regionCity}</td>
            </tr>
            <tr>
            <td scope="col">   الشارع  </td>

            <td>{invoice.issuer.address.street}</td>
            </tr>
            <tr>
            <td scope="col">   الرقم التعريفي  </td>

            <td>{invoice.issuer.id}</td>
            </tr>
            <tr>
            <td scope="col">   نوع المصدر  </td>

            <td>{invoice.issuer.issuerType}</td>
            </tr>

            <td colSpan="2" className="text-center"> المستقبل </td>

            <tr>
            <td scope="col">اسم المستقبل</td>

            <td>{invoice.receiver.name}</td>
            </tr>
            <tr>
            <td scope="col"> رقم المبنى    </td>

            <td>{invoice.receiver.address.buildingNumber}</td>
            </tr>
            <tr>
            <td scope="col">   البلد  </td>

            <td>{invoice.receiver.address.country}</td>
            </tr>
            <tr>
            <td scope="col">   المحافظة  </td>

            <td>{invoice.receiver.address.governate}</td>
            </tr>
            <tr>
            <td scope="col">   المنطقة  </td>

            <td>{invoice.receiver.address.regionCity}</td>
            </tr>
            <tr>
            <td scope="col">   الشارع  </td>

            <td>{invoice.receiver.address.street}</td>
            </tr>
            <tr>
            <td scope="col">   الرقم التعريفي  </td>

            <td>{invoice.receiver.id}</td>
            </tr>
            <tr>
            <td scope="col">   نوع المستقبل  </td>

            <td>{invoice.receiver.receiverType}</td>
            </tr> */}
                    {/* { invoiceLines?.map(invoiceline => taxableItems?.map((taxableitem) => (
                      <>
                        <th colSpan="2" className="text-center">
                        لعناصر الخاضعة للضريبة
                        /th>

                        <tr>
                          <td scope="col">  كمية  </td>
                          <td>{invoiceline.taxableitem.amount}</td>


                        </tr>
                        <tr>
                          <td scope="col">  النسبة  </td>
                          <td>{taxableitem.rate}</td>


                        </tr>
                        <tr>                  <td scope="col">  النوع الفرعي </td>

                          <td>{taxableitem.subType}</td>

                        </tr>
                        <tr>
                          <td scope="col">   نوع الضريبة </td>
                          <td>{taxableitem.taxType}</td>
                        </tr>

                      </>
                    )))} */}
                    {/* {SecondInvoiceLine && (
<>
<tr></tr>
<td>{unitValue.amountEGP}</td>
</>
)} */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
