import React, { useState } from 'react';
import PageTitle from './../../Components/Uitily/PageTitle';
import { Modal, Button } from 'react-bootstrap';
import baseUrl from '../../Api/baseUrl';
import notify from './../../hook/useNotifaction';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {  toast as sonnerToast } from 'sonner'

const ExcelInvoicePage = () => {
  let companyId = localStorage.getItem('companyId');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [selectedFile, setSelectedFile] = useState('');

  const [invoices, setInvoices] = useState([]);

  const [disbaled, setDisbaled] = useState(false);

  const [invoiceDisplay, setInvoiceDisplay] = useState([]);

  const [isChecked, setIsChecked] = useState(true);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onFileChange = e => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };
  const handleImport = e => {
    e.preventDefault();
    if (selectedFile === '') {
      notify('اضف الملف ', 'error');
      return;
    }

    setDisbaled(true);

    const formData = new FormData();
    formData.append('companyId', companyId);
    formData.append('invoicesFile', selectedFile);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    baseUrl
      .post('/invoices/importExcelInvoices', formData, config)
      .then(res => {
        console.log(res);
        if (res.data.status === 'Success') {
          notify(res.data.message, 'success');
          setInvoices(res.data.data);
          setInvoiceDisplay(res.data.data);
        }
        setShow(false);

        setDisbaled(false);
      })
      .catch(err => {
        if (err?.response?.data?.errors?.issues)
        {
          console.log(err.response.data.errors.issues?.slice(0,20))
          err.response.data.errors.issues?.slice(0,20).forEach((error) => {
            console.log(error.message)
            sonnerToast.error(`${error.message} ${error.path?.length ? 'في الصف رقم  ' + (error.path?.[0] + 1) + ` عمود : (${error.path?.[1]})` : ''}`)
          })
        }else 
        {
          sonnerToast.error( err?.response?.data?.message || 'يوجد مشكلة فى تحميل الفواتير من اكسل');
        }

        setDisbaled(false);
      });
  };

  const handleChange = event => {
    const checked = event.target.checked;
    const index = invoiceDisplay.findIndex(
      invoice => invoice.internalId === invoices[event.target.value].internalId
    );

    if (checked) {
      setInvoiceDisplay(prevState => [
        ...invoiceDisplay,
        invoices[event.target.value],
      ]);
    } else {
      setInvoiceDisplay([...invoiceDisplay].splice(index, 1));
    }
  };

  const handleSave = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      setLoading(true);
      console.log(invoiceDisplay);
      let documents = [];
      invoiceDisplay.map(el =>
        documents.push({ ...el, invoiceType: 'imported' })
      );
      const response = await baseUrl.post(
        '/invoices/saveInvoice',
        documents,
        config
      );
      if (response.status === 201) {
        console.log('Invoice saved successfully');
        console.log(response.data);
        toast.success('تم حفظ الفواتير بنجاح');
        // navigate to internal invoice page
        navigate('/internalinvoices');
      } else {
        console.log('Failed to save invoice');
      }
    } catch (error) {
      // Handle error
      console.log('An error occurred while saving the invoice');
      console.log(error);
      notify(error?.response?.data?.error, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='page-content'>
        {/* Modal For Import Company Excel */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              {' '}
              <div> استيراد من اكسل</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <input
                accept='.xlsx'
                type='file'
                name='file'
                onChange={onFileChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='success' onClick={handleClose}>
              تراجع
            </Button>
            {disbaled ? (
              <button
                disabled={disbaled}
                className='mt-2 btn-custom btn-custom-disable '
              >
                <i className='fa fa-spinner fa-spin'></i>
              </button>
            ) : (
              <Button variant='dark' onClick={handleImport}>
                حفظ
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <div className='container-fluid'>
          <PageTitle title='ارسال الفواتير من اكسل' />
          <div className='row pb-4'>
            <div className='col-sm-6 mb-3 mb-sm-0'>
              <button className='btn main-btn' onClick={handleShow}>
                تحميل اكسيل
              </button>
            </div>
          </div>

          <div className='card'>
            <div className='card-body'>
              {invoices.map((item, index) => (
                <div key={index}>
                  <input
                    type='checkbox'
                    id={item.internalId}
                    name={item.internalId}
                    className='ms-3'
                    value={index}
                    checked={invoiceDisplay.includes(item)}
                    onChange={handleChange}
                  />
                  <label htmlFor={item.internalId}> {item.internalId}</label>
                </div>
              ))}
            </div>
          </div>
          <div className='card  mt-4'>
            <div className='card-body'>
              <table className='table table-hover table-bordered'>
                <thead>
                  <tr>
                    <th scope='col'>رقم الفاتورة</th>
                    <th scope='col'>العملة</th>
                    <th scope='col'>السعر مصرى</th>
                    <th scope='col'>السعر الاجنبى</th>
                    <th scope='col'>تحويلة العملة</th>
                    <th scope='col'> اسم الصنف</th>
                    <th scope='col'> كود الصنف</th>
                    <th scope='col'> الكمية</th>
                    <th scope='col'> سعر البيع</th>
                    <th scope='col'> نسبة الخصم</th>
                    <th scope='col'> قيمة الخصم</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceDisplay
                    ? invoiceDisplay.map(inv =>
                        inv.invoiceLines
                          ? inv.invoiceLines.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {inv.internalId ? inv.internalId : null}
                                </td>
                                <td>
                                  {item.unitValue
                                    ? item.unitValue.currencySold
                                    : null}
                                </td>
                                <td>
                                  {item.unitValue
                                    ? item.unitValue.amountEGP
                                    : null}
                                </td>
                                <td>
                                  {item.unitValue
                                    ? item.unitValue.amountSold
                                    : null}
                                </td>
                                <td>
                                  {item.unitValue
                                    ? item.unitValue.currencyExchangeRate
                                    : null}
                                </td>
                                <td>{item.description}</td>
                                <td>{item.itemCode}</td>
                                <td>{item.quantity}</td>
                                <td>{item.salesTotal}</td>
                                <td>
                                  {item.discount ? item.discount.rate : null}
                                </td>
                                <td>
                                  {item.discount ? item.discount.amount : null}
                                </td>
                              </tr>
                            ))
                          : null
                      )
                    : null}
                  <tr></tr>
                </tbody>
              </table>
            </div>
            <div className='card mt-4'>
              <div className='card-body'>
                <table className='table table-hover table-bordered '>
                  <thead>
                    <tr>
                      <th scope='col'>TaxType</th>
                      <th scope='col'>Value </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceDisplay
                      ? invoiceDisplay.map(item =>
                          item.taxTotals.map((item, index) => (
                            <tr key={index}>
                              <td>{item.taxType}</td>
                              <td>{item.amount}</td>
                            </tr>
                          ))
                        )
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            {/* <div className="d-flex p-4 flex-wrap pt-0">
              <p className="ms-2">
                العميل :{" "}
                <strong>
                  {invoiceDisplay.receiver
                    ? invoiceDisplay.receiver.name
                    : null}
                </strong>
              </p>
              <p className="ms-2">
                الصافى : <strong> {invoiceDisplay? invoiceDisplay.taxTotals : null}</strong>
              </p>
              <p className="ms-2">
                اجمالى الفاتورة : <strong> 1120</strong>
              </p>
              <p className="ms-2">
                اجمالى الضريبة : <strong>0.00</strong>
              </p>
              <p className="ms-2">
                العملة : <strong>EGP</strong>
              </p>
            </div> */}
          </div>
          {disbaled || loading ? (
            <button
              disabled={disbaled}
              className='mt-2 btn-custom btn-custom-disable '
            >
              <i className='fa fa-spinner fa-spin'></i>
            </button>
          ) : (
            <button className=' mt-2 btn-custom' onClick={handleSave}>
              حفظ
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ExcelInvoicePage;
