import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import PageTitle from '../../../Components/Uitily/PageTitle';
import axios from 'axios';
import Joi from 'joi';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import notify from '../../../hook/useNotifaction';
import Select from 'react-select'

import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlusCircle,
} from 'react-icons/ai';
import baseUrl from '../../../Api/baseUrl';

export default function Manulnvoicepage() {
  let Navigate = useNavigate();
  let companyId = localStorage.getItem('companyId');
  let [clients, setClients] = useState([]);
  let [getCurrencies, setGetCurrencies] = useState([]);
  let [taxType, setTaxType] = useState([]);
  let [nonTaxType, setNonTaxType] = useState([]);
  let [subTaxType, setSubTaxType] = useState([]);
  let [isLoading, setisLoading] = useState(false);
  let [disbaled, setDisbaled] = useState(false);

  async function getTaxTypes() {
    let { data } = await baseUrl.get(`/json/getTaxTypes`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    setTaxType(data.data);
  }
  async function getNonTaxTypes() {
    let { data } = await baseUrl.get(`/json/getNonTaxTypes`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    setNonTaxType(data.data);
  }
  async function getSubTaxTypes(id) {
    let { data } = await baseUrl.get(`/json/getTaxSubTypes?taxType=${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    setSubTaxType(data.data);
  }
  async function getcurrencies() {
    let { data } = await baseUrl.get(`/json/getCurrencies`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    setGetCurrencies(data.data);
  }

  const currencyData = Object.values(getCurrencies).reduce(
    (acc, curr) => acc.concat(curr),
    []
  );
  async function getAllclients() {
    await baseUrl
      .post(
        `/clients/getClients`,
        { companyId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )
      .then(res => {
        const formattedClients = res.data.data.map(client => {
          return {
            value: client._id,
            label: client.clientName,
          };
        });
        setClients(formattedClients);
      })
      .catch(err => {
        if (err.response.data.statusCode === '401') {
          Navigate('/');
        } else if (err.response.data.status === 'fail') {
          setClients([]);
          setisLoading(false);
        }
      });
  }

  async function getCountries(e) {
    let { data } = await baseUrl.get(`/json/getCountries`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    setGetcountries(data.data);
  }

  /*Get All Products */
  const [products, setProducts] = useState([]);
  async function getAllproduct() {
    await baseUrl
      .post(
        `/products/getAllProductsUnpaginated`,
        { companyId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )
      .then(res => {
        const formattedProducts = res.data.data.map(product => {
          return {
            value: product._id,
            label: product.itemName,
          };
        });
        setProducts(formattedProducts);
      })
      .catch(err => {
        if (err.response.data.statusCode === '401') {
          Navigate('/');
        } else if (err.response.data.status === 'fail') {
          setProducts([]);
        }
      });
  }

  async function loadingData() {
    try {
      setisLoading(true);
      setDisbaled(true);
      await Promise.all(
        getcurrencies(),
        getAllclients(),
        getAllproduct(),
        getTaxTypes(),
        getNonTaxTypes(),
        getCountries()
      );
    } catch (error) {
    } finally {
      setisLoading(false);
      setDisbaled(false);
    }
  }
  useEffect(() => {
    loadingData();
  }, []);

  /*Start Add Client Modal */
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = e => {
    e.preventDefault();
    setShow(true);
  };
  let [validationError, setvalidationError] = useState([]);

  let [labelType, setLabelType] = useState('الرقم الضريبي');
  let [getcountries, setGetcountries] = useState([]);

  const countryData = Object.values(getcountries).reduce(
    (acc, curr) => acc.concat(curr),
    []
  );
  let [user, setUser] = useState({
    clientName: '',
    taxNumber: '',
    phone: '',
    type: '',
    country: '',
    governate: '',
    regionCity: '',
    buildingNumber: '',
    street: '',
    companyId: companyId,
  });
  function getDataClient(e) {
    let currentUser = { ...user };
    currentUser[e.target.name] = e.target.value;
    setUser(currentUser);
  }
  useEffect(() => {
    if (user.type === 'P') {
      setLabelType('الرقم القومي');
    } else if (user.type === 'B') {
      setLabelType('الرقم الضريبي');
    } else if (user.type === 'F') {
      setLabelType('رقم الباسورد');
    }
  }, [user.type]);
  function vaildation() {
    let schema = Joi.object({
      clientName: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك اكتب اسمك العميل' }),
      taxNumber: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك ادخل الرقم الضريبي' }),
      phone: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك ادخل هاتف' }),
      type: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك ادخل النوع' }),
      country: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك اختر البلد' }),
      governate: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك ادخل الوصف المحافظة' }),
      regionCity: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك ادخل المنطقة ' }),
      buildingNumber: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك ادخل رقم المبني ' }),
      street: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك اختر اسم الشارع' }),
      companyId,
    });
    let res = schema.validate(user, { abortEarly: false });

    if (res.error) {
      setvalidationError(res.error.details);
      return false;
    } else {
      return true;
    }
  }
  async function addNewclient(e) {
    e.preventDefault();
    if (vaildation()) {
      setisLoading(true);
      setDisbaled(true);
      await baseUrl
        .post(`/clients/addClient`, user, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        .then(res => {
          if (res.data.message === 'تم الاضافة') {
            setisLoading(false);
            setDisbaled(false);
            notify('تم اضافة المستخدم بنجاح', 'success');

            setTimeout(() => {
              setShow(false);
            }, 1000);
            getAllclients();
          }
        })
        .catch(err => {
          if (err.response.data.errors) {
            setisLoading(false);
            setDisbaled(false);
            err.response.data.errors.map(error => notify(error.msg, 'error'));
          }
        });
    }
  }
  /*add tax */
  const [taxShow, setTaxShow] = useState(false);

  const handleAddTaxShow = () => {
    setTaxShow(true);
  };
  const closeAddTaxShow = () => {
    setTaxShow(false);
    setTaxShow(false);
    setTaxsValidationError([]);
    setTaxableItemsObject({
      taxType: '',
      amount: 0,
      subType: '',
      rate: 0,
      taxValue: 0,
      type: 'value',
    });
    setIsTaxEdit(false);
  };

  const [clientId, setClientId] = useState('');

  const [internalId, setInternalId] = useState('');
  const [extraDiscountAmount, setExtraDiscountAmount] = useState(0);
  const [documentType, setDocumentType] = useState('');

  const [invoicesValidationError, setInvoicesValidationError] = useState([]);
  function invoicesVaildation() {
    let schema = Joi.object({
      clientId: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك اختر العميل' }),
      internalId: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك ادخل الرقم الداخلى للفاتورة' }),
      documentType: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك اختر النوع' }),
    });
    let res = schema.validate(
      { clientId, internalId, documentType },
      { abortEarly: false }
    );

    if (res.error) {
      setInvoicesValidationError(res.error.details);
      return false;
    } else {
      return true;
    }
  }
  const [isTaxable, setIsTaxable] = useState(true);
  const onChangeIsTaxable = () => {
    setIsTaxable(!isTaxable);
  };

  const [allInvoiceLines, setAllInvoiceLines] = useState([]);
  const [taxableItemsArray, setTaxableItemsArray] = useState([]);
  const [taxableItemsObject, setTaxableItemsObject] = useState({
    taxType: '',
    amount: 0,
    subType: '',
    rate: 0,
    taxValue: 0,
    type: 'value',
  });

  const [selectedInvoiceLine, setSelectedInvoiceLine] = useState({
    itemId: '',
    description: '',
    itemCode: '',
    unitType: '',
    quantity: '',
    unitValue: {
      currencySold: 'EGP',
      amountEGP: '',
      amountSold: '',
      currencyExchangeRate: '',
    },
    salesTotal: 0,
    discountNum: '',
    discounType: 'discountValue',
    discount: {
      rate: 0,
      amount: 0,
    },
    total: 0,
    valueDifference: 0,
    totalTaxableFees: 0,
    netTotal: 0,
    itemsDiscount: '',
  });

  function getTaxableItems(e) {
    let currentTaxableItems = { ...taxableItemsObject };

    if (e.target.name === 'taxType') {
      currentTaxableItems[e.target.name] = e.target.value;
      getSubTaxTypes(e.target.value);
    } else if (e.target.name === 'taxValue') {
      currentTaxableItems[e.target.name] = Number(e.target.value);
    } else {
      currentTaxableItems[e.target.name] = e.target.value;
    }
    if (currentTaxableItems.type === 'value') {
      currentTaxableItems.amount = currentTaxableItems.taxValue;
      currentTaxableItems.rate = 0;
    } else if (currentTaxableItems.type === 'rate') {
      currentTaxableItems.rate = currentTaxableItems.taxValue;
      currentTaxableItems.amount =
        (selectedInvoiceLine.netTotal * currentTaxableItems.rate) / 100;
    }

    setTaxableItemsObject(currentTaxableItems);
  }

  const [taxsValidationError, setTaxsValidationError] = useState([]);
  function taxsVaildation() {
    let schema = Joi.object({
      taxType: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك اختر النوع  ' }),
      subType: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك اختر الفرعى' }),
      taxValue: Joi.number().required().messages({
        'number.base': 'من فضلك ادخل القيمة',
      }),
    });
    let res = schema.validate(
      {
        taxType: taxableItemsObject.taxType,
        subType: taxableItemsObject.subType,
        taxValue: taxableItemsObject.taxValue,
      },
      { abortEarly: false }
    );

    if (res.error) {
      setTaxsValidationError(res.error.details);
      return false;
    } else {
      return true;
    }
  }

  const AddTaxableItems = e => {
    e.preventDefault();
    if (taxsVaildation()) {
      setTaxableItemsArray([...taxableItemsArray, taxableItemsObject]);

      setTaxShow(false);
      setTaxsValidationError([]);
      setTaxableItemsObject({
        taxType: '',
        amount: 0,
        subType: '',
        rate: 0,
        taxValue: 0,
        type: 'value',
      });
    }
  };
  const getTotalTaxableFees = (taxableItems, netTotal) => {
    let taxableItemsCodes = ['T5', 'T6', 'T7', 'T8', 'T9', 'T10', 'T11', 'T12'];
    let totalTaxableFees = 0;
    for (let x = 0; x < taxableItems.length; x++) {
      for (let i = 0; i < taxableItemsCodes.length; i++) {
        if (taxableItems[x].taxType === taxableItemsCodes[i]) {
          if (taxableItems[x].rate === 0) {
            totalTaxableFees += taxableItems[x].amount;
          } else {
            let amount = (taxableItems[x].rate * netTotal) / 100;
            totalTaxableFees += amount;
          }
        }
      }
    }
    return totalTaxableFees;
  };
  const getTotal = (salesTotal, totalTaxableFees) => {
    return salesTotal + totalTaxableFees;
  };

  const handleProduct = e => {
    console.log("Product Select",e)
    if (e.value !== '0') {
      async function getDataProduct() {
        const { data } = await baseUrl.get(
          `/products/getProduct/${e.value}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        setSelectedInvoiceLine({
          itemId: data.data._id,
          description: data.data.description,
          itemCode: data.data.itemCode,
          unitType: data.data.unitType,
          quantity: selectedInvoiceLine.quantity,
          itemsDiscount: selectedInvoiceLine.itemsDiscount,
          salesTotal: selectedInvoiceLine.quantity * data.data.itemPrice,
          totalTaxableFees: selectedInvoiceLine.totalTaxableFees,
          netTotal: selectedInvoiceLine.netTotal,
          unitValue: {
            currencySold: selectedInvoiceLine.unitValue.currencySold,
            amountEGP: data.data.itemPrice,
            amountSold: selectedInvoiceLine.unitValue.amountSold,
            currencyExchangeRate:
              selectedInvoiceLine.unitValue.currencyExchangeRate,
          },
          discountNum: selectedInvoiceLine.discountNum,
          discounType: selectedInvoiceLine.discounType,
          discount: {
            rate: selectedInvoiceLine.discount.rate,
            amount: selectedInvoiceLine.discount.amount,
          },
          total: selectedInvoiceLine.total,
          valueDifference: selectedInvoiceLine.valueDifference,
        });
      }
      getDataProduct();
    }
  };

  const [isEGP, setIsEGP] = useState(true);
  function getInvoiceData(e) {
    let currentInvoice = { ...selectedInvoiceLine };
    if (e.target.name === 'currencySold') {
      currentInvoice.unitValue[e.target.name] = e.target.value;
    } else if (
      e.target.name === 'amountEGP' ||
      e.target.name === 'amountSold' ||
      e.target.name === 'currencyExchangeRate'
    ) {
      currentInvoice.unitValue[e.target.name] = Number(e.target.value);
    } else if (
      e.target.name === 'quantity' ||
      e.target.name === 'itemsDiscount'
    ) {
      currentInvoice[e.target.name] = Number(e.target.value);
    } else {
      currentInvoice[e.target.name] = e.target.value;
    }
    if (currentInvoice.unitValue.currencySold !== 'EGP') {
      currentInvoice.unitValue.amountEGP =
        currentInvoice.unitValue.amountSold *
        currentInvoice.unitValue.currencyExchangeRate;
    }
    if (currentInvoice.discountNum === '') {
      currentInvoice.discount.amount = 0;
    }
    if (currentInvoice.discounType === 'discountValue') {
      currentInvoice.discount.amount = Number(currentInvoice.discountNum);
      currentInvoice.discount.rate =
        currentInvoice.discount.amount === 0
          ? 0
          : (currentInvoice.discount.amount * 100) / currentInvoice.salesTotal;
    } else if (currentInvoice.discounType === 'discountRate') {
      currentInvoice.discount.rate = Number(currentInvoice.discountNum);
      currentInvoice.discount.amount =
        (currentInvoice.salesTotal * currentInvoice.discount.rate) / 100;
    }
    selectedInvoiceLine.salesTotal =
      currentInvoice.unitValue.amountEGP * currentInvoice.quantity;
    selectedInvoiceLine.netTotal =
      currentInvoice.unitValue.amountEGP * currentInvoice.quantity -
      currentInvoice.discount.amount;
    console.log("currentInvoice",currentInvoice)
    setSelectedInvoiceLine(currentInvoice);
  }

  const getSalesTotal = (quantity, amountEGP) => {
    return quantity * amountEGP;
  };
  const getNetTotal = (salesTotal, amount) => {
    return salesTotal - amount;
  };
  useMemo(() => {
    if (selectedInvoiceLine.unitValue.currencySold === 'EGP') {
      setIsEGP(true);
    } else if (selectedInvoiceLine.unitValue.currencySold !== 'EGP') {
      setIsEGP(false);
      selectedInvoiceLine.unitValue.amountEGP =
        selectedInvoiceLine.unitValue.amountSold *
        selectedInvoiceLine.unitValue.currencyExchangeRate;
    }
  }, [selectedInvoiceLine]);
  useMemo(() => {
    selectedInvoiceLine.salesTotal = getSalesTotal(
      selectedInvoiceLine.quantity,
      selectedInvoiceLine.unitValue.amountEGP
    );
  }, [selectedInvoiceLine]);
  useMemo(() => {
    if (isTaxable === true) {
      selectedInvoiceLine.itemsDiscount = '';

      selectedInvoiceLine.netTotal = getNetTotal(
        selectedInvoiceLine.salesTotal,
        selectedInvoiceLine.discount.amount
      );
    } else {
      selectedInvoiceLine.discountNum = '';
      selectedInvoiceLine.discount.amount = 0;
      selectedInvoiceLine.discount.rate = 0;

      selectedInvoiceLine.netTotal = getNetTotal(
        selectedInvoiceLine.salesTotal,
        0
      );
    }
  }, [selectedInvoiceLine, isTaxable]);

  useEffect(() => {
    taxableItemsArray.map((item, index) => {
      console.log(item, index);

      if (item.type === 'rate') {
        item.amount = (selectedInvoiceLine.netTotal * Number(item.rate)) / 100;
      }
    });
  }, [
    selectedInvoiceLine,
    taxableItemsObject.type,
    taxableItemsObject.taxValue,
  ]);
  useMemo(() => {
    selectedInvoiceLine.totalTaxableFees = getTotalTaxableFees(
      taxableItemsArray,
      selectedInvoiceLine.netTotal
    );
  }, [selectedInvoiceLine, taxableItemsArray]);
  const [invoiceLineValidationError, setInvoiceLineValidationError] = useState(
    []
  );

  console.log('taxableItemsArray', taxableItemsArray);

  function invoiceLineValidation() {
    let schema = Joi.object({
      itemId: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك اختر الصنف' }),
      description: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك ادخل الوصف' }),
      quantity: Joi.number()
        .required()
        .messages({ 'number.base': ' من فضلك ادخل الكمية الصنف' }),

      amountEGP: Joi.number().min(0).required().messages({
        'number.base': 'من فضلك ادخل  السعر المصرى',
        'number.min': 'من فضلك ادخل  السعر المصرى',
      }),
    });
    let res = schema.validate(
      {
        description : selectedInvoiceLine.description,
        itemId: selectedInvoiceLine.itemId,
        quantity: selectedInvoiceLine.quantity,
        amountEGP: selectedInvoiceLine.unitValue.amountEGP,
      },
      { abortEarly: false }
    );

    if (res.error) {
      console.log(res);
      setInvoiceLineValidationError(res.error.details);
      return false;
    } else {
      return true;
    }
  }

  useMemo(() => {
    selectedInvoiceLine.total = getTotal(
      selectedInvoiceLine.salesTotal,
      selectedInvoiceLine.totalTaxableFees
    );
  }, [selectedInvoiceLine]);

  const handleAddInvoiceLine = e => {
    e.preventDefault();
    if (invoiceLineValidation()) {
      selectedInvoiceLine.totalTaxableFees = getTotalTaxableFees(
        taxableItemsArray,
        selectedInvoiceLine.netTotal
      );

      if (isTaxable) {
        selectedInvoiceLine.itemsDiscount = 0;
      }
      if (isTaxable === false) {
        selectedInvoiceLine.discountNum = 0;
        selectedInvoiceLine.discount.amount = 0;
        selectedInvoiceLine.discount.rate = 0;
      }

      let finalInvoiceLineData;
      console.log('selectedInvoiceLine', selectedInvoiceLine);
      const nonTaxableItemsCodes = ['T13', 'T14', 'T15', 'T16', 'T17', 'T18', 'T19', 'T0'];
      const taxableItemsWithFeesCodes = ['T5','T7','T8','T9','T10','T11','T12']
      // const totalTaxableFees = taxableItemsArray?.reduce(
      //   // (acc, curr) => acc + curr.taxValue,
      //   (acc, curr) => {
      //     // check if its between T5 and T12
      //     console.log("Current In Taxes Reduce 🚕", curr)
      //     if (nonTaxableItemsCodes.includes(curr.taxType)) return acc;
         
      //       if (curr?.rate === 0) {
      //         return acc + curr?.amount;
      //       }
      //       return acc + (curr?.rate * selectedInvoiceLine.netTotal) / 100;

      //   },
      //   0
      // );

      const calculateTaxbleItems = () => {
        const totalTaxableFees = 0;
        const totalNonTaxableFees = 0

        // first we need to calculate the total taxable fees
        taxableItemsArray.forEach(item => {
          const taxType = item.taxType;
          // check if the item is taxable
          if (taxableItemsWithFeesCodes.includes(taxType)) {
            // taxable Items
            totalTaxableFees += item.rate > 0 ? item.rate * selectedInvoiceLine.netTotal : item.amount
          }
          else if (nonTaxableItemsCodes.includes(taxType)) {
            // non taxable Items
            totalNonTaxableFees += item.rate > 0 ? item.rate * selectedInvoiceLine.netTotal : item.amount
          }
        })

        // get the new Taxes Array
        const taxes = taxableItemsArray?.map(item => {
          /*
            item 
            {
              amount: 0,
              rate: 0,
              taxType: "T5",
              subType: "V002",
              // taxValue: 0
            }
          */
          const taxType = item.taxType;
          const isItemRate = item.rate > 0;
          console.log("taxType", item)
          // check if the item is taxable 
          if (taxableItemsWithFeesCodes.includes(taxType)) {
            // taxable Items 
            return {
              taxType: item.taxType,
              subType: item.subType,
              amount : isItemRate ? Math.round(item.amount*100000)/100000 : item.amount,
              rate: item.rate,
            }
          }
          else if (nonTaxableItemsCodes.includes(taxType)) {
            // non taxable Items 
            return {
              taxType: item.taxType,
              subType: item.subType,
              amount : isItemRate ? Math.round(item.amount*100000)/100000 : item.amount,
              rate: item.rate,
            }
          } else if (taxType === 'T2')
          {
            // T2
            // get all items with T3 
            // sum of all T3 items
            const t3ItemsTotal = taxableItemsArray?.reduce((acc, curr) => {
              if (curr.taxType === 'T3') {
                return acc + curr.amount
              }
              return acc
            }, 0)
            let amount = ((selectedInvoiceLine.netTotal - selectedInvoiceLine.itemsDiscount + totalTaxableFees + selectedInvoiceLine.valueDifference) * item.rate) / 100
            // get the amount to 5 decimal places
            return {
              taxType: item.taxType,
              subType: item.subType,
              amount : isItemRate ? Math.round(amount*100000)/100000 : item.amount,
              rate: item.rate,
            }

          } else if (taxType === 'T3') 
          {
            // T3
            return {
              taxType: item.taxType,
              subType: item.subType,
              amount : isItemRate ? Math.round(item.amount*100000)/100000 : item.amount,
              rate:0,
            }
          } else if (taxType === 'T4') 
          {
            // T4
            // GET ALL T4 ITEMS WITH THE SAME SUBTYPE 
            
            const amount = item.rate * (selectedInvoiceLine.netTotal - selectedInvoiceLine.itemsDiscount) / 100
            return {
              taxType: item.taxType,
              subType: item.subType,
              amount : isItemRate ? Math.round(amount*100000)/100000 : item.amount,
              rate: item.rate,
            }
          } else if (taxType === 'T1') 
          {
            // T1
            // get the amount of T2 
            const t2Item = taxableItemsArray?.find(item => item.taxType === 'T2')
            const amount = ((selectedInvoiceLine.netTotal - selectedInvoiceLine.itemsDiscount + totalTaxableFees + selectedInvoiceLine.valueDifference + (t2Item?.amount || 0)) * item.rate)/100
            return {
              taxType: item.taxType,
              subType: item.subType,
              amount : isItemRate ? Math.round(amount*100000)/100000 : item.amount,
              rate: item.rate,
            }
          } else if (taxType === 'T6') {
            return {
              taxType: item.taxType,
              subType: item.subType,
              amount : isItemRate ? Math.round(item.amount*100000)/100000 : item.amount,
              rate:0,
            }
          }
        });
        // get all T4 ITEMS AND SUM THE SAME SUBTYPE TOGETHER 
        const t4ItemsTotal = taxableItemsArray?.reduce((acc, curr) => {
          if (curr.taxType === 'T4') {
            return acc + curr.amount
          }
          return acc
        }, 0) 

        console.log("T4 ITEMS TOTAL", t4ItemsTotal , taxableItemsArray)

        // GET THE TOTAL AMOUNT OF THE ITEMS THAT CAN BE TAXABLE THE T1,T2,T3,T4 and the taxable items codes with fees
        const taxableItemsCodes = ['T1', 'T2', 'T3']
        const toalTaxbleItemsAmount = taxableItemsArray?.reduce((acc, curr) => {
          if (taxableItemsCodes.includes(curr.taxType)) {
            return acc + curr.amount
          }
          return acc
        }, 0)

        const invoiceLineTotal = selectedInvoiceLine.netTotal - selectedInvoiceLine.itemsDiscount + totalTaxableFees  + totalNonTaxableFees + toalTaxbleItemsAmount - t4ItemsTotal

        // round the amount to 5 decimal places using math.round
        return {
          totalNonTaxableFees:Math.round(totalNonTaxableFees * 100000) / 100000,
          totalTaxableFees:Math.round(totalTaxableFees * 100000) / 100000,
          taxes,
          invoiceLineTotal:Math.round(invoiceLineTotal * 100000) / 100000
        }
      }
      const {taxes,totalNonTaxableFees,totalTaxableFees,invoiceLineTotal} = calculateTaxbleItems()
      if (selectedInvoiceLine.unitValue.currencySold === 'EGP') {
        finalInvoiceLineData = {
          // ...selectedInvoiceLine,
          taxableItems:taxes,
          itemId: selectedInvoiceLine.itemId,
          itemCode: selectedInvoiceLine.itemCode,
          description: selectedInvoiceLine.description,
          unitType: selectedInvoiceLine.unitType,
          quantity: selectedInvoiceLine.quantity,
          unitValue: {
            currencySold: selectedInvoiceLine.unitValue.currencySold,
            amountEGP: selectedInvoiceLine.unitValue.amountEGP,
            amountSold:
              selectedInvoiceLine.unitValue.currencySold === 'EGP'
                ? null
                : selectedInvoiceLine.unitValue.amountSold,
            currencyExchangeRate:
              selectedInvoiceLine.unitValue.currencySold === 'EGP'
                ? null
                : selectedInvoiceLine.unitValue.currencyExchangeRate,
          },
          salesTotal: selectedInvoiceLine.salesTotal,
          total:invoiceLineTotal,
          valueDifference: 0,
          totalTaxableFees,
          netTotal:
            Number(selectedInvoiceLine.salesTotal) -
            Number(selectedInvoiceLine.discount.amount),
          discount: selectedInvoiceLine.discount,
          itemsDiscount: selectedInvoiceLine.itemsDiscount,
        };
      } else {
        finalInvoiceLineData = {
          ...selectedInvoiceLine,
        };
      }
      // finalInvoiceLineData.taxableItems = taxableItemsArray;

      setAllInvoiceLines([...allInvoiceLines, finalInvoiceLineData]);
      setInvoiceLineValidationError([]);
      setTaxableItemsArray([]);
      setSelectedInvoiceLine({
        itemId: '',
        description: '',
        itemCode: '',
        unitType: '',
        quantity: '',
        unitValue: {
          currencySold: 'EGP',
          amountEGP: '',
          amountSold: '',
          currencyExchangeRate: '',
        },
        salesTotal: 0,
        discountNum: '',
        discounType: 'discountValue',
        discount: {
          rate: 0,
          amount: 0,
        },
        total: 0,
        valueDifference: 0,
        totalTaxableFees: 0,
        netTotal: 0,
        itemsDiscount: '',
      });
    }
  };

  const handleAddInvoice = async e => {
    e.preventDefault();

    if (invoicesVaildation()) {
      setInvoicesValidationError([]);

      if (allInvoiceLines.length < 1) {
        notify('ادخل الفاتورة', 'warn');
        return;
      }
      // setisLoading(true);
      // setDisbaled(true);

      const invoiceLines = allInvoiceLines.map(ele => {
        console.log(ele);
        return {
          ...ele,
          taxableItems: ele.taxableItems.map(item => {
            const { taxValue, type, ...rest } = item;
            return {
              ...rest,
            };
          }),
        };
      });

      const body = {
        company: { companyId },
        clientId,
        internalId,
        documentType,
        invoiceLines,
        taxTotals: totalTaxes,
        extraDiscountAmount,
      };

      // const newBody = [
      //   {
      //     ...body,
      //     invoiceLines: invoiceLines.map(ele => {
      //       const {
      //         description,
      //         itemCode,
      //         unitType,
      //         discountNum,
      //         discounType,
      //         ...rest
      //       } = ele;
      //       return {
      //         ...rest,
      //       };
      //     }),
      //   },
      // ];
      await baseUrl
        .post(`/invoices/saveInvoice`, [body], {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        .then(res => {
          console.log(res);
          if (res.status === 201) {
            setisLoading(false);
            setDisbaled(false);
            notify('تم الحفظ  فى الفواتير الداخلية بنجاح', 'success');
            setTimeout(() => {
              setClientId('');
              setInternalId('');
              setDocumentType('');
              setExtraDiscountAmount('');
              setTaxableItemsArray([]);
              setAllInvoiceLines([]);
              setTotalTaxes([]);
              setTotals({
                totalSalesAmount: 0,
                totalDiscountAmount: 0,
                netTotal: 0,
                totalItemsDiscountAmount: 0,
                totalAmount: 0,
                totalTaxableFees: 0,
              });
            }, 1000);
          }
        })
        .catch(err => {
          if (err.response.data.errors) {
            err.response.data.errors.map(error => notify(error.msg, 'error'));
            setisLoading(false);
            setDisbaled(false);
          } else if (err.response.data.statusCode === '401') {
            localStorage.clear();
            Navigate('/');
          }
        });
    }
  };

  const [isEdit, setIsEdit] = useState(false);
  const [currentIndex, setCurrentIndex] = useState('');
  const handleEditInfo = index => {
    setCurrentIndex(index);
    setIsEdit(true);
    console.log(index);
    console.log(allInvoiceLines[index]);

    setSelectedInvoiceLine({
      itemId: allInvoiceLines[index].itemId,
      description: allInvoiceLines[index].description,
      itemType: allInvoiceLines[index].itemType,
      itemCode: allInvoiceLines[index].itemCode,
      unitType: allInvoiceLines[index].unitType,
      quantity: allInvoiceLines[index].quantity,
      itemsDiscount: allInvoiceLines[index].itemsDiscount,
      salesTotal: allInvoiceLines[index].salesTotal,
      totalTaxableFees: allInvoiceLines[index].totalTaxableFees,
      netTotal: allInvoiceLines[index].netTotal,
      unitValue: {
        currencySold: allInvoiceLines[index].unitValue.currencySold,
        amountEGP: allInvoiceLines[index].unitValue.amountEGP,
        amountSold: allInvoiceLines[index].unitValue.amountSold,
        currencyExchangeRate:
          allInvoiceLines[index].unitValue.currencyExchangeRate,
      },
      discountNum: allInvoiceLines[index].discountNum,
      discounType: allInvoiceLines[index].discounType,
      discount: {
        rate: allInvoiceLines[index].discount.rate,
        amount: allInvoiceLines[index].discount.amount,
      },
      total: allInvoiceLines[index].total,
      valueDifference: allInvoiceLines[index].valueDifference,
    });
    setTaxableItemsArray(allInvoiceLines[index].taxableItems);
  };

  const handleEditInvoiceLine = e => {
    e.preventDefault();
    console.log(currentIndex);
    console.log(selectedInvoiceLine);
    if (isTaxable) {
      selectedInvoiceLine.itemsDiscount = 0;
    }
    selectedInvoiceLine.taxableItems = taxableItemsArray;

    let finalInvoiceLineData;
    // any data clerance
    if (selectedInvoiceLine.unitValue.currencySold === 'EGP') {
      finalInvoiceLineData = {
        ...selectedInvoiceLine,
        unitValue: {
          currencySold: selectedInvoiceLine.unitValue.currencySold,
          amountEGP: selectedInvoiceLine.unitValue.amountEGP,
          amountSold: selectedInvoiceLine.unitValue.amountEGP,
          currencyExchangeRate: 1,
        },
      };
    }

    const tempallInvoiceLines = allInvoiceLines;
    Object.assign(tempallInvoiceLines[currentIndex], finalInvoiceLineData);
    setAllInvoiceLines([...tempallInvoiceLines]);
    setInvoiceLineValidationError([]);
    setSelectedInvoiceLine({
      itemId: '',
      description: '',
      itemCode: '',
      unitType: '',
      quantity: '',
      unitValue: {
        currencySold: 'EGP',
        amountEGP: '',
        amountSold: '',
        currencyExchangeRate: '',
      },
      salesTotal: 0,
      discountNum: '',
      discounType: 'discountValue',
      discount: {
        rate: 0,
        amount: 0,
      },
      total: 0,
      valueDifference: 0,
      totalTaxableFees: 0,
      netTotal: 0,
      itemsDiscount: '',
    });
    setTaxableItemsArray([]);

    setIsEdit(false);
  };

  const [isTaxEdit, setIsTaxEdit] = useState(false);
  const handleEditTaxInfo = index => {
    setCurrentIndex(index);
    setIsTaxEdit(true);
    console.log(index);
    console.log(taxableItemsArray[index]);
    setTaxShow(true);
    setTaxableItemsObject({
      taxType: taxableItemsArray[index].taxType,
      subType: taxableItemsArray[index].subType,
      taxValue:
        taxableItemsArray[index].rate !== 0
          ? taxableItemsArray[index].rate
          : taxableItemsArray[index].amount,
      type: taxableItemsArray[index].rate !== 0 ? 'rate' : 'value',
      amount: taxableItemsArray[index].amount,
      rate: taxableItemsArray[index].rate,
    });
  };
  const handleEditTax = e => {
    e.preventDefault();
    console.log(currentIndex);
    console.log(taxableItemsObject);

    const tempTax = taxableItemsArray;
    Object.assign(taxableItemsArray[currentIndex], taxableItemsObject);

    if (taxsVaildation()) {
      setTaxableItemsArray([...tempTax]);

      console.log(taxableItemsArray);

      setTaxShow(false);
      setIsTaxEdit(false);
      setTaxableItemsObject({
        taxType: '',
        amount: 0,
        subType: '',
        rate: 0,
        taxValue: 0,
        type: 'value',
      });
    }
  };
  const handleDeleteInvoiceLine = index => {
    let currentArray = [...allInvoiceLines];
    currentArray.splice(index, 1);
    setAllInvoiceLines(currentArray);
  };
  const handleDeleteTax = index => {
    let currentArray = [...taxableItemsArray];
    currentArray.splice(index, 1);
    setTaxableItemsArray(currentArray);
  };

  // total taxes calculation
  const [totalTaxes, setTotalTaxes] = useState([]);

  const calculateTotalTaxes = () => {
    const taxesTotal = allInvoiceLines.map(invoiceLine => {
      const taxes = {};

      const invoiceLineTotal = invoiceLine.taxableItems.map(taxableItem => {
        if (taxes[taxableItem.taxType]) {
          taxes[taxableItem.taxType] += taxableItem.amount;
        } else {
          taxes[taxableItem.taxType] = taxableItem.amount;
        }
      });
      return taxes;
    });

    const totalTaxes = taxesTotal.reduce((acc, curr) => {
      for (const [key, value] of Object.entries(curr)) {
        if (acc[key]) {
          acc[key] += value;
        } else {
          acc[key] = value;
        }
      }
      return acc;
    }, {});

    // convert object to array
    const totalTaxesArray = Object.entries(totalTaxes).map(([key, value]) => {
      return {
        taxType: key,
        amount: value,
      };
    });

    console.log('Total Taxes Array: ', totalTaxesArray);
    setTotalTaxes(totalTaxesArray);
  };

  useMemo(() => {
    calculateTotalTaxes();
  }, [allInvoiceLines]);
  const [totals, setTotals] = useState({
    totalSalesAmount: 0,
    totalDiscountAmount: 0,
    netTotal: 0,
    totalItemsDiscountAmount: 0,
    totalAmount: 0,
    totalTaxableFees: 0,
  });

  useMemo(() => {
    const totalSalesAmount = allInvoiceLines.reduce((acc, curr) => {
      return acc + curr.salesTotal;
    }, 0);

    const totalDiscountAmount = allInvoiceLines.reduce((acc, curr) => {
      return acc + curr.discount.amount;
    }, 0);

    const netTotal = allInvoiceLines.reduce((acc, curr) => {
      return acc + curr.netTotal;
    }, 0);

    const totalItemsDiscountAmount = allInvoiceLines.reduce((acc, curr) => {
      return acc + curr.itemsDiscount;
    }, 0);

    const totalsAmount = allInvoiceLines.reduce((acc, curr) => {
      return acc + curr.total;
    }, 0);

    const totalAmount = totalsAmount - Number(extraDiscountAmount);

    const totalTaxableFees = allInvoiceLines.reduce((acc, curr) => {
      return acc + curr.totalTaxableFees;
    }, 0);

    setTotals({
      totalSalesAmount,
      totalDiscountAmount,
      netTotal,
      totalItemsDiscountAmount,
      totalAmount,
      totalTaxableFees,
    });
  }, [allInvoiceLines, extraDiscountAmount, selectedInvoiceLine]);

  return (
    <>
      <div className='page-content'>
        <div className='container-fluid'>
          {/* Modal For Add Client  */}
          <Modal
            show={show}
            onHide={handleClose}
            className='form-modal'
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title>
                <div>اضافة عميل جديد</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='card '>
                <div className='card-body m-3'>
                  <div className='row '>
                    <div className='col-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='clientName'
                          className='col-sm-2  col-xl-2  col-form-label'
                        >
                          الاسم
                        </label>
                        <div className='col-sm-10  col-xl-10'>
                          <input
                            type='text'
                            className='form-control'
                            id='clientName'
                            placeholder='ادخل اسم العميل'
                            name='clientName'
                            onChange={getDataClient}
                          />
                          <div
                            className={
                              validationError.filter(
                                ele => ele.context.label === 'clientName'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              validationError.filter(
                                ele => ele.context.label === 'clientName'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='phoneNumber'
                          className='col-sm-2 col-xl-2  col-form-label'
                        >
                          هاتف
                        </label>
                        <div className='col-sm-10 col-xl-10'>
                          <input
                            type='text'
                            className='form-control'
                            id='phoneNumber'
                            placeholder='ادخل الهاتف '
                            name='phone'
                            onChange={getDataClient}
                          />
                          <div
                            className={
                              validationError.filter(
                                ele => ele.context.label === 'phone'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              validationError.filter(
                                ele => ele.context.label === 'phone'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='type'
                          className='col-sm-2 col-xl-2 col-form-label'
                        >
                          النوع
                        </label>
                        <div className='col-sm-10 col-xl-10'>
                          <select
                            onChange={getDataClient}
                            name='type'
                            className='form-select'
                            id='type'
                          >
                            <option defaultValue='0'>اختر النوع</option>
                            <option value='P'>Person</option>
                            <option value='B'>Business</option>
                            <option value='F'>Foreigner</option>
                          </select>
                          <div
                            className={
                              validationError.filter(
                                ele => ele.context.label === 'type'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              validationError.filter(
                                ele => ele.context.label === 'type'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='taxNumber'
                          className='col-sm-2 col-form-label'
                        >
                          {labelType}
                        </label>
                        <div className='col-sm-10'>
                          <input
                            type='text'
                            className='form-control'
                            id='taxNumber'
                            placeholder={labelType}
                            name='taxNumber'
                            onChange={getDataClient}
                          />
                          <div
                            className={
                              validationError.filter(
                                ele => ele.context.label === 'taxNumber'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              validationError.filter(
                                ele => ele.context.label === 'taxNumber'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-6 col-lg-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='country'
                          className='col-sm-2 col-form-label'
                        >
                          البلد
                        </label>
                        <div className='col-sm-10'>
                          <select
                            className='form-select'
                            name='country'
                            onChange={getDataClient}
                            id='country'
                          >
                            <option defaultValue='0'>اختر البلد</option>
                            {countryData?.map(country => (
                              <option key={country.code} value={country.code}>
                                {country.Desc_ar}
                              </option>
                            ))}
                          </select>

                          <div
                            className={
                              validationError.filter(
                                ele => ele.context.label === 'country'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              validationError.filter(
                                ele => ele.context.label === 'country'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-6 col-lg-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='governate'
                          className='col-sm-2 col-form-label'
                        >
                          المحافظة
                        </label>
                        <div className='col-sm-10'>
                          <input
                            type='text'
                            className='form-control'
                            id='governate'
                            placeholder='cairo'
                            name='governate'
                            onChange={getDataClient}
                          />
                          <div
                            className={
                              validationError.filter(
                                ele => ele.context.label === 'governate'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              validationError.filter(
                                ele => ele.context.label === 'governate'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-6 col-lg-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='regionCity'
                          className='col-sm-2 col-form-label'
                        >
                          المنطقة
                        </label>
                        <div className='col-sm-10'>
                          <input
                            type='text'
                            className='form-control'
                            id='regionCity'
                            placeholder='المنطقة'
                            name='regionCity'
                            onChange={getDataClient}
                          />
                          <div
                            className={
                              validationError.filter(
                                ele => ele.context.label === 'regionCity'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              validationError.filter(
                                ele => ele.context.label === 'regionCity'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-6 col-lg-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='buildNumber'
                          className='col-sm-2 col-form-label'
                        >
                          ر.المبنى
                        </label>
                        <div className='col-sm-10'>
                          <input
                            type='text'
                            className='form-control'
                            id='buildNumber'
                            placeholder='رقم المبنى'
                            name='buildingNumber'
                            onChange={getDataClient}
                          />
                          <div
                            className={
                              validationError.filter(
                                ele => ele.context.label === 'buildingNumber'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              validationError.filter(
                                ele => ele.context.label === 'buildingNumber'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='streetName'
                          className='col-sm-2 col-xl-2  col-form-label'
                        >
                          الشارع
                        </label>
                        <div className='col-sm-10 col-xl-10'>
                          <input
                            type='text'
                            className='form-control'
                            id='streetName'
                            placeholder='اسم الشارع'
                            name='street'
                            onChange={getDataClient}
                          />
                          <div
                            className={
                              validationError.filter(
                                ele => ele.context.label === 'street'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              validationError.filter(
                                ele => ele.context.label === 'street'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='success' onClick={handleClose}>
                تراجع
              </Button>
              {disbaled ? (
                <button
                  disabled={disbaled}
                  className='mt-2 btn-custom btn-custom-disable '
                >
                  <i className='fa fa-spinner fa-spin'></i>
                </button>
              ) : (
                <button
                  className='mt-2 btn-custom'
                  type='submit'
                  onClick={addNewclient}
                >
                  اضافة
                </button>
              )}
            </Modal.Footer>
          </Modal>
          {/* Modal For Add Tax  */}
          <Modal
            show={taxShow}
            onHide={closeAddTaxShow}
            className='form-modal'
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title>
                <div>اضافة ضربية جديد</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='card mt-4'>
                <div className='card-body'>
                  <div className='row '>
                    <div className=' col-lg-12'>
                      <div className='row mb-3'>
                        <div className='col-sm-12'>
                          <select
                            className='form-select border-info'
                            id='taxType'
                            name='taxType'
                            value={taxableItemsObject.taxType}
                            onChange={getTaxableItems}
                          >
                            <option defaultValue hidden value='0'>
                              النوع
                            </option>
                            {isTaxable === true
                              ? taxType
                                ? taxType.map((item, index) => (
                                  <option value={item.Code} key={index}>
                                    {`${item.Desc_ar} ${item.Code}`}
                                  </option>
                                ))
                                : null
                              : nonTaxType
                                ? nonTaxType.map((item, index) => (
                                  <option value={item.Code} key={index}>
                                    {`${item.Desc_ar} ${item.Code}`}
                                  </option>
                                ))
                                : null}
                          </select>
                          <div
                            className={
                              taxsValidationError.filter(
                                ele => ele.context.label === 'taxType'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              taxsValidationError.filter(
                                ele => ele.context.label === 'taxType'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=' col-lg-12'>
                      <div className='row mb-3'>
                        <div className='col-sm-12'>
                          <select
                            className='form-select border-info'
                            id='subType'
                            name='subType'
                            onChange={getTaxableItems}
                            value={taxableItemsObject.subType}
                          >
                            <option defaultValue hidden value='0'>
                              {' '}
                              فرعى
                            </option>
                            {subTaxType
                              ? subTaxType.map((item, index) => (
                                <option value={item.Code} key={index}>
                                  {`${item.Desc_ar}  ${item.Code}`}
                                </option>
                              ))
                              : null}
                          </select>
                          <div
                            className={
                              taxsValidationError.filter(
                                ele => ele.context.label === 'subType'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              taxsValidationError.filter(
                                ele => ele.context.label === 'subType'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=' col-lg-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='taxValue'
                          className='col-sm-2 col-form-label'
                        >
                          القيمة
                        </label>
                        <div className='col-sm-10'>
                          <input
                            type='number'
                            min='0'
                            className='form-control border-info'
                            id='taxValue'
                            placeholder='القيمة'
                            value={taxableItemsObject.taxValue}
                            name='taxValue'
                            onChange={getTaxableItems}
                          />
                          <div
                            className={
                              taxsValidationError.filter(
                                ele => ele.context.label === 'taxValue'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              taxsValidationError.filter(
                                ele => ele.context.label === 'taxValue'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <input
                        type='radio'
                        name='type'
                        value='rate'
                        id='rate'
                        checked={taxableItemsObject.type === 'rate'}
                        onChange={getTaxableItems}
                      />
                      <label htmlFor='rate'>نسبة</label>
                    </div>
                    <div className='col-md-4'>
                      <input
                        type='radio'
                        name='type'
                        value='value'
                        id='taxValueType'
                        checked={taxableItemsObject.type === 'value'}
                        onChange={getTaxableItems}
                      />
                      <label htmlFor='value'>قيمة</label>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='success' onClick={closeAddTaxShow}>
                تراجع
              </Button>
              {isTaxEdit === false ? (
                <Button
                  className='mt-2 btn-custom'
                  type='submit'
                  onClick={AddTaxableItems}
                >
                  اضافة
                </Button>
              ) : (
                <Button
                  className='mt-2 btn-custom'
                  type='submit'
                  onClick={handleEditTax}
                >
                  تعديل
                </Button>
              )}
            </Modal.Footer>
          </Modal>
          <PageTitle title='ارسال الفاتورة يدوى' />
          <form>
            <div className='row pb-4'>
              <div className='card '>
                <div className='card-body m-3'>
                  <div className='row '>
                    <div className='col-xl-6 col-lg-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='clientName'
                          className='col-sm-3 col-form-label'
                        >
                          العميل
                        </label>
                        <div className='col-sm-9'>
                          <Select
                            placeholder='اختر العميل'
                            id='type'
                            name='clientName'
                            options={clients}
                            getValue={() => clientId}
                            onChange={(value) => {
                              console.log("Set Value",value)
                              setClientId(value.value);
                            }}
                          >

                          </Select>
                          <div
                            className={
                              invoicesValidationError.filter(
                                ele => ele.context.label === 'clientId'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              invoicesValidationError.filter(
                                ele => ele.context.label === 'clientId'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-6 col-lg-12'>
                      <div className='row mb-3'>
                        <div className='col-sm-12'>
                          {disbaled === true ? (
                            <button
                              disabled={true}
                              className='mt-2 btn-custom btn-custom-disable '
                            >
                              <i className='fa fa-spinner fa-spin'></i>
                            </button>
                          ) : (
                            <button
                              className='btn btn-primary btn-custom'
                              onClick={handleShow}
                            >
                              اضافة عميل
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='col-xl-6 col-lg-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='documentType'
                          className='col-sm-3  col-xl-3  col-form-label'
                        >
                          نوع الفاتورة
                        </label>
                        <div className='col-sm-9 col-xl-9'>
                          <select
                            className='form-select border-info'
                            id='documentType'
                            name='documentType'
                            value={documentType}
                            onChange={e => setDocumentType(e.target.value)}
                          >
                            <option defaultValue hidden value='0'></option>
                            <option value='i'>فاتورة بيع</option>
                            <option value='c'>اشعار خصم</option>
                            <option value='d'>اشعار اضافة</option>
                            <option value='SS'>ايصال خدمة عامة</option>
                            {/* <option value='RS'>ايصال ارتجاع خدمة عامة</option> */}
                          </select>
                          <div
                            className={
                              invoicesValidationError.filter(
                                ele => ele.context.label === 'documentType'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              invoicesValidationError.filter(
                                ele => ele.context.label === 'documentType'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-xl-6 col-lg-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='internalId'
                          className='col-sm-3 col-form-label'
                        >
                          الرقم الداخلى للفاتورة
                        </label>

                        <div className='col-sm-9 '>
                          <input
                            type='text'
                            className='form-control border-info '
                            id='internalId'
                            name='internalId'
                            value={internalId}
                            onChange={e => setInternalId(e.target.value)}
                          />
                          <div
                            className={
                              invoicesValidationError.filter(
                                ele => ele.context.label === 'internalId'
                              )[0]
                                ? 'alert mt-2 alert-warning'
                                : ''
                            }
                          >
                            {
                              invoicesValidationError.filter(
                                ele => ele.context.label === 'internalId'
                              )[0]?.message
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-6 col-lg-12'>
                      <div className='row mb-3'>
                        <label
                          htmlFor='extraDiscountAmount'
                          className='col-sm-3 col-form-label'
                        >
                          خصم الفاتورة
                        </label>

                        <div className='col-sm-9 '>
                          <input
                            type='number'
                            min='0'
                            className='form-control border-info '
                            id='extraDiscountAmount'
                            name='extraDiscountAmount'
                            value={extraDiscountAmount}
                            onChange={e => {
                              setExtraDiscountAmount(Number(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Tabs
              defaultActiveKey='home'
              id='justify-tab-example'
              className=''
              justify
            >
              <Tab eventKey='home' title='تفاصيل الفاتورة'>
                <div className='row '>
                  <div className='col-xl-4 col-lg-12'>
                    <div className='row mb-3'>
                      <div className='col-sm-12'>
                        <Select
                          placeholder='إختر الصنف'
                          id='type'
                          onChange={handleProduct}
                          name='itemId'
                          getValue={() => selectedInvoiceLine.itemId}
                          options={products}
                        >
                        </Select>
                        <div
                          className={
                            invoiceLineValidationError.filter(
                              ele => ele.context.label === 'itemId'
                            )[0]
                              ? 'alert mt-2 alert-warning'
                              : ''
                          }
                        >
                          {
                            invoiceLineValidationError.filter(
                              ele => ele.context.label === 'itemId'
                            )[0]?.message
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-lg-12'>
                    <div className='row mb-3'>
                      <div className='col-sm-12'>
                        <input
                          type='number'
                          min='0'
                          className='form-control border-info'
                          id='quantity'
                          placeholder='الكمية'
                          name='quantity'
                          value={selectedInvoiceLine.quantity}
                          onChange={e => getInvoiceData(e)}
                        />
                        <div
                          className={
                            invoiceLineValidationError.filter(
                              ele => ele.context.label === 'quantity'
                            )[0]
                              ? 'alert mt-2 alert-warning'
                              : ''
                          }
                        >
                          {
                            invoiceLineValidationError.filter(
                              ele => ele.context.label === 'quantity'
                            )[0]?.message
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-4 col-lg-12'>
                    <div className='row mb-3'>
                      <div className='col-sm-12'>
                        <input
                          type='number'
                          min='0'
                          className='form-control border-info'
                          id='itemsDiscount'
                          placeholder='خصم الصنف'
                          disabled={isTaxable}
                          name='itemsDiscount'
                          value={selectedInvoiceLine.itemsDiscount}
                          onChange={e => getInvoiceData(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-4 col-lg-12'>
                    <div className='row mb-3'>
                      <div className='col-sm-12'>
                        <input
                          type='number'
                          min='0'
                          disabled={!isTaxable ? true : false}
                          className='form-control border-info'
                          placeholder='الخصم'
                          name='discountNum'
                          value={selectedInvoiceLine.discountNum}
                          onChange={e => getInvoiceData(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-lg-12'>
                    <div className='row mb-3'>
                      <div className='col-md-4'>
                        <input
                          type='radio'
                          name='discounType'
                          id='discountRate'
                          disabled={!isTaxable}
                          checked={
                            selectedInvoiceLine.discounType === 'discountRate'
                          }
                          value='discountRate'
                          onChange={e => getInvoiceData(e)}
                        />
                        <label htmlFor='discountRate'>نسبة</label>
                      </div>
                      <div className='col-md-4'>
                        <input
                          type='radio'
                          name='discounType'
                          value='discountValue'
                          id='discountValue'
                          checked={
                            selectedInvoiceLine.discounType === 'discountValue'
                          }
                          onChange={e => getInvoiceData(e)}
                        />
                        <label htmlFor='discountValue'>قيمة</label>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-lg-12'>
                    <div className='row mb-3'>
                      <div className='col-sm-12'>
                        <input
                          type='text'
                          disabled={!isTaxable ? true : false}
                          className='form-control border-info'
                          placeholder="الوصف"
                          name='description'
                          value={selectedInvoiceLine.description}
                          onChange={e => getInvoiceData(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className='col-xl-6 col-lg-12'>
                    <div className='row mb-3'>
                      <label
                        htmlFor='currencySold'
                        className='col-sm-4    col-form-label'
                      >
                        العملة
                      </label>
                      <div className='col-sm-8'>
                        <select
                          className='form-select border-info'
                          id='type'
                          name='currencySold'
                          value={selectedInvoiceLine.unitValue.currencySold}
                          onChange={e => getInvoiceData(e)}
                        >
                          <option defaultValue hidden value='0'>
                            اختر العملة
                          </option>
                          {currencyData?.map(currencies => (
                            <option
                              key={currencies.code}
                              value={currencies.code}
                            >
                              {currencies.Desc_en}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-6 col-lg-12'>
                    <div className='row mb-3'>
                      <label
                        htmlFor='amountEGP'
                        className='col-sm-4    col-form-label'
                      >
                        السعر بالمصرى
                      </label>
                      <div className='col-sm-8'>
                        <input
                          type='number'
                          min='0'
                          className='form-control border-info'
                          id='amountEGP'
                          placeholder='السعر بالمصرى'
                          name='amountEGP'
                          value={
                            isEGP === true
                              ? selectedInvoiceLine.unitValue.amountEGP
                              : (selectedInvoiceLine.unitValue.amountEGP =
                                selectedInvoiceLine.unitValue.amountSold *
                                selectedInvoiceLine.unitValue
                                  .currencyExchangeRate)
                          }
                          onChange={e => getInvoiceData(e)}
                          disabled={!isEGP}
                        />
                        <div
                          className={
                            invoiceLineValidationError.filter(
                              ele => ele.context.label === 'amountEGP'
                            )[0]
                              ? 'alert mt-2 alert-warning'
                              : ''
                          }
                        >
                          {
                            invoiceLineValidationError.filter(
                              ele => ele.context.label === 'amountEGP'
                            )[0]?.message
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-6 col-lg-12'>
                    <div className='row mb-3'>
                      <label
                        htmlFor='amountSold'
                        className='col-sm-4    col-form-label'
                      >
                        السعر بالعملة
                      </label>
                      <div className='col-sm-8'>
                        <input
                          type='number'
                          min='0'
                          className='form-control border-info'
                          id='amountSold'
                          placeholder='السعر بالعملة'
                          name='amountSold'
                          value={selectedInvoiceLine.unitValue.amountSold}
                          onChange={e => getInvoiceData(e)}
                          disabled={isEGP}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-6 col-lg-12'>
                    <div className='row mb-3'>
                      <label
                        htmlFor='currencyExchangeRate'
                        className='col-sm-4    col-form-label'
                      >
                        سعر التحويل
                      </label>
                      <div className='col-sm-8'>
                        <input
                          type='number'
                          min='0'
                          className='form-control border-info'
                          id='currencyExchangeRate'
                          placeholder='سعر التحويل'
                          name='currencyExchangeRate'
                          value={
                            selectedInvoiceLine.unitValue.currencyExchangeRate
                          }
                          onChange={e => getInvoiceData(e)}
                          disabled={isEGP}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='flexSwitchCheckChecked'
                        checked={isTaxable}
                        onChange={onChangeIsTaxable}
                      />
                      <label>خاضع للضريبة</label>
                    </div>
                  </div>
                  <div className='col-12 my-3'>
                    <div className='card'>
                      <div className='card-body'>
                        <table className='table table-hover table-bordered '>
                          <thead>
                            <tr>
                              <th scope='col'>Tax Type</th>
                              <th scope='col'>Tax Sub Type </th>
                              <th scope='col'>Amount</th>
                              <th scope='col'>Rate</th>
                              <th scope='col'>Edit</th>
                              <th scope='col'>Delete</th>

                              <th scope='col'>
                                <AiOutlinePlusCircle
                                  onClick={handleAddTaxShow}
                                  style={{ fontSize: '20px' }}
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {taxableItemsArray
                              ? taxableItemsArray.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.taxType}</td>
                                  <td>{item.subType}</td>
                                  <td>
                                    {item.rate === 0
                                      ? item.amount
                                      : (selectedInvoiceLine.netTotal *
                                        item.rate) /
                                      100}
                                  </td>
                                  <td>{item.rate}</td>

                                  <td>
                                    <span className=' table-icon edit'>
                                      <AiOutlineEdit
                                        onClick={() => {
                                          handleEditTaxInfo(index);
                                        }}
                                      />
                                    </span>
                                  </td>
                                  <td>
                                    <span className=' table-icon delete'>
                                      <AiOutlineDelete
                                        onClick={() => {
                                          handleDeleteTax(index);
                                        }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='row mb-3'>
                      <div className='col-12'>
                        {disbaled ? (
                          <button
                            disabled={disbaled}
                            className='mt-2 btn-custom btn-custom-disable '
                          >
                            <i className='fa fa-spinner fa-spin'></i>
                          </button>
                        ) : isEdit === false ? (
                          <button
                            className='btn btn-primary btn-custom'
                            onClick={handleAddInvoiceLine}
                          >
                            اضافة
                          </button>
                        ) : (
                          <button
                            className='btn btn-primary btn-custom'
                            onClick={handleEditInvoiceLine}
                          >
                            تعديل
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
            <div className='card  mt-4'>
              <div className='card-body'>
                <table className='table table-hover table-bordered '>
                  <thead>
                    <tr>
                      <th scope='col'>Invoice id</th>

                      <th scope='col'>Currency</th>
                      <th scope='col'>Item Name</th>
                      <th scope='col'>Item Code</th>
                      <th scope='col'>QTY</th>
                      <th scope='col'>Sales Price</th>
                      <th scope='col'>DiscountPercent</th>
                      <th scope='col'>DiscountAmount</th>
                      <th scope='col'>Edit</th>
                      <th scope='col'>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allInvoiceLines?.map((ele, index) => (
                      <tr key={index}>
                        <td>{internalId}</td>

                        <td>{ele.unitValue.currencySold}</td>
                        <td>{ele.description}</td>
                        <td>{ele.itemCode}</td>
                        <td>{ele.quantity}</td>
                        <td>{ele.salesTotal}</td>
                        <td>
                          {ele.discount.rate !== 0
                            ? ele.discount.rate
                            : ele.discount.rate}
                        </td>
                        <td>
                          {' '}
                          {ele.discount.amount !== 0
                            ? ele.discount.amount
                            : ele.discount.amount}
                        </td>

                        <td>
                          <span className=' table-icon edit'>
                            <AiOutlineEdit
                              onClick={() => {
                                handleEditInfo(index);
                              }}
                            />
                          </span>
                        </td>
                        <td>
                          <span className=' table-icon delete'>
                            <AiOutlineDelete
                              onClick={() => {
                                handleDeleteInvoiceLine(index);
                              }}
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr />
              <div className='d-flex flex-wrap p-4 pt-0'>
                <p className='ms-2'>
                  العميل :{' '}
                  <strong>
                    {clientId !== ''
                      ? clients
                        ? clients.map(item =>
                          item._id === clientId ? item.clientName : null
                        )
                        : null
                      : ' __'}
                  </strong>
                </p>
                <p className='ms-2'>
                  الصافى :{' '}
                  <strong>
                    {' '}
                    {totals.netTotal > 0 ? totals.netTotal : '__'}
                  </strong>
                </p>
                <p className='ms-2'>
                  اجمالى الفاتورة :{' '}
                  <strong>
                    {totals.totalAmount > 0 ? totals.totalAmount : '__'}{' '}
                  </strong>
                </p>
                <p className='ms-2'>
                  اجمالى الضريبة :{' '}
                  <strong>
                    {totals.totalTaxableFees > 0
                      ? totals.totalTaxableFees
                      : '__'}
                  </strong>
                </p>
              </div>
            </div>

            <div className='card mt-4'>
              <div className='card-body'>
                <table className='table table-hover table-bordered '>
                  <thead>
                    <tr>
                      <th scope='col'>TaxType</th>
                      <th scope='col'>Value </th>
                    </tr>
                  </thead>
                  <tbody>
                    {totalTaxes
                      ? totalTaxes.map((item, index) => (
                        <tr key={index}>
                          <td>{item.taxType}</td>
                          <td>{item.amount}</td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>

            {disbaled || isEdit ? (
              <button
                disabled={disbaled || isEdit}
                className='mt-2 btn-custom btn-custom-disable '
              >
                <i className='fa fa-spinner fa-spin'></i>
              </button>
            ) : (
              <button
                onClick={handleAddInvoice}
                className='btn btn-primary mt-2 me-2 btn-custom'
              >
                حفظ
              </button>
            )}
            {/* {disbaled ? (
              <button
                disabled={disbaled}
                className='mt-2 btn-custom btn-custom-disable '
              >
                <i className='fa fa-spinner fa-spin'></i>
              </button>
            ) : (
              <button className='btn btn-primary mt-2 btn-custom'>ارسال</button>
            )} */}
          </form>
        </div>
      </div>
    </>
  );
}
