import React from "react";
import AdminMangeUser from "./../../Components/Admin/AdminMangeUser";

const AdminMangeUsersPage = () => {
  return (
    <>
      <AdminMangeUser />
    </>
  );
};

export default AdminMangeUsersPage;
