import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Joi from "joi";
import PageTitle from "./../Uitily/PageTitle";
import { useParams } from "react-router-dom";
import notify from "./../../hook/useNotifaction";
import baseUrl from "./../../Api/baseUrl";
const EditClient = () => {
  let [validationError, setvalidationError] = useState([]);
  let Navigate =useNavigate()
  const { id } = useParams();
  let [isLoading, setisLoading] = useState(false);
  let [disbaled, setDisbaled] = useState(false);
  const companyId = localStorage.getItem("companyId");
  let [getcountries, setGetcountries] = useState([]);
  async function getCountries(e) {
    let { data } = await baseUrl.get(
      `/json/getCountries`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    setGetcountries(data.data);
  }
  const countryData = Object.values(getcountries).reduce(
    (acc, curr) => acc.concat(curr),
    []
  );
  let [editUser, setEditUser] = useState({
    type: "",
    taxNumber: "",
    clientName: "",
    phone: "",
    country: "",
    governate: "",
    regionCity: "",
    street: "",
    buildingNumber: "",
    companyId: companyId,
  });
  async function getDataClient() {
    const { data } = await baseUrl.get(
      `/clients/getClient/${id}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    console.log(data.data.company)
    let selector;
    if (data.data.type === 'p') {
      selector = 'person';
    } else if (data.data.type === 'b') {
      selector = 'Business';
    } else if (data.data.type === 'f') {
      selector = 'Foreigner';
    }
    setEditUser({
      type: data.data.type,
      taxNumber:data.data.taxNumber,
      clientName: data.data.clientName,
      phone: data.data.phone,
      country: data.data.country,
      governate: data.data.governate,
      regionCity: data.data.regionCity,
      street: data.data.street,
      buildingNumber: data.data.buildingNumber,
      companyId: companyId,
    });
  }

  useEffect(() => {
    getDataClient();
    getCountries();
  }, []);
  function changeDataClient(e) {
    let currentUser = { ...editUser };
    currentUser[e.target.name] = e.target.value;
    setEditUser(currentUser);
  console.log(currentUser)
  }
  // function vaildation() {
  //   let schema = Joi.object({
  //     clientName: Joi.string()
  //       .required()
  //       .messages({ "string.empty": " من فضلك اكتب اسمك العميل" }),
  //     taxNumber: Joi.string()
  //       .required()
  //       .messages({ "string.empty": "من فضلك ادخل الرقم الضريبي" }),
  //     phone: Joi.string()
  //       .required()
  //       .messages({ "string.empty": "من فضلك ادخل هاتف" }),
  //     type: Joi.string()
  //       .required()
  //       .messages({ "string.empty": "من فضلك ادخل النوع" }),
  //     governate: Joi.string()
  //       .required()
  //       .messages({ "string.empty": "من فضلك ادخل الوصف المحافظة" }),
  //     regionCity: Joi.string()
  //       .required()
  //       .messages({ "string.empty": "من فضلك ادخل المنطقة " }),
  //     buildingNumber: Joi.string()
  //       .required()
  //       .messages({ "string.empty": "من فضلك ادخل رقم المبني " }),
  //     street: Joi.string()
  //       .required()
  //       .messages({ "string.empty": " من فضلك اختر اسم الشارع" }),
  //     companyId,
  //   });
  //   let res = schema.validate(editUser, { abortEarly: false });
  //   console.log(res);
  //   if (res.error) {
  //     setvalidationError(res.error.details);
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  async function updateClient(e) {
    e.preventDefault();
    setDisbaled(true);
    setisLoading(true);
    let  data  = await baseUrl.put(
      `/clients/updateClient/${id}`,
      editUser,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    ) .then((res) => {
      if (res.data.message === "تم التعديل") {
        setisLoading(false);
        setDisbaled(false);
        notify("تم تعديل المستخدم  ", "success");
        setTimeout(() => {
          Navigate("/clients");
        }, 1000);
      }
    })
    .catch((err) => {
      console.log(err)
      if (err.response.data.errors) {
        err.response.data.errors.map((error) => notify(error.msg, "error"));
        setisLoading(false);
        setDisbaled(false);
      }
    });
    console.log(data)
}

  return (
    <div className="page-content">
      <div className="container-fluid">
      <div className="d-flex">
        <PageTitle title="تعديل عميل" />
        <div className="me-2">
        {isLoading?<i className='fa fa-spinner fa-spin'></i>:""}
        </div>
        </div>        <div className="card ">
          <div className="card-body m-3">
            <form onSubmit={updateClient}>
              <div className="row ">
                <div className="col-xl-6 col-lg-6">
                  <div className="row mb-3">
                    <label
                      htmlFor="taxNumber"
                      className="col-sm-2 col-form-label">
                      ر.ضريبى
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="taxNumber"
                        value={editUser.taxNumber}
                        name="taxNumber"
                        onChange={changeDataClient}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row mb-3">
                    <label
                      htmlFor="clientName"
                      className="col-sm-2  col-xl-2  col-form-label">
                      الاسم
                    </label>
                    <div className="col-sm-10  col-xl-10">
                      <input
                        type="text"
                        className="form-control"
                        id="clientName"
                        value={editUser.clientName}
                        name="clientName"
                        onChange={changeDataClient}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row mb-3">
                    <label
                      htmlFor="phone"
                      className="col-sm-2 col-xl-2  col-form-label">
                      هاتف
                    </label>
                    <div className="col-sm-10 col-xl-10">
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        value={editUser.phone}
                        name="phone"
                        onChange={changeDataClient}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row mb-3">
                    <label
                      htmlFor="type"
                      className="col-sm-2 col-xl-2 col-form-label">
                      النوع
                    </label>
                    <div className="col-sm-10 col-xl-10">
                    <select
  onChange={changeDataClient}
  name="type"
  className="form-select"
  id="type"
  value={editUser.type}
>
  <option value="P" defaultValue={editUser.selector === 'person'}>Person</option>
  <option value="B" defaultValue={editUser.selector === 'Business'}>Business</option>
  <option value="F" defaultValue={editUser.selector === 'Foreigner'}>Foreigner</option>
</select>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-12">
                  <div className="row mb-3">
                    <label
                      htmlFor="country"
                      className="col-sm-2 col-form-label">
                      البلد
                    </label>
                    <div className="col-sm-10">
                    <select
                        className="form-select"
                        name="country"
                        defaultValue={editUser.country}
                        onChange={changeDataClient}
                        id="country">
                    {countryData?.map((Count) => (
                          <option key={Count.code} value={Count.code}>
                            {Count.Desc_ar}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12">
                  <div className="row mb-3">
                    <label
                      htmlFor="governorate"
                      className="col-sm-2 col-form-label">
                      المحافظة
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="governate"
                        placeholder="cairo"
                        name="governate"
                        value={editUser.governate}
                        onChange={changeDataClient}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12">
                  <div className="row mb-3">
                    <label htmlFor="region" className="col-sm-2 col-form-label">
                      المنطقة
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="regionCity"
                        placeholder="المنطقة"
                        name="regionCity"
                        value={editUser.regionCity}
                        onChange={changeDataClient}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12">
                  <div className="row mb-3">
                    <label
                      htmlFor="buildNumber"
                      className="col-sm-2 col-form-label">
                      ر.المبنى
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="buildingNumber"
                        placeholder="رقم المبنى"
                        name="buildingNumber"
                        onChange={changeDataClient}
                        value={editUser.buildingNumber}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row mb-3">
                    <label
                      htmlFor="streetName"
                      className="col-sm-2 col-xl-2  col-form-label">
                      الشارع
                    </label>
                    <div className="col-sm-10 col-xl-10">
                      <input
                        type="text"
                        className="form-control"
                        id="streetName"
                        placeholder="اسم الشارع"
                        name="street"
                        onChange={changeDataClient}
                        value={editUser.street}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {disbaled ?
  <button disabled={disbaled} className="mt-2 btn-custom btn-custom-disable ">
    <i className='fa fa-spinner fa-spin'></i> 
  </button>
  :
  <button className="mt-2 btn-custom">
  تعديل
  </button>
}            </form>
           </div>
        </div>
      </div>
    </div>
  );
};

export default EditClient;
