import React, { useState, useEffect } from "react";
import PageTitle from "../Uitily/PageTitle";
import Joi, { object } from "joi";
import axios from "axios";
import notify from "./../../hook/useNotifaction";
import baseUrl from "./../../Api/baseUrl";
import { useNavigate } from "react-router-dom";
import Select  from "react-select";
const AddProduct = () => {
  let companyId = localStorage.getItem("companyId");
  let itemBarcode = "";
  let Navigate = useNavigate();
  let [validationError, setvalidationError] = useState([]);
  let [getunits, setGetunits] = useState([]);

  // let [unitDes, setUnitDes] = useState();
  let [isLoading, setisLoading] = useState(false);
  let [disbaled, setDisbaled] = useState(false);
  async function getUnits(e) {
    let { data } = await baseUrl.get(
      `/json/getUnits`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    let filtered= [];
    data.data.map(el=> {
      if(!filtered.includes(el)){
      filtered.push(el)
    }})
    setGetunits(filtered);
  }
  let unitDesarray = [];
  getunits.forEach((item) => {
    unitDesarray.push(item.desc_en);
  });
  const element = [];
  unitDesarray.forEach((ele) => {
    const regex = /\((.*?)\)/g;
    const matches = ele.match(regex);
    if (matches) {
      matches.forEach((match) => {
        const desstring = match.slice(1, -1);
          element.push(desstring);
       
      });
    }
  });

  let unitsOptions = getunits.map(el=>({
    value: el.code,
    label:el.desc_en
  }))

  console.log(unitsOptions)

  //const filtered =  getunits.filter(el=> !filtered.includes(el))
  //setFilteredUnites(filtered)
  useEffect(() => {
    getUnits();
  }, []);
  let [product, setProduct] = useState({
    itemName: "",
    itemPrice: "",
    itemCode: "",
    itemBarcode: itemBarcode,
    unitType: "EA",
    description: "",
    companyId: companyId,
  });
  function getDataproduct(e) {
    let currentproduct = { ...product };
    currentproduct[e.target.name] = e.target.value;
    setProduct(currentproduct);
  }
  const  handleUniteChange=(e)=>{
    let currentproduct = {...product};
    currentproduct['unitType'] = e.value;
    setProduct(currentproduct)
  }
  function vaildation() {
    let schema = Joi.object({
      itemName: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك اكتب اسمك " }),
      itemPrice: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل سعر المنتج" }),
      itemCode: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل كود المنتج" }),
      unitType: Joi.string()
        .required()
        .messages({ "string.empty": " من فضلك اختر وحدة الصنف" }),
      description: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل الوصف بالانجليزية" }),
      companyId,
      itemBarcode,
    });
    let res = schema.validate(product, { abortEarly: false });
    if (res.error) {
      setvalidationError(res.error.details);
      return false;
    } else {
      return true;
    }
  }
  async function addNewproduct(e) {
    e.preventDefault();
    if (vaildation()) {
      setisLoading(true);
      setDisbaled(true);
      let { data } = await baseUrl
        .post(`/products/addProduct`, product, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })

        .then((res) => {
          if (res.data.message === "تم الاضافة") {
            setisLoading(false);
            setDisbaled(false);
            notify("تم اضافة المنتج بنجاح", "success");
            setTimeout(() => {
              Navigate("/product");
            }, 1000);
          }
        })
        .catch((error) => {
          if (error.response.data.errors) {
            setisLoading(false);
            setDisbaled(false);
            error.response.data.errors.map((error) => notify(error.msg, "error"));
          }   else if (error.response.status === 401) {   
            Navigate("/");    }
        });
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="d-flex">
          <PageTitle title="اضافة صنف" />
          <div className="me-2">
            {isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}
          </div>
        </div>
        <div className="card ">
          <div className="card-body m-3">
            <form onSubmit={addNewproduct}>
              <div className="row ">
                <div className="col-12">
                  <div className="row mb-3">
                    <label
                      htmlFor="ProductName"
                      className="col-sm-2 col-form-label">
                      اسم المنتج{" "}
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="ProductName"
                        placeholder="اسم المنتج"
                        name="itemName"
                        onChange={getDataproduct}
                      />
                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "itemName"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "itemName"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row mb-3">
                    <label
                      htmlFor="ProductPrice"
                      className="col-sm-2 col-form-label">
                      السعر{" "}
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="ProductPrice"
                        placeholder="السعر"
                        name="itemPrice"
                        onChange={getDataproduct}
                      />
                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "itemPrice"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "itemPrice"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row mb-3">
                    <label
                      htmlFor="ProductCoding"
                      className="col-sm-2 col-form-label">
                      تكويد الصنف{" "}
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="ProductCoding"
                        placeholder="التكويد على موقع الضرائب"
                        name="itemCode"
                        onChange={getDataproduct}
                      />
                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "itemCode"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "itemCode"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row mb-3">
                    <label
                      htmlFor="unitType"
                      className="col-sm-2 col-form-label">
                      وحدة الصنف
                    </label>

                    <div className="col-sm-10">
                      {/* <select
                        className="form-select"
                        onChange={getDataproduct}
                        name="unitType"
                        id="unitType">
                        <option>من فضلك ادخل وحدة الصنف</option>
                        {getunits.map((unit) => (
                          <React.Fragment key={unit.code}>
                            {element.map((item, index) => (
                              <option
                                key={`${unit.code}-${index}`}
                                value={unit.code}>
                                {item}
                              </option>
                            ))}
                          </React.Fragment>
                        ))}
                      </select> */}
                      <Select 
                        name="unitType"
                        onChange={handleUniteChange}
                        options={unitsOptions}
                        isSearchable
                      />

                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "unitType"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "unitType"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row mb-3">
                    <label
                      htmlFor="description"
                      className="col-sm-2 col-form-label">
                      وصف بالانجليزية
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="description"
                        name="description"
                        placeholder="وصف بالانجليزية "
                        onChange={getDataproduct}
                      />
                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "description"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "description"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {disbaled ? (
                <button
                  disabled={disbaled}
                  className="mt-2 btn-custom btn-custom-disable ">
                  <i className="fa fa-spinner fa-spin"></i>
                </button>
              ) : (
                <button className="mt-2 btn-custom">اضافة</button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
