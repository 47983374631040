import React from "react";
import EditClient from "../../Components/Clients/EditClient";

const EditClientPage = () => {
  return (
    <>
      <EditClient />
    </>
  );
};

export default EditClientPage;
