import { useState, useEffect } from 'react';
import notify from './../useNotifaction';
import { useNavigate } from 'react-router-dom';

import baseUrl from './../../Api/baseUrl';
const LoginHook = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  useEffect(() => {
    baseUrl
      .get('/auth/getActiveUsers')
      .then(res => {
        setUsers(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const [nameID, setNameID] = useState(0);
  const [password, setPassword] = useState('');
  const [companyOptions, setCompanyOptions] = useState('');
  const [companyID, setCompanyID] = useState(0);
  const [loading, setLoading] = useState(true);

  const [comapnyData, setCompanyData] = useState([]);
  const getCompany = async id => {
    await baseUrl.get(`/auth/getUserCompanies/${id}`).then(res => {
      setCompanyData(res.data.data);
    });
  };
  //when select name store id
  const onSelectName = async e => {
    if (e.target.value !== 0) {
      getCompany(e.target.value);
    }

    setNameID(e.target.value);
  };

  useEffect(() => {
    if (nameID !== 0) {
      if (comapnyData) {
        const options = comapnyData.map((c) => ({
          value: c._id,
          label: c.companyName,
        }));
        if (comapnyData) setCompanyOptions(options);
      }
    } else setCompanyOptions([]);
  }, [nameID, comapnyData]);

  const onChangePassword = e => {
    setPassword(e.target.value);
  };

  const onSelectCompany = e => {
    setCompanyID(e.value);
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (nameID === 0) {
      notify('  اختار اسم المستخدم ', 'error');
      return;
    }
    if (password === '') {
      notify('  ادخل الباسورد ', 'error');
      return;
    }
    if (companyID === 0) {
      notify('  اختار اسم الشركة ', 'error');
      return;
    }
    setLoading(true);
    await baseUrl
      .post(
        '/auth/login',
        {
          userId: nameID,
          password: password,
          companyId: companyID,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then(res => {
        if (res.data.token) {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('companyId', res.data.companyId);
          localStorage.setItem('user', JSON.stringify(res.data.data));
          notify('تم تسجيل الدخول بنجاح', 'success');
          setTimeout(() => {
            navigate('/dashboard');
          }, 1000);
        } else {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem('companyId');
        }
      })
      .catch(err => {
        console.log(err);
        if (err) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem('companyId');
        }

        if (
          err.response.data &&
          err.response.data.message === 'اسم المستخدم او كلمة السر خطأ'
        ) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem('companyId');
          notify('كلمة السر خطأ', 'error');
        }
      });
    setLoading(false);
  };

  return [
    password,
    companyOptions,
    onSelectName,
    onChangePassword,
    onSelectCompany,
    onSubmit,
    users,
  ];
};

export default LoginHook;
