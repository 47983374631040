import React, { useState, useEffect } from "react";
import axios from "axios";
import PageTitle from "./../../Components/Uitily/PageTitle";
import { useParams } from "react-router-dom";
import baseUrl from "./../../Api/baseUrl";
const SingleClientPage = () => {
  const [users, setUsers] = useState([]);
  const { id } = useParams();
  async function getDataClient() {
    let { data } = await baseUrl.get(`clients/getClient/${id}`,{
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    setUsers(data.data)
  }
  useEffect(()=>{
    getDataClient()
   },[]
   )
  
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <PageTitle title="تفاصيل عميل" />
          <div className="card mt-3 single-info">
            <div>
              <h6
                className="text-center "
                style={{
                  borderTopRightRadius: "0.75rem",
                  borderTopLeftRadius: "0.75rem",
                }}>
                بيانات العميل
              </h6>

              <div className="overflow-auto">
                <table className=" table table-bordered table-hover">
                  <tbody>
                    <tr>
                      <td style={{ width: "30%" }}>اسم العميل</td>
                      <td>{users.clientName} </td>
                    </tr>
                    <tr>
                      <td>رقم الكود</td>
                      <td>{users.clientCode}</td>
                    </tr>
                    <tr>
                      <td>الرقم الضريبى</td>
                      <td>{users.taxNumber}</td>
                    </tr>

                    <tr>
                      <td>الهاتف</td>
                      <td>{users.phone}</td>
                    </tr>

                    <tr>
                      <td>النوع</td>
                      <td>{users.type}</td>
                    </tr>
                    <tr>
                      <td>البلد</td>
                      <td>{users.country}</td>
                    </tr>

                    <tr>
                      <td> المحافظة</td>
                      <td> {users.governorate} </td>
                    </tr>

                    <tr>
                      <td>المنطقة</td>
                      <td>{users.region}</td>
                    </tr>
                    <tr>
                      <td>الشارع</td>
                      <td>{users.street}</td>
                    </tr>
                    <tr>
                      <td>رقم المبنى</td>
                      <td>{users.buildingNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleClientPage;
