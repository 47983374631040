import React, { useState } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import LoginPage from './Page/Auth/LoginPage';
import ContactUsPage from './Page/ContactUsPage';
import AdminMangeUsersPage from './Page/Admin/AdminMangeUsersPage';
import AdminMangeCompanyPage from './Page/Admin/AdminMangeCompanyPage';
import AdminAddCompanyPage from './Page/Admin/AdminAddCompanyPage';
import Navbar from './Components/Uitily/Navbar';

import Sidebar from './Components/Uitily/Sidebar';
import AdminEditCompanyPage from './Page/Admin/AdminEditCompanyPage';
import SearchCodingPage from './Page/ItemsCoding/SearchCodingPage';
import ManageClientPage from './Page/Clients/ManageClientPage';
import AddClientPage from './Page/Clients/AddClientPage';
import EditClientPage from './Page/Clients/EditClientPage';
import ManageProductPage from './Page/Product/ManageProductPage';
import EditProductPage from './Page/Product/EditProductPage';
import AddProductPage from './Page/Product/AddProductPage';
import AdminAddUserPage from './Page/Admin/AdminAddUserPage';
import AdminEditUserPage from './Page/Admin/AdminEditUserPage';
import SingleCompanyPage from './Page/Admin/SingleCompanyPage';
import Footer from './Components/Uitily/Footer';
import ProtectedRoute from './Components/Uitily/ProtectedRoute';
import SingleClientPage from './Page/Clients/SingleClientPage';
import SingleProductPage from './Page/Product/SingleProductPage';
import ExcelInvoicePage from './Page/ElectronicInvoice/ExcelInvoicePage';
import ErpInvoicePage from './Page/ElectronicInvoice/ErpInvoicePage';
import InvoiceReportsPage from './Page/ElectronicInvoice/InvoiceReportsPage';
import License from './Page/Clients/License';
import EdituserLicense from './Page/Clients/EdituserLicense';
import ManulCode from './Page/ItemsCoding/ManulCode';
import Invoicepage from './Page/HomePage/Invoicepage';
import Manulnvoicepage from './Page/ElectronicInvoice/ManulInvoices/Manulnvoicepage';
import InternalInvoices from './Page/ElectronicInvoice/InternalInvoices/InternalInvoices';
import EditInvoices from './Page/ElectronicInvoice/InternalInvoices/EditInvoices';
import AdvancedResearch from './Page/ElectronicInvoice/AdvancedResearch/AdvancedResearch';
import BackageInvoices from './Page/ElectronicInvoice/BackageInvoices/BackageInvoices';
import SearchProducts from './Page/ItemsCoding/SearchProducts';
import ManulReciptpage from './Page/ElectronicRecipt/ManulRecipt/ManulReciptpage';
import { ToastContainer } from 'react-toastify';
import ExcelReciptPage from './Page/ElectronicRecipt/ExcelReciptPage';
import ReciptReportsPage from './Page/ElectronicRecipt/ReciptReportsPage';
import ErpReciptPage from './Page/ElectronicRecipt/ErpReciptPage';
import AdvancedResearchRecipts from './Page/ElectronicRecipt/AdvancedResearch/AdvancedResearch';
import InternalRecipts from './Page/ElectronicRecipt/InternalRecipt/InternalRecipt';
import BackageRecipts from './Page/ElectronicRecipt/BackageInvoices/BackageRecipts';
import InvoiceDetails from './Page/ElectronicInvoice/InternalInvoices/InvoiceDetails';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { Toaster } from 'sonner'


function App() {
  const location = useLocation();
  const loggedIn = location.pathname !== '/' ? true : false;
  const [isMenuActive, setIsMenuActive] = useState(false);
  const handleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };
  const user = localStorage.getItem('user');
  const userAdmin = JSON.parse(localStorage.getItem('user'));
  const useAuth = () => {
    if (user !== null) {
      return true;
    } else {
      return false;
    }
  };
  const userAuthAdmin = () => {
    if (userAdmin !== null) {
      if (userAdmin.isAdmin) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <>
      <Toaster richColors position="top-right"/>
      <ToastContainer />
      {loggedIn && (
        <Navbar isMenuActive={isMenuActive} handleMenu={handleMenu} />
      )}
      {loggedIn && <Sidebar isMenuActive={isMenuActive} />}
      <Routes>
        {user !== null ? (
          <Route index element={<Navigate to='/dashboard' />} />
        ) : (
          <Route index element={<LoginPage />} />
        )}
        <Route element={<ProtectedRoute auth={userAuthAdmin()} />}>
          <Route path='/admin/mangeusers' element={<AdminMangeUsersPage />} />
          <Route path='/admin/adduser' element={<AdminAddUserPage />} />
          <Route path='/admin/edituser/:id' element={<AdminEditUserPage />} />
          <Route path='/admin/company' element={<AdminMangeCompanyPage />} />
          <Route path='/admin/company/:id' element={<SingleCompanyPage />} />
          <Route path='/admin/addcompany' element={<AdminAddCompanyPage />} />
          <Route
            path='/admin/editcompany/:id'
            element={<AdminEditCompanyPage />}
          />
        </Route>

        <Route element={<ProtectedRoute auth={useAuth()} />}>
          <Route path='/contactus' element={<ContactUsPage />} />
          <Route path='/clients' element={<ManageClientPage />} />
          <Route path='/clients/:id' element={<SingleClientPage />} />
          <Route path='/addclient' element={<AddClientPage />} />
          <Route path='/editclient/:id' element={<EditClientPage />} />
          <Route path='/Product' element={<ManageProductPage />} />
          <Route path='/Product/:id' element={<SingleProductPage />} />
          <Route path='/addProduct' element={<AddProductPage />} />
          <Route path='/editProduct/:id' element={<EditProductPage />} />
          <Route path='/searchcoding' element={<SearchCodingPage />} />
          <Route path='/searchproducts' element={<SearchProducts />} />
          <Route path='/dashboard' element={<Invoicepage />} />
          <Route path='/manulinvoicepage' element={<Manulnvoicepage />} />
          <Route path='/internalinvoices' element={<InternalInvoices />} />

          <Route path='/editinvoices' element={<EditInvoices />} />
          <Route path='/editinvoices/:id' element={<EditInvoices />} />
          <Route path='/license' element={<License />} />
          <Route path='/editlicense/:id' element={<EdituserLicense />} />
          <Route path='/manulcode' element={<ManulCode />} />
          <Route path='/advancedresearch' element={<AdvancedResearch />} />
          <Route path='/packagedata' element={<BackageInvoices />} />

          <Route path='/excelinvoice' element={<ExcelInvoicePage />} />
          <Route path='/erpinvoice' element={<ErpInvoicePage />} />
          <Route path='/invoicereports' element={<InvoiceReportsPage />} />

          <Route path='/license' element={<License />} />
          <Route path='/editlicense/:id' element={<EdituserLicense />} />
          <Route path='/manulcode' element={<ManulCode />} />

          <Route path='/manulrecipt' element={<ManulReciptpage />} />
          <Route path='/excelrecipt' element={<ExcelReciptPage />} />
          <Route path='/erprecipt' element={<ErpReciptPage />} />
          <Route path='/reciptreports' element={<ReciptReportsPage />} />
          <Route
            path='/advancedsesearchrecipts'
            element={<AdvancedResearchRecipts />}
          />
          <Route path='/internalrecipts' element={<InternalRecipts />} />
          <Route path='/backagerecipts' element={<BackageRecipts />} />
          <Route path='/invoicedetails/:id' element={<InvoiceDetails />} />
          <Route path='/editInvoice/:id' element={<EditInvoices />} />
        </Route>
      </Routes>

      {loggedIn && <Footer />}
    </>
  );
}

export default App;
