import React, { useState, useEffect } from 'react';

import axios from 'axios';
import notify from './../../hook/useNotifaction';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { AiOutlineEdit } from 'react-icons/ai';
import { GoArrowDown } from 'react-icons/go';
import { Navigate } from 'react-router-dom';
import { AiOutlineDelete } from 'react-icons/ai';
import { TbFileImport } from 'react-icons/tb';
import { TbFileExport } from 'react-icons/tb';

import PageTitle from './../Uitily/PageTitle';
import '../Uitily/Pagination.css';
import { saveAs } from 'file-saver';
import baseUrl from './../../Api/baseUrl';
import useDebounce from '../../hook/useDebounce';
const MangeAllClients = () => {
  let companyId = localStorage.getItem('companyId');
  const [runUseEffect, setRun] = useState(0);
  let [clients, setClients] = useState([]);
  let [totalResults, setTotalResults] = useState(0);
  const [PageCount, setpageCount] = useState();
  let pageList = new Array(PageCount).fill().map((ele, i) => i + 1);
  let [isLoading, setisLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(1);

  async function getAllclients(currentpage) {
    const limit = 20;
    setisLoading(true);
    let { data } = await baseUrl.post(
      `/clients/getClients?page=${currentpage}&limit=${limit}`,
      { companyId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    );
    setisLoading(false);
    setClients(data.data);
    console.log(data);
    setpageCount(Math.ceil(data.results / limit));
  }

  const [showDelete, setShowDelete] = useState(false);
  const [id, setId] = useState('');
  const handleDeletClose = () => setShowDelete(false);
  const handleDeleteShow = itemId => {
    setShowDelete(true);
    setId(itemId);
  };

  const handleExport = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    await axios
      .post('/clients/exportClientsToExcel', { companyId }, config)
      .then(res => {
        const url = res.data.data.fileURL;
        saveAs(url);
        if (res.data.status === 'Success') {
          notify(res.data.message, 'success');
        }
      })
      .catch(err => {
        if (err) {
          notify('يوجد مشكلة فى تصدير العملاء', 'error');
        }
      });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [selectedFile, setSelectedFile] = useState('');

  const onFileChange = e => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };
  const handleImport = e => {
    e.preventDefault();
    if (selectedFile === '') {
      notify('اضف الملف ', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('clientsExcel', selectedFile);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    axios
      .post('/clients/importClientsFromExcel', formData, config)
      .then(res => {
        if (res.data.status === 'Success') {
          notify(res.data.message, 'success');
        }
        setShow(false);
        setRun(prev => prev + 1);
      })
      .catch(err => {
        notify('يوجد مشكلة فى العملاء ', 'error');
      });
  };
  const handelDelete = async () => {
    try {
      const res = await baseUrl.delete(`/clients/deleteClient/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (res.status === 200) {
        notify('تم حذف العميل بنجاح', 'success');
        setShowDelete(false);
        setRun(prev => prev + 1);
      }
    } catch (error) {
      notify('هناك مشكله فى عملية الحذف', 'error');
      if (error.response.status === 401) {
        Navigate('/');
      }
    }
  };
  const [searchData, setSearchData] = useState('');
  const debouncedValue = useDebounce(searchData, 500)

async function importClients () {
  try {
      setisLoading(true);
      const { data } = await baseUrl.post(
        `/invoices/importClientsFromTax`,
        { companyId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      if (data.status === 'Success') {
        notify(data.message, 'success');
        getAllclients(1)
      }
  } catch (error) {
    if (error.response.status === 401) {
      Navigate('/');
    }
    notify('يوجد مشكلة فى تحميل العملاء', 'error');
  }
  finally {
    setisLoading(false);
  }
}

  async function searchClient(pageNumber = 1) {
    const { data } = await baseUrl.post(
      `/clients/searchClient??page=${pageNumber}&limit=3`,
      {
        companyId,
        clientName: debouncedValue,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    );
    if (data.status == 'Success') {
      setisLoading(false);
      setClients(data.data);
      setTotalResults(data.total);
      setpageCount(Math.ceil(data.results / 3));
    } else if (data.status === 'fail') {
      setClients([]);
      setisLoading(false);
      setpageCount(0);
    }
  }

  function handleKeyPress(e) {
    const { value } = e.target;
    setSearchData(value);

  }

  function onPaginate(newPageNumber) {
    setPage(newPageNumber);

    if (debouncedValue) {
      searchClient(newPageNumber);
    } else {
      getAllclients(newPageNumber);
    }
    setActivePage(newPageNumber);
  }

  const nextPage = () => {
    const newPageNumber = page + 1;
    if (newPageNumber <= PageCount) {
      setPage(newPageNumber);
      onPaginate(newPageNumber);
    }
  };
  const prevPage = () => {
    const newPageNumber = page - 1;
    if (newPageNumber >= 1) {
      setPage(newPageNumber);
      onPaginate(newPageNumber);
    }
  };
  useEffect(() => {
    if (debouncedValue) {
      searchClient(page);
    } else {
      getAllclients(page);
    }
  }, [runUseEffect, page, debouncedValue]);

  return (
    <div className='page-content'>
      {/* Modal For Delete User */}
      <Modal show={showDelete} onHide={handleDeletClose}>
        <Modal.Header>
          <Modal.Title>
            {' '}
            <div>تاكيد الحذف</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>هل انتا متاكد من حذف المستخدم </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' onClick={handleDeletClose}>
            تراجع
          </Button>
          <Button variant='dark' onClick={handelDelete}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {' '}
            <div> استيراد من اكسل</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              accept='.xlsx'
              type='file'
              name='file'
              onChange={onFileChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' onClick={handleClose}>
            تراجع
          </Button>
          <Button variant='dark' onClick={handleImport}>
            حفظ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='container-fluid'>
        <div className='d-flex'>
          <PageTitle title='العملاء' />
          <div className='me-2'>
            {isLoading ? <i className='fa fa-spinner fa-spin'></i> : ''}
          </div>
        </div>
        <div className='row pb-4'>
          <div className='col-sm-6 mb-3 mb-sm-0'>
            <Link to='/addclient' className='btn main-btn'>
              اضافة عميل
            </Link>
          </div>
          <div className='col-sm-6'>
            <div className='search-box'>
              <BsSearch className='search-icon' />
              <input
                onChange={handleKeyPress}
                type='text'
                className='form-control'
                id='searchMemberList'
                placeholder='ابحث عن النتائج'
              />
            </div>
          </div>
        </div>

        <div className='card  mt-4'>
          <div className='card-body'>
            <table className='table table-hover table-bordered'>
              <thead>
                <tr>
                  <th scope='col'> العميل</th>
                  <th scope='col'>الرقم الضربيى</th>
                  <th scope='col'>تعديل</th>
                  <th scope='col'>حذف</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td className='d-flex'>
                      <i className='fa fa-spinner fa-spin spinner-table'></i>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {clients.map(client => (
                    <tr key={client._id}>
                      <td>
                        <Link to={`/clients/${client._id}`} className='name'>
                          {client.clientName}
                        </Link>
                      </td>
                      <td>{client.taxNumber}</td>

                      <td>
                        <Link
                          to={`/editclient/${client._id}`}
                          className='table-icon edit'
                        >
                          <AiOutlineEdit />
                        </Link>
                      </td>
                      <td>
                        <span className=' table-icon delete'>
                          <AiOutlineDelete
                            onClick={() => {
                              handleDeleteShow(client._id);
                            }}
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            <nav aria-label='...'>
              <ul className='pagination'>
                <li className='page-item '>
                  <a className='page-link' onClick={prevPage}>
                    السابق
                  </a>
                </li>
                {pageList.map(ele => (
                  <li
                    className={`page-item ${
                      ele === activePage ? 'active-page' : ''
                    }`}
                    onClick={() => onPaginate(ele)}
                    key={ele}
                  >
                    <span className='page-link '>{ele}</span>
                  </li>
                ))}

                <li className='page-item'>
                  <a className='page-link' onClick={nextPage}>
                    التالي
                  </a>
                </li>
              </ul>
            </nav>
            <div className='d-flex justify-content-between align-items-center flex-wrap'>
              <p>العدد : {clients.length}</p>
              <div className='d-flex'>
                <div className='file-icon' onClick={importClients}>
                    <GoArrowDown />
                </div>
                <button
                  className='file-icon'
                  title='تصدير'
                  onClick={handleExport}
                >
                  <TbFileExport />
                </button>
                <button
                  className='file-icon'
                  title='استيراد'
                  onClick={handleShow}
                >
                  <TbFileImport />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MangeAllClients;
