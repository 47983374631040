import React, { useState, useEffect } from "react";

import PageTitle from "./../Uitily/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import notify from "./../../hook/useNotifaction";
import baseUrl from "./../../Api/baseUrl";
import Joi from "joi";
const EditAdminUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  let [isLoading, setisLoading] = useState(false);
  let [disbaled, setDisbaled] = useState(false);
  useEffect(() => {
    setDisbaled(true);
    setisLoading(true);
    baseUrl
      .get(`/users/getUser/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setDisbaled(false);
        setisLoading(false);
        setName(res.data.data.username);
        setPassword(res.data.data.password);
        setIsActive(res.data.data.isActive);
        setIsAdmin(res.data.data.isAdmin);
      });
  }, [id]);

  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onChangeIsActive = () => {
    setIsActive(!isActive);
  };
  const onChangeIsAdmin = () => {
    setIsAdmin(!isAdmin);
  };
  let [validationError, setvalidationError] = useState([]);
  function vaildation() {
    let schema = Joi.object({
      name: Joi.string().min(4).required().messages({
        "string.empty": " من فضلك اكتب اسم المستخدم",
        "string.min": " من فضلك اكتب اسم مستخدم لا يقل عن 4 حروف",
      }),
      password: Joi.string().min(5).required().messages({
        "string.empty": " من فضلك ادخل كلمة المرور ",
        "string.min": " من فضلك ادخل كلمة المرور لا تقل عن 5 ارقام او حروف",
      }),
    });
    let res = schema.validate({ name, password }, { abortEarly: false });

    if (res.error) {
      setvalidationError(res.error.details);
      return false;
    } else {
      return true;
    }
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    if (vaildation()) {
      setDisbaled(true);
      setisLoading(true);

      await baseUrl
        .put(
          `/users/updateUser/${id}`,
          {
            username: name,
            password,
            isActive,
            isAdmin,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setDisbaled(false);
            setisLoading(false);
            setvalidationError([]);
            notify("تم تعديل المستخدم بنجاح", "success");
            setTimeout(() => {
              navigate("/admin/mangeusers");
            }, 1000);
          }
        })
        .catch((err) => {
          if (err) {
            setDisbaled(false);
            setisLoading(false);
          }
          if (err.response.data.errors) {
            err.response.data.errors.map((error) => notify(error.msg, "error"));
          }
          if (err.response.data.statusCode === "401") {
            localStorage.clear();
            navigate("/");
          }
        });
    }
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="d-flex">
          <PageTitle title="تعديل مستخدم" />
          <div className="me-2">
            {isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}
          </div>
        </div>
        <div className="card ">
          <div className="card-body m-3">
            <form>
              <div className="row mb-3">
                <label
                  htmlFor="name"
                  className="col-sm-3 col-lg-2  col-form-label">
                  اسم المستخدم
                </label>
                <div className="col-sm-9 col-lg-10">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="اسم المستخدم"
                    value={name}
                    onChange={onChangeName}
                  />
                  <div
                    className={
                      validationError.filter(
                        (ele) => ele.context.label === "name"
                      )[0]
                        ? "alert mt-2 alert-danger"
                        : ""
                    }>
                    {
                      validationError.filter(
                        (ele) => ele.context.label === "name"
                      )[0]?.message
                    }
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="password"
                  className="col-sm-3 col-lg-2 col-form-label">
                  كلمة المرور
                </label>
                <div className="col-sm-9 col-lg-10">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="كلمة المرور"
                    value={password}
                    onChange={onChangePassword}
                  />
                  <div
                    className={
                      validationError.filter(
                        (ele) => ele.context.label === "password"
                      )[0]
                        ? "alert mt-2 alert-danger"
                        : ""
                    }>
                    {
                      validationError.filter(
                        (ele) => ele.context.label === "password"
                      )[0]?.message
                    }
                  </div>
                </div>
              </div>

              <div className="row mb-3 align-items-center">
                <label htmlFor="" className="col-3 col-lg-2  col-form-label">
                  الحالة
                </label>
                <div className="col-9 col-lg-10 ">
                  <div className="d-flex  ">
                    <div className="form-check form-switch d-flex ms-3">
                      <label htmlFor="active" className="form-label">
                        نشط
                      </label>
                      <input
                        className="form-check-input me-3"
                        id="active"
                        type="checkbox"
                        checked={isActive}
                        onChange={onChangeIsActive}
                      />
                    </div>
                    <div className="form-check form-switch d-flex">
                      <label className="form-label" htmlFor="isadmin">
                        مدير
                      </label>
                      <input
                        className="form-check-input me-3"
                        id="isadmin"
                        type="checkbox"
                        checked={isAdmin}
                        onChange={onChangeIsAdmin}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {disbaled ? (
                <button
                  disabled={disbaled}
                  className="mt-2 btn-custom btn-custom-disable ">
                  <i className="fa fa-spinner fa-spin"></i>
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={onSubmit}
                  className="mt-2 btn-custom">
                  اضافة
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAdminUser;
