import React from 'react';
import { AiOutlineEdit } from "react-icons/ai";
import { Link } from 'react-router-dom';
import PageTitle from "../../Components/Uitily/PageTitle";
export default function License() {
  return (
    <>
          <div className="page-content">
        <div className="container-fluid">
          <PageTitle title="ترخيص العملاء" />


          <div className="card  mt-4">
            <div className="card-body">
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col">رقم العميل</th>
                    <th scope="col">اسم العميل</th>
                    <th scope="col">نوع الترخيص</th>
                    <th scope="col">تاريخ بداية التفعيل</th>
                    <th scope="col">تاريخ نهاية التفعيل</th>
                    <th scope="col">تعديل</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <th scope="col">رقم العميل</th>
                    <th scope="col">اسم العميل</th>
                    <th scope="col">نوع الترخيص</th>
                    <th scope="col">تاريخ بداية التفعيل</th>
                    <th scope="col">تاريخ نهاية التفعيل</th>
                    <th scope="col">
                     <Link to='/editlicense/:id'>
                      <AiOutlineEdit />
                    </Link></th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
