import React, { useState, useEffect } from "react";
import axios from "axios";
import Joi from "joi";
import { useNavigate } from "react-router-dom";
import PageTitle from "./../Uitily/PageTitle";
import notify from "./../../hook/useNotifaction";
import baseUrl from "./../../Api/baseUrl";
const AddClient = () => {
  let Navigate = useNavigate();
  let [validationError, setvalidationError] = useState([]);
  let [selectors, setSelectors] = useState([]);
  let [isLoading, setisLoading] = useState(false);
  let [disbaled, setDisbaled] = useState(false);
  let [labelType, setLabelType] = useState("الرقم الضريبي");
  let companyId = localStorage.getItem("companyId");
  let [getcountries, setGetcountries] = useState([]);
  async function getCountries(e) {
    let { data } = await baseUrl.get(
      `/json/getCountries`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    setGetcountries(data.data);
  }
  useEffect(() => {
    getCountries();
  }, []);
  const countryData = Object.values(getcountries).reduce(
    (acc, curr) => acc.concat(curr),
    []
  );
  let [user, setUser] = useState({
    clientName: "",
    taxNumber: "",
    phone: "",
    type: "",
    country: "",
    governate: "",
    regionCity: "",
    buildingNumber: "",
    street: "",
    companyId: companyId,
  });
  function getDataClient(e) {
    let currentUser = { ...user };
    currentUser[e.target.name] = e.target.value;
    setUser(currentUser);
  }
  useEffect(() => {
    if (user.type === "P") {
      setLabelType("الرقم القومي");
    } else if (user.type === "B") {
      setLabelType("الرقم الضريبي");
    } else if (user.type === "F") {
      setLabelType("رقم التعريفي");
    }
  }, [user.type]);
  function vaildation() {
    let schema = Joi.object({
      clientName: Joi.string()
        .required()
        .messages({ "string.empty": " من فضلك اكتب اسمك العميل" }),
      taxNumber: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل الرقم الضريبي" }),
      phone: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل هاتف" }),
      type: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل النوع" }),
      country: Joi.string()
        .required()
        .messages({ "string.empty": " من فضلك اختر البلد" }),
      governate: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل الوصف المحافظة" }),
      regionCity: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل المنطقة " }),
      buildingNumber: Joi.string()
        .required()
        .messages({ "string.empty": "من فضلك ادخل رقم المبني " }),
      street: Joi.string()
        .required()
        .messages({ "string.empty": " من فضلك اختر اسم الشارع" }),
      companyId,
    });
    let res = schema.validate(user, { abortEarly: false });
    console.log(res);
    if (res.error) {
      setvalidationError(res.error.details);
      return false;
    } else {
      return true;
    }
  }
  async function addNewclient(e) {
    e.preventDefault();
    if (vaildation()) {
      setisLoading(true);
      setDisbaled(true);
      console.log(user);
      let res = await baseUrl.post(`/clients/addClient`, user, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          if (res.data.message === "تم الاضافة") {
            setisLoading(false);
            setDisbaled(false);
            notify("تم اضافة المستخدم بنجاح", "success");
            setTimeout(() => {
              Navigate("/clients");
            }, 1000);
          }
        })
        .catch((error) => {
          if (error.response.data.errors) {
            setisLoading(false);
            setDisbaled(false);
            error.response.data.errors.map((error) => notify(error.msg, "error"));

          }
          else if (error.response.status === 401) {   
            Navigate("/");    }
        });
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="d-flex">
          <PageTitle title="اضافة عميل" />
          <div className="me-2">
            {isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}
          </div>
        </div>
        <div className="card ">
          <div className="card-body m-3">
            <form onSubmit={(e) => addNewclient(e)}>
              <div className="row ">
                <div className="col-xl-6 col-lg-6">
                  <div className="row mb-3">
                    <label
                      htmlFor="taxNumber"
                      className="col-sm-2 col-form-label">
                      {labelType}
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="taxNumber"
                        placeholder={labelType}
                        name="taxNumber"
                        onChange={getDataClient}
                      />
                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "taxNumber"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "taxNumber"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row mb-3">
                    <label
                      htmlFor="clientName"
                      className="col-sm-2  col-xl-2  col-form-label">
                      الاسم
                    </label>
                    <div className="col-sm-10  col-xl-10">
                      <input
                        type="text"
                        className="form-control"
                        id="clientName"
                        placeholder="ادخل اسم العميل"
                        name="clientName"
                        onChange={getDataClient}
                      />
                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "clientName"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "clientName"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row mb-3">
                    <label
                      htmlFor="phoneNumber"
                      className="col-sm-2 col-xl-2  col-form-label">
                      هاتف
                    </label>
                    <div className="col-sm-10 col-xl-10">
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        placeholder="ادخل الهاتف "
                        name="phone"
                        onChange={getDataClient}
                      />
                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "phone"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "phone"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row mb-3">
                    <label
                      htmlFor="type"
                      className="col-sm-2 col-xl-2 col-form-label">
                      النوع
                    </label>
                    <div className="col-sm-10 col-xl-10">
                      <select
                        onChange={getDataClient}
                        name="type"
                        className="form-select"
                        id="type">
                        <option defaultValue="0">اختر النوع</option>
                        <option value="P">Person</option>
                        <option value="B">Business</option>
                        <option value="F">Foreigner</option>
                      </select>
                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "type"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "type"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-12">
                  <div className="row mb-3">
                    <label
                      htmlFor="country"
                      className="col-sm-2 col-form-label">
                      البلد
                    </label>
                    <div className="col-sm-10">
                      <select
                        className="form-select"
                        name="country"
                        onChange={getDataClient}
                        id="country">
                        <option defaultValue="0">اختر البلد</option>
                        {countryData?.map((country) => (
                          <option key={country.code} value={country.code}>
                            {country.Desc_ar}
                          </option>
                        ))}
                      </select>

                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "country"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "country"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12">
                  <div className="row mb-3">
                    <label
                      htmlFor="governate"
                      className="col-sm-2 col-form-label">
                      المحافظة
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="governate"
                        placeholder="cairo"
                        name="governate"
                        onChange={getDataClient}
                      />
                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "governate"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "governate"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12">
                  <div className="row mb-3">
                    <label
                      htmlFor="regionCity"
                      className="col-sm-2 col-form-label">
                      المنطقة
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="regionCity"
                        placeholder="المنطقة"
                        name="regionCity"
                        onChange={getDataClient}
                      />
                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "regionCity"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "regionCity"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12">
                  <div className="row mb-3">
                    <label
                      htmlFor="buildNumber"
                      className="col-sm-2 col-form-label">
                      ر.المبنى
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="buildNumber"
                        placeholder="رقم المبنى"
                        name="buildingNumber"
                        onChange={getDataClient}
                      />
                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "buildingNumber"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "buildingNumber"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row mb-3">
                    <label
                      htmlFor="streetName"
                      className="col-sm-2 col-xl-2  col-form-label">
                      الشارع
                    </label>
                    <div className="col-sm-10 col-xl-10">
                      <input
                        type="text"
                        className="form-control"
                        id="streetName"
                        placeholder="اسم الشارع"
                        name="street"
                        onChange={getDataClient}
                      />
                      <div
                        className={
                          validationError.filter(
                            (ele) => ele.context.label == "street"
                          )[0]
                            ? "alert mt-2 alert-warning"
                            : ""
                        }>
                        {
                          validationError.filter(
                            (ele) => ele.context.label == "street"
                          )[0]?.message
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {disbaled ? (
                <button
                  disabled={disbaled}
                  className="mt-2 btn-custom btn-custom-disable ">
                  <i className="fa fa-spinner fa-spin"></i>
                </button>
              ) : (
                <button className="mt-2 btn-custom">اضافة</button>
              )}{" "}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClient;
