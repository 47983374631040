import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import noImg from '../../images/noimg.jpg';

import { useNavigate, useParams } from 'react-router-dom';
import baseUrl from './../../Api/baseUrl';
import notify from './../../hook/useNotifaction';
import PageTitle from './../Uitily/PageTitle';
import Multiselect from 'multiselect-react-dropdown';
import Joi from 'joi';
import env from "react-dotenv";
import axios from 'axios';
const AdminEditCompany = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  let [isLoading, setisLoading] = useState(false);
  let [disbaled, setDisbaled] = useState(false);
  //convert url to file
  const convertURLtoFile = async url => {
    const response = await fetch(url, { mode: 'cors' });
    const data = await response.blob();
    const ext = url.split('.').pop();

    const metadata = { type: `image/${ext}` };
    return new File([data], Math.random(), metadata);
  };
  useEffect(() => {
    setisLoading(true);
    setDisbaled(true);
    baseUrl
      .get(`/companies/getCompany/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then(res => {
        setisLoading(false);
        setDisbaled(false);
        setCompanyData(res.data.data);
        setCompanyName(res.data.data.companyName);
        setTaxRegistration(res.data.data.taxNumber);
        setPhoneNumber(res.data.data.phone);
        setFax(res.data.data.faxNumber);
        setCompanyEmail(res.data.data.email);
        setIsActive(res.data.data.isActive);
        setSelectedValues(res.data.data.users);
        setSeletedUserID(res.data.data.users);
        res.data.data.companyImage !== ''
          ? setImg(`${env.API_URL}/${res.data.data.companyImage}`)
          : setImg(noImg);
        res.data.data.companyImage !== ''
          ? setTimeout(() => {
            convertURLtoFile(
              `${env.API_URL}/${res.data.data.companyImage}`
            ).then(val => setSelectedFile(val));
          }, 1000)
          : setSelectedFile('');

        setActivityCode(res.data.data.activityCode);
        if (res.data.data.companyEnvironment === 'prod') {
          setClientId(res.data.data.clientIdProd);
          setClientSecret(res.data.data.clientSecretProd);
          setPosSerial(res.data.data.posSerialProd);
          setPosOsVersion(res.data.data.posOsVersionProd);
          setPosClientId(res.data.data.posClientIdProd);
          setPosClientSecret(res.data.data.posClientSecretProd);
        } else {
          setClientId(res.data.data.clientIdPreProd);
          setClientSecret(res.data.data.clientSecretPreProd);
          setPosSerial(res.data.data.posSerialPreProd);
          setPosOsVersion(res.data.data.posOsVersionPreProd);
          setPosClientId(res.data.data.posClientIdPreProd);
          setPosClientSecret(res.data.data.posClientSecretPreProd);
        }
        setTokenPin(res.data.data.tokenPin);
        setCompanyType(res.data.data.companyType);
        setCountry(res.data.data.address.country);
        setBrancheId(res.data.data.address.brancheId);
        setBuildNumber(res.data.data.address.buildingNumber);
        setGovernorate(res.data.data.address.governorate);
        setRegion(res.data.data.address.regionCity);
        setStreet(res.data.data.address.street);
        setCompanyEnvironment(res.data.data.companyEnvironment);
        res.data.data.companyEnvironment === 'prod'
          ? setCompanyEnvironmentMode(true)
          : setCompanyEnvironmentMode(false);
      });
  }, [id]);

  let [countries, setcountries] = useState([]);
  async function getCountries(e) {
    let { data } = await baseUrl.get(
      `/json/getCountries`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    );
    setcountries(data.data);
  }
  useEffect(() => {
    getCountries();
  }, []);
  const countryData = Object.values(countries).reduce(
    (acc, curr) => acc.concat(curr),
    []
  );
  let [validationError, setvalidationError] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [brancheId, setBrancheId] = useState('');
  const [taxRegistration, setTaxRegistration] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fax, setFax] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [activityCode, setActivityCode] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [tokenPin, setTokenPin] = useState('');
  const [posSerial, setPosSerial] = useState('');
  const [posOsVersion, setPosOsVersion] = useState('');
  const [posClientId, setPosClientId] = useState('');
  const [posClientSecret, setPosClientSecret] = useState('');
  const [seletedUserID, setSeletedUserID] = useState([]);
  const [country, setCountry] = useState('');
  const [governorate, setGovernorate] = useState('');
  const [region, setRegion] = useState('');
  const [buildNumber, setBuildNumber] = useState('');
  const [street, setStreet] = useState('');
  const [companyEnvironmentMode, setCompanyEnvironmentMode] = useState(true);
  const [companyEnvironment, setCompanyEnvironment] = useState('prod');

  const onChangeCompanyEnvironmentMode = e => {
    setCompanyEnvironmentMode(e.target?.checked);

    if (e.target?.checked === true) {
      setCompanyEnvironment('prod');
      setClientId(companyData.clientIdProd || '');
      setClientSecret(companyData.clientSecretProd || '');
      setPosSerial(companyData.posSerialProd || '');
      setPosOsVersion(companyData.posOsVersionProd || '');
      setPosClientId(companyData.posClientIdProd || '');
      setPosClientSecret(companyData.posClientSecretProd || '');
    } else {
      setCompanyEnvironment('preprod');
      setClientId(companyData.clientIdPreProd || '');
      setClientSecret(companyData.clientSecretPreProd || '');
      setPosSerial(companyData.posSerialPreProd || '');
      setPosOsVersion(companyData.posOsVersionPreProd || '');
      setPosClientId(companyData.posClientIdPreProd || '');
      setPosClientSecret(companyData.posClientSecretPreProd || '');
    }
  };
  const onSelect = selectedList => {
    setSeletedUserID(selectedList);
  };
  const onRemove = selectedList => {
    setSeletedUserID(selectedList);
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    baseUrl
      .get('/auth/getActiveUsers', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then(res => {
        setOptions(res.data.data);
      });
  }, []);
  const onChangeCompanyName = e => {
    e.persist();
    setCompanyName(e.target.value);
  };
  const onSelectCompanyType = e => {
    setCompanyType(e.target.value);
  };
  const onSelectCountry = e => {
    setCountry(e.target.value);
  };
  const onChangeGovernorate = e => {
    setGovernorate(e.target.value);
  };
  const onChangeTaxRegistration = e => {
    e.persist();
    setTaxRegistration(e.target.value);
  };
  const onChangePhoneNumber = e => {
    e.persist();
    setPhoneNumber(e.target.value);
  };
  const onChangeCompanyEmail = e => {
    e.persist();
    setCompanyEmail(e.target.value);
  };
  const onChangeFax = e => {
    e.persist();
    setFax(e.target.value);
  };

  const onChangeIsActive = () => {
    setIsActive(!isActive);
  };

  const onChangeRegion = e => {
    e.persist();
    setRegion(e.target.value);
  };
  const onChangeActivityCode = e => {
    e.persist();
    setActivityCode(e.target.value);
  };
  const onChangeClientId = e => {
    e.persist();
    setClientId(e.target.value);
  };
  const onChangeClientSecret = e => {
    e.persist();
    setClientSecret(e.target.value);
  };
  const onChangeTokenPin = e => {
    e.persist();
    setTokenPin(e.target.value);
  };

  // e-receipt data fields
  const onChangePosSerial = e => {
    e.persist();
    setPosSerial(e.target.value);
  };
  const onChangePosOsVersion = e => {
    e.persist();
    setPosOsVersion(e.target.value);
  };
  const onChangePosClientId = e => {
    e.persist();
    setPosClientId(e.target.value);
  };
  const onChangePosClientSecret = e => {
    e.persist();
    setPosClientSecret(e.target.value);
  };

  const onChangeBuildNumber = e => {
    e.persist();
    setBuildNumber(e.target.value);
  };

  const onChangeStreet = e => {
    e.persist();
    setStreet(e.target.value);
  };
  const onChangeBrancheId = e => {
    e.persist();
    setBrancheId(e.target.value);
  };
  const [img, setImg] = useState(noImg);
  const [selectedFile, setSelectedFile] = useState('');

  const onImageChange = e => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));

      setSelectedFile(e.target.files[0]);
    }
  };
  const removeImage = () => {
    setImg(noImg);
    setSelectedFile('');
  };
  function vaildation() {
    let schema = Joi.object({
      companyName: Joi.string().required().min(4).messages({
        'string.empty': 'من فضلك اكتب اسم الشركة ',
        'string.min': '   اسم الشركة يجب ان يزيد عن 4 أحرف',
      }),
      companyType: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك ادخل نوع الشركة ' }),
      taxRegistration: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك ادخل التسجيل الضريبى' }),
      brancheId: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك اكتب رقم الفرع ' }),
      country: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك اختر البلد' }),
      governorate: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك ادخل الوصف المحافظة' }),
      region: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك ادخل المنطقة ' }),
      buildNumber: Joi.string()
        .required()
        .messages({ 'string.empty': 'من فضلك ادخل رقم المبني ' }),
      street: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك اختر اسم الشارع' }),
      phoneNumber: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك ادخل  الهاتف' }),

      tokenPin: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك ادخل  tokenPin' }),
      clientId: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك ادخل  clientId' }),
      clientSecret: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك ادخل  clientSecret' }),
      posSerial: Joi.string()
        .required()
        .messages({ 'string.empty': 'posSerial من فضلك ادخل' }),
      posOsVersion: Joi.string()
        .required()
        .messages({ 'string.empty': 'posOsVersion من فضلك ادخل' }),
      posClientId: Joi.string()
        .required()
        .messages({ 'string.empty': 'posClientId من فضلك ادخل' }),
      posClientSecret: Joi.string()
        .required()
        .messages({ 'string.empty': 'posClientSecret من فضلك ادخل' }),
      companyEmail: Joi.string()
        .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
        .messages({
          'string.empty': ' من فضلك ادخل  الايميل',
          'string.email': ' من فضلك ادخل  الايميل بطريقة صحيحة',
        }),
      seletedUserID: Joi.array()
        .min(1)
        .required()
        .messages({ 'array.min': ' من فضلك اختر  المستخدمين' }),
      activityCode: Joi.string()
        .required()
        .messages({ 'string.empty': ' من فضلك ادخل  كود النشاط' }),
    });
    let res = schema.validate(
      {
        companyName,
        companyType,
        taxRegistration,
        brancheId,
        country,
        governorate,
        region,
        buildNumber,
        street,
        phoneNumber,
        tokenPin,
        clientId,
        clientSecret,
        companyEmail,
        seletedUserID,
        activityCode,
        posSerial,
        posOsVersion,
        posClientId,
        posClientSecret,
      },
      { abortEarly: false }
    );
    console.log(res);
    if (res.error) {
      setvalidationError(res.error.details);
      return false;
    } else {
      return true;
    }
  }
  const handelSubmit = e => {
    e.preventDefault();
    if (
      companyName === '' ||
      companyType === '' ||
      taxRegistration === '' ||
      brancheId === '' ||
      country === '' ||
      governorate === '' ||
      region === '' ||
      street === '' ||
      buildNumber === '' ||
      phoneNumber === '' ||
      companyEmail === '' ||
      isActive === '' ||
      clientId === '' ||
      clientSecret === '' ||
      tokenPin === '' ||
      posClientId === '' ||
      posClientSecret === '' ||
      posOsVersion === '' ||
      posSerial === '' ||
      seletedUserID.length < 1
    ) {
      notify('يوجد بيانات ناقصة', 'error');
    }
    if (vaildation()) {
      setisLoading(true);
      setDisbaled(true);

      console.log('options', options);

      const bodyData = {
        companyName,
        companyType,
        taxNumber: taxRegistration,
        brancheId,
        country,
        governorate,
        regionCity: region,
        street,
        buildingNumber: buildNumber,
        phone: phoneNumber,
        faxNumber: fax,
        email: companyEmail,
        companyImage: selectedFile,
        isActive,
        activityCode,
        companyEnvironment,
        clientId,
        clientSecret,
        tokenPin,
        posSerial,
        posOsVersion,
        posClientId,
        posClientSecret,
        userId: options?.map(user => {
          return {
            userId: user._id,
            username: user.username,
          };
        }),
      };

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      baseUrl
        .put(`/companies/updateCompany/${id}`, bodyData, config)
        .then(res => {
          if (res.status === 200) {
            setisLoading(false);
            setDisbaled(false);
            setvalidationError([]);
            notify('تم تعديل الشركة بنجاح', 'success');
            setTimeout(() => {
              // navigate('/admin/company');
              // eslint-disable-next-line no-restricted-globals
              location.replace('/admin/company');
            }, 1000);
          }
        })
        .catch(err => {
          setisLoading(false);
          setDisbaled(false);
          if (err.response.data.statusCode === '401') {
            localStorage.clear();
            navigate('/');
          }
        });
    }
  };

  const [key, setKey] = useState('info');
  const [keyTwo, setkeyTwo] = useState('e-invoic');
  return (
    <div className='page-content'>
      <div className='container-fluid company'>
        <div className='d-flex'>
          <PageTitle title='تعديل شركة' />
          <div className='me-2'>
            {isLoading ? <i className='fa fa-spinner fa-spin'></i> : ''}
          </div>
        </div>
        <Tabs
          id='controlled-tab-example'
          activeKey={key}
          onSelect={k => setKey(k)}
          className='mb-3 '
        >
          <Tab eventKey='info' title='بيانات الشركة'>
            <div className='row company-info'>
              <div className='col-12'>
                <div className='row mb-3'>
                  <label
                    htmlFor='companyName'
                    className='col-sm-2 col-xl-1 col-form-label'
                  >
                    الاسم
                  </label>
                  <div className='col-sm-10 col-xl-11'>
                    <input
                      type='text'
                      className='form-control'
                      id='companyName'
                      placeholder='ادخل اسم الشركة'
                      value={companyName}
                      onChange={onChangeCompanyName}
                    />
                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'companyName'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'companyName'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row mb-3'>
                  <label
                    htmlFor='companyType'
                    className='col-sm-2 col-xl-1 col-form-label'
                  >
                    النوع
                  </label>

                  <div className='col-sm-10 col-xl-11'>
                    <select
                      onChange={onSelectCompanyType}
                      name='companyType'
                      className='form-select'
                      id='companyType'
                      value={companyType}
                    >
                      <option defaultValue='0' hidden>
                        اختر النوع
                      </option>
                      <option value='P'>Person</option>
                      <option value='B'>Business</option>
                      <option value='F'>Foreigner</option>
                    </select>
                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'companyType'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'companyType'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-12'>
                <div className='row mb-3'>
                  <label
                    htmlFor='taxRegistration'
                    className='col-sm-2 col-form-label'
                  >
                    ت.ضريبى
                  </label>
                  <div className='col-sm-10'>
                    <input
                      type='text'
                      className='form-control'
                      id='taxRegistration'
                      placeholder='رقم التسجيل'
                      value={taxRegistration}
                      onChange={onChangeTaxRegistration}
                    />
                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'taxRegistration'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'taxRegistration'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-12'>
                <div className='row mb-3'>
                  <label
                    htmlFor='brancheId'
                    className='col-sm-2 col-form-label'
                  >
                    رقم الفرع
                  </label>
                  <div className='col-sm-10'>
                    <input
                      type='text'
                      className='form-control'
                      id='brancheId'
                      placeholder='رقم الفرع'
                      value={brancheId}
                      onChange={onChangeBrancheId}
                    />
                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'brancheId'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'brancheId'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-xl-6 col-lg-12'>
                <div className='row mb-3'>
                  <label htmlFor='country' className='col-sm-2 col-form-label'>
                    البلد
                  </label>
                  <div className='col-sm-10'>
                    <select
                      className='form-select'
                      name='country'
                      value={country}
                      onChange={onSelectCountry}
                      id='country'
                    >
                      <option defaultValue='0'>اختر البلد</option>
                      {countryData?.map(country => (
                        <option key={country.code} value={country.code}>
                          {country.Desc_ar}
                        </option>
                      ))}
                    </select>

                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'country'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'country'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-12'>
                <div className='row mb-3'>
                  <label
                    htmlFor='governorate'
                    className='col-sm-2 col-form-label'
                  >
                    المحافظة
                  </label>
                  <div className='col-sm-10'>
                    <input
                      type='text'
                      className='form-control'
                      id='governorate'
                      placeholder='cairo'
                      name='governorate'
                      value={governorate}
                      onChange={onChangeGovernorate}
                    />

                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'governorate'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'governorate'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-12'>
                <div className='row mb-3'>
                  <label htmlFor='region' className='col-sm-2 col-form-label'>
                    المنطقة
                  </label>
                  <div className='col-sm-10'>
                    <input
                      type='text'
                      className='form-control'
                      id='region'
                      placeholder='المنطقة'
                      name='region'
                      value={region}
                      onChange={onChangeRegion}
                    />
                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'region'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'region'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-12'>
                <div className='row mb-3'>
                  <label
                    htmlFor='buildNumber'
                    className='col-sm-2 col-form-label'
                  >
                    ر.المبنى
                  </label>
                  <div className='col-sm-10'>
                    <input
                      type='text'
                      className='form-control'
                      id='buildNumber'
                      placeholder='رقم المبنى'
                      name='buildNumber'
                      value={buildNumber}
                      onChange={onChangeBuildNumber}
                    />

                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'buildNumber'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'buildNumber'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row mb-3'>
                  <label
                    htmlFor='streetName'
                    className='col-sm-2 col-xl-1  col-form-label'
                  >
                    الشارع
                  </label>
                  <div className='col-sm-10 col-xl-11'>
                    <input
                      type='text'
                      className='form-control'
                      id='streetName'
                      placeholder='اسم الشارع'
                      name='street'
                      value={street}
                      onChange={onChangeStreet}
                    />
                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'street'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'street'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-12'>
                <div className='row mb-3'>
                  <label
                    htmlFor='phoneNumber'
                    className='col-sm-2 col-form-label'
                  >
                    هاتف
                  </label>
                  <div className='col-sm-10'>
                    <input
                      type='text'
                      className='form-control'
                      id='phoneNumber'
                      placeholder='ادخل الهاتف '
                      value={phoneNumber}
                      onChange={onChangePhoneNumber}
                    />
                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'phoneNumber'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'phoneNumber'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-12'>
                <div className='row mb-3'>
                  <label htmlFor='fax' className='col-sm-2 col-form-label'>
                    فاكس
                  </label>
                  <div className='col-sm-10'>
                    <input
                      type='text'
                      className='form-control'
                      id='fax'
                      placeholder='ادخل الفاكس'
                      value={fax}
                      onChange={onChangeFax}
                    />
                  </div>
                </div>
              </div>

              <div className='col-12'>
                <div className='row mb-3'>
                  <label
                    htmlFor='companyEmail'
                    className='col-sm-2 col-xl-1 col-form-label'
                  >
                    الايميل
                  </label>
                  <div className='col-sm-10 col-xl-11'>
                    <input
                      type='email'
                      className='form-control'
                      id='companyEmail'
                      placeholder='ادخل ايميل الشركة'
                      value={companyEmail}
                      onChange={onChangeCompanyEmail}
                    />
                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'companyEmail'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'companyEmail'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-12'>
                <div className='row mb-3'>
                  <div className='col-sm-2 '>
                    <label
                      htmlFor='upload-photo'
                      className=' col-form-label company-btn'
                    >
                      تحميل
                    </label>
                    <input
                      type='file'
                      name='photo'
                      onChange={onImageChange}
                      id='upload-photo'
                    />
                    <button
                      className='company-btn me-2 me-sm-0'
                      onClick={removeImage}
                    >
                      مسح
                    </button>
                  </div>

                  <div className='col-sm-10'>
                    <img src={img} alt='fxy' height='80px' width='100px' />
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-12'>
                <div className='row mb-3'>
                  <label className='col-sm-2 col-form-label'>الحالة</label>
                  <div className='col-sm-10'>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='flexSwitchCheckChecked'
                        checked={isActive}
                        onChange={onChangeIsActive}
                      />
                      <label className='' htmlFor='flexSwitchCheckChecked'>
                        نشط
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row mb-3'>
                  <label className='col-sm-2 col-xl-1 col-form-label'>
                    المستخدم
                  </label>
                  <div className='col-sm-10 col-xl-11'>
                    <Multiselect
                      placeholder='اسم المستخدم'
                      options={options}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      selectedValues={selectedValues}
                      displayValue='username'
                      className='text-end'
                    />
                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'seletedUserID'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'seletedUserID'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey='pill' title='بيانات الربط'>
            <div className='row e-pill'>
              <div className='col-xl-6 col-lg-12'>
                <div className='row mb-3'>
                  <label
                    htmlFor='activityCode'
                    className='col-sm-3 col-form-label'
                  >
                    كود النشاط
                  </label>
                  <div className='col-sm-9'>
                    <input
                      type='text'
                      className='form-control'
                      id='activityCode'
                      placeholder=' كود النشاط'
                      value={activityCode}
                      onChange={onChangeActivityCode}
                    />
                    <div
                      className={
                        validationError.filter(
                          ele => ele.context.label === 'activityCode'
                        )[0]
                          ? 'alert mt-2 alert-warning'
                          : ''
                      }
                    >
                      {
                        validationError.filter(
                          ele => ele.context.label === 'activityCode'
                        )[0]?.message
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-12'>
                <div className='form-check form-switch'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='flexSwitchCheckChecked'
                    checked={companyEnvironmentMode}
                    onChange={onChangeCompanyEnvironmentMode}
                  />
                  <label>البيئة الفعلية</label>
                </div>
              </div>
            </div>
            <Tabs
              id='controlled-tab'
              activeKey={keyTwo}
              className='mt-3'
              onSelect={k => setkeyTwo(k)}
            >
              <Tab
                eventKey='e-invoic'
                className='e-invoic-tab'
                title='الفاتورة الإلكترونية'
              >
                <div className='card'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-12'>
                        <div className='row mb-3'>
                          <label
                            htmlFor='clientid'
                            className='col-sm-4 col-form-label'
                          >
                            Client Id
                          </label>
                          <div className='col-sm-8'>
                            <input
                              type='text'
                              className='form-control'
                              id='clientid'
                              value={clientId}
                              onChange={onChangeClientId}
                            />
                            <div
                              className={
                                validationError.filter(
                                  ele => ele.context.label === 'clientId'
                                )[0]
                                  ? 'alert mt-2 alert-warning'
                                  : ''
                              }
                            >
                              {
                                validationError.filter(
                                  ele => ele.context.label === 'clientId'
                                )[0]?.message
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-12'>
                        <div className='row mb-3'>
                          <label
                            htmlFor='clientsecret'
                            className='col-sm-4 col-form-label'
                          >
                            Client Secret
                          </label>
                          <div className='col-sm-8'>
                            <input
                              type='text'
                              className='form-control'
                              id='clientsecret'
                              value={clientSecret}
                              onChange={onChangeClientSecret}
                            />
                            <div
                              className={
                                validationError.filter(
                                  ele => ele.context.label === 'clientSecret'
                                )[0]
                                  ? 'alert mt-2 alert-warning'
                                  : ''
                              }
                            >
                              {
                                validationError.filter(
                                  ele => ele.context.label === 'clientSecret'
                                )[0]?.message
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-12'>
                        <div className='row mb-3'>
                          <label
                            htmlFor='tokenpen'
                            className='col-sm-4 col-form-label'
                          >
                            Token Pin
                          </label>
                          <div className='col-sm-8'>
                            <input
                              type='text'
                              className='form-control'
                              id='tokenpen'
                              value={tokenPin}
                              onChange={onChangeTokenPin}
                            />
                            <div
                              className={
                                validationError.filter(
                                  ele => ele.context.label === 'tokenPin'
                                )[0]
                                  ? 'alert mt-2 alert-warning'
                                  : ''
                              }
                            >
                              {
                                validationError.filter(
                                  ele => ele.context.label === 'tokenPin'
                                )[0]?.message
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey='e-receipt'
                className='e-receipt-tab'
                title='الإيصال الإلكتروني'
              >
                <div className='card'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-12'>
                        <div className='row mb-3'>
                          <label
                            htmlFor='posSerial'
                            className='col-sm-4 col-form-label'
                          >
                            POS Serial
                          </label>
                          <div className='col-sm-8'>
                            <input
                              type='text'
                              className='form-control'
                              id='posSerial'
                              value={posSerial}
                              onChange={onChangePosSerial}
                            />
                            <div
                              className={
                                validationError.filter(
                                  ele => ele.context.label === 'posSerial'
                                )[0]
                                  ? 'alert mt-2 alert-warning'
                                  : ''
                              }
                            >
                              {
                                validationError.filter(
                                  ele => ele.context.label === 'posSerial'
                                )[0]?.message
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-12'>
                        <div className='row mb-3'>
                          <label
                            htmlFor='posOsVersion'
                            className='col-sm-4 col-form-label'
                          >
                            POS OS Version
                          </label>
                          <div className='col-sm-8'>
                            <input
                              type='text'
                              className='form-control'
                              id='posOsVersion'
                              value={posOsVersion}
                              onChange={onChangePosOsVersion}
                            />
                            <div
                              className={
                                validationError.filter(
                                  ele => ele.context.label === 'posOsVersion'
                                )[0]
                                  ? 'alert mt-2 alert-warning'
                                  : ''
                              }
                            >
                              {
                                validationError.filter(
                                  ele => ele.context.label === 'posOsVersion'
                                )[0]?.message
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-12'>
                        <div className='row mb-3'>
                          <label
                            htmlFor='posClientId'
                            className='col-sm-4 col-form-label'
                          >
                            POS Client Id
                          </label>
                          <div className='col-sm-8'>
                            <input
                              type='text'
                              className='form-control'
                              id='posClientId'
                              value={posClientId}
                              onChange={onChangePosClientId}
                            />
                            <div
                              className={
                                validationError.filter(
                                  ele => ele.context.label === 'posClientId'
                                )[0]
                                  ? 'alert mt-2 alert-warning'
                                  : ''
                              }
                            >
                              {
                                validationError.filter(
                                  ele => ele.context.label === 'posClientId'
                                )[0]?.message
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-12'>
                        <div className='row mb-3'>
                          <label
                            htmlFor='posClientsecret'
                            className='col-sm-4 col-form-label'
                          >
                            POS Client Secret
                          </label>
                          <div className='col-sm-8'>
                            <input
                              type='text'
                              className='form-control'
                              id='posClientsecret'
                              value={posClientSecret}
                              onChange={onChangePosClientSecret}
                            />
                            <div
                              className={
                                validationError.filter(
                                  ele => ele.context.label === 'posClientSecret'
                                )[0]
                                  ? 'alert mt-2 alert-warning'
                                  : ''
                              }
                            >
                              {
                                validationError.filter(
                                  ele => ele.context.label === 'posClientSecret'
                                )[0]?.message
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Tab>
        </Tabs>
        <div className='my-3'>
          {disbaled ? (
            <button
              disabled={disbaled}
              className='mt-2 btn-custom btn-custom-disable '
            >
              <i className='fa fa-spinner fa-spin'></i>
            </button>
          ) : (
            <button
              type='submit'
              onClick={handelSubmit}
              className='mt-2 btn-custom'
            >
              اضافة
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminEditCompany;
