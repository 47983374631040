import React from "react";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import PageTitle from "../../../Components/Uitily/PageTitle";

export default function ManulReciptpage() {
  let [showTable, setShowTable] = useState([]);
  let [Recipt, setRecipt] = useState({
    clientNum: "",
    clientName: "",
    clientType: "",
    ReciptType: "",
    currency: "",
    quantityPrice: "",
    cate: "",
    quantityCate: "",
    price: "",
  });

  function getDataUser(e) {
    console.log();
    let currentUser = { ...Recipt };
    currentUser[e.target.name] = e.target.value;
    setRecipt(currentUser);
    console.log(Recipt);
  }
  function handleClick(e) {
    e.preventDefault();
    setShowTable([...showTable, Recipt]);
    setRecipt({
      clientNum: "",
      clientName: "",
      clientType: "",
      ReciptType: "",
      currency: "",
      quantityPrice: "",
      cate: "",
      quantityCate: "",
      price: "",
    });
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <PageTitle title="ارسال الايصال يدوى" />
          <form>
            <div className="row pb-4">
              <div className="card ">
                <div className="card-body m-3">
                  <div className="row ">
                    <div className="col-12">
                      <div className="row mb-3">
                        <label
                          htmlFor="clientNum"
                          className="col-sm-2  col-xl-1  col-form-label">
                          الرقم
                        </label>
                        <div className="col-sm-10  col-xl-11">
                          <input
                            type="number"
                            className="form-control border-info"
                            id="clientNumber"
                            name="clientNum"
                            onChange={(e) => getDataUser(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12">
                      <div className="row mb-3">
                        <label
                          htmlFor="clientName"
                          className="col-sm-2 col-form-label">
                          العميل
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control border-info"
                            id="clientName"
                            placeholder="ادخل اسم العميل"
                            name="clientName"
                            onChange={(e) => getDataUser(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12">
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <select
                            className="form-select border-info"
                            id="type"
                            name="clientType">
                            <option defaultValue hidden value="0">
                              نوع العميل
                            </option>
                            <option value="1">Person</option>
                            <option value="2">Business</option>
                            <option value="3">Foreigner</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row mb-3">
                        <label
                          htmlFor="ReciptType"
                          className="col-sm-2  col-xl-1  col-form-label">
                          نوع الايصال
                        </label>
                        <div className="col-sm-10 col-xl-11">
                          <select
                            className="form-select border-info"
                            id="type"
                            name="ReciptType"
                            onChange={(e) => getDataUser(e)}>
                            <option defaultValue hidden value="0"></option>
                            <option value="1">ايصال بيع</option>
                            <option value="2">ايصال مرتجع بيع</option>
                       
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row mb-3">
                        <label
                          htmlFor="date"
                          className="col-sm-2 col-xl-1 col-form-label">
                          التاريخ
                        </label>
                        <div className="col-sm-10 col-xl-11">
                          <input
                            type="date"
                            className="form-control border-info "
                            id="date"
                            name="date"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6">
                      <div className="row mb-3">
                        <label
                          htmlFor="country"
                          className="col-sm-2 col-form-label">
                          العملة
                        </label>
                        <div className="col-sm-10">
                          <select
                            className="form-select border-info"
                            id="currency"
                            name="currency"
                            onChange={(e) => getDataUser(e)}>
                            <option defaultValue hidden value="0"></option>
                            <option value="1">Person</option>
                            <option value="2">Business</option>
                            <option value="3">Foreigner</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <input
                            type="number"
                            className="form-control border-info"
                            id="quinty"
                            placeholder="الكمية"
                            name="quantityPrice"
                            onChange={(e) => getDataUser(e)}
                          />
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-xl-6 col-lg-6">
                      <div className="row mb-3">
                        <label
                          htmlFor="country"
                          className="col-sm-2 col-form-label">
                          Old UUID
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="number"
                            className="form-control border-info"
                            id="quinty"
                            name="quantityPrice"
                            onChange={(e) => getDataUser(e)}
                          />
                        </div>
                      </div>
                    </div>







                  </div>
                </div>
              </div>
            </div>

            <Tabs
              defaultActiveKey="home"
              id="justify-tab-example"
              className=""
              justify>
              <Tab eventKey="home" title="تفاصيل الايصال">
                <div className="row ">
                  <div className="col-xl-3 col-lg-12">
                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <select
                          className="form-select border-info"
                          id="type"
                          name="cate"
                          onChange={(e) => getDataUser(e)}>
                          <option defaultValue hidden value="0">
                            {" "}
                            الصنف
                          </option>
                          <option value="1">بطاطس</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-12">
                    <div className="row mb-3">
                      <label
                        htmlFor="clientName"
                        className="col-sm-2 col-form-label">
                        الكمية
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="number"
                          className="form-control border-info"
                          id="quantityCate"
                          placeholder="ادخل الكمية"
                          name="quantityCate"
                          onChange={(e) => getDataUser(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-12">
                    <div className="row mb-3">
                      <label
                        htmlFor="clientName"
                        className="col-sm-2 col-form-label">
                        السعر
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="number"
                          className="form-control border-info"
                          id="clientName"
                          placeholder="السعر"
                          name="price"
                          onChange={(e) => getDataUser(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-12">
                    <div className="row mb-3">
                      <div className="col-sm-8">
                        <button
                          className="btn btn-primary btn-custom"
                          onClick={handleClick}>
                          اضافة
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
            <div className="card  mt-4">
              <div className="card-body">
                <table className="table table-hover table-bordered ">
                  <thead>
                    <tr>
                      <th scope="col"> SalesPrice</th>
                      <th scope="col">City</th>
                      <th scope="col">ItemName</th>
                      <th scope="col">Currency</th>
                      <th scope="col">Date</th>
                      <th scope="col">Customer</th>
                      <th scope="col">InvoiceID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {showTable?.map((ele, idx) => (
                      <tr key={idx}>
                        <td>{ele.cate}</td>
                        <td>{ele.quantityCate}</td>
                        <td>{ele.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <button className="btn btn-primary mt-2 me-2 btn-custom">
              حفظ
            </button>
            <button className="btn btn-primary mt-2 btn-custom">ارسال</button>
          </form>
        </div>
      </div>
    </>
  );
}
