import React from "react";

import MangeAllClients from "./../../Components/Clients/MangeAllClients";
import "./Pagination.css"
const ManageClientPage = () => {
  return (
    <>
      <MangeAllClients />
    </>
  );
};

export default ManageClientPage;
