import React from "react";
import { useNavigate } from "react-router-dom";
const LogoutButton = () => {
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("companyId");
    navigate("/");
  };
  return (
    <>
      <button className="btn logout-btn " style={{padding:'10px 20px',fontWeight:'bold'}} onClick={logOut}>
        تسجيل الخروج
      </button>
    </>
  );
};

export default LogoutButton;
