import React from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../Components/Uitily/PageTitle";

export default function EdituserLicense() {
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <PageTitle title="تعديل الترخيص" />
          <div className="card ">
            <div className="card-body m-3">
              <form>
                <div className="row ">
                  <div className="col-12">
                    <div className="row mb-3">
                      <label
                        htmlFor="clientNum"
                        className="col-sm-2   col-form-label">
                        رقم العميل
                      </label>
                      <div className="col-sm-10 ">
                        <input
                          type="number"
                          className="form-control border-info"
                          id="clientNumber"
                          name="clientNum"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12">
                    <div className="row mb-3">
                      <label
                        htmlFor="clientName"
                        className="col-sm-2 col-form-label">
                        العميل
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control border-info"
                          id="clientName"
                          placeholder="ادخل اسم العميل"
                          name="clientName"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row mb-3">
                      <label
                        htmlFor="lincenseStartdate"
                        className="col-sm-4  col-xl-2  col-form-label">
                        تاريخ بداية التفعيل
                      </label>
                      <div className="col-sm-8  col-xl-10">
                        <input
                          type="date"
                          className="form-control border-info"
                          id="lincenseStartdate"
                          name="lincenseStartdate"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row mb-3">
                      <label
                        htmlFor="lincenseEnddate"
                        className="col-sm-4  col-xl-2  col-form-label">
                        تاريخ نهاية التفعيل
                      </label>
                      <div className="col-sm-8  col-xl-10">
                        <input
                          type="date"
                          className="form-control border-info"
                          id="lincenseEnddate"
                          name="lincenseEnddate"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <button className="btn btn-primary mt-2 me-2 btn-custom">
                  حفظ
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
