import React from "react";
import AdminAddCompany from "./../../Components/Admin/AdminAddCompany";

const AdminAddCompanyPage = () => {
  return (
    <>
      <AdminAddCompany />
    </>
  );
};

export default AdminAddCompanyPage;
