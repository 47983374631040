import React from "react";
import EditProduct from "./../../Components/Product/EditProduct";

const EditProductPage = () => {
  return (
    <>
      <EditProduct />
    </>
  );
};

export default EditProductPage;
