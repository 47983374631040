import React, { useState } from "react";
import {
  AiOutlineDatabase,
  AiOutlineSetting,
  AiOutlineHome,
  AiOutlineMail,
  AiOutlineLogout,
} from "react-icons/ai";
import { MdArrowBackIosNew } from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
//import { BiReceipt } from "react-icons/bi";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import "./Sidebar.css";
import { useEffect } from "react";
const Sidebar = ({ isMenuActive }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("companyId");
    navigate("/");
  };

  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);
  //const [isReceiptOpen, setIsReceiptOpen] = useState(false);
  useEffect(() => {
    if (
      location.pathname.includes("user") ||
      location.pathname.includes("contact") ||
      location.pathname.includes("company") ||
      location.pathname.includes("license")
    ) {
      setIsSettingOpen(true);
    } else if (
      location.pathname.includes("client") ||
      location.pathname.includes("Product") ||
      location.pathname.includes("searchcoding")
    ) {
      setIsInfoOpen(true);
    } else if (location.pathname.includes("invoice")) {
      setIsInvoiceOpen(true);
    }
  }, [location]);

  return (
    <div className={isMenuActive === true ? `sidebar active` : `sidebar `}>
      <>
        <li className="nav-item mt-2">
          <NavLink to="/dashboard" className="nav-link" role="button">
            <AiOutlineHome />
            <span className=" me-2">لوحة التحكم</span>
          </NavLink>
        </li>
        <li className="nav-item mt-2">
          <a
            className="nav-link menu-link"
            href="#adminSettings"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded={isSettingOpen}
            // aria-expanded="true"
            aria-controls="adminSettings">
            <AiOutlineSetting />
            <span data-key="t-invoices" className=" me-2">
              إعداد الشركة
            </span>
            <span className="arrow-icon">
              <MdArrowBackIosNew />
            </span>
          </a>
          <div
            className={
              isSettingOpen === false
                ? "menu-dropdown collapse"
                : "menu-dropdown collapse show"
            }
            id="adminSettings">
            <ul className="nav nav-sm flex-column">
              {JSON.parse(localStorage.getItem("user")) != null &&
                JSON.parse(localStorage.getItem("user")).isAdmin
              ? (
                <li className="nav-item">
                  <NavLink to="/admin/mangeusers" className="nav-link">
                    إدارة المستخدمين
                  </NavLink>
                </li>
              ) : null}
              {JSON.parse(localStorage.getItem("user")) != null &&
               JSON.parse(localStorage.getItem("user")).isAdmin ? (
                <li className="nav-item">
                  <NavLink to="/admin/company" className="nav-link">
                    بيانات الشركات
                  </NavLink>
                </li>
              ) : null}
              {/* <li className="nav-item">
                <NavLink to="/license" className="nav-link">
                  الترخيص
                </NavLink>
              </li> */}
            </ul>
          </div>
        </li>
        <li className="nav-item mt-2">
          <a
            className="nav-link menu-link"
            href="#basicdata"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded={isInfoOpen}
            aria-controls="basicdata">
            <AiOutlineDatabase />
            <span data-key="t-invoices" className=" me-2">
              البيانات الأساسية
            </span>
            <span className="arrow-icon">
              <MdArrowBackIosNew />
            </span>
          </a>
          <div
            className={
              isInfoOpen === false
                ? "menu-dropdown collapse"
                : "menu-dropdown collapse show"
            }
            id="basicdata">
            <ul className="nav nav-sm flex-column">
              <li className="nav-item">
                <NavLink to="/clients" className="nav-link">
                  العملاء
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/manulcode" className="nav-link">
                  تكويد الأصناف
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/Product" className="nav-link">
                  الأصناف
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/searchcoding" className="nav-link">
                  البحث على المنتجات
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item mt-2">
          <a
            className="nav-link menu-link"
            href="#e-invoice"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded={isInvoiceOpen}
            aria-controls="e-invoice">
            <TbFileInvoice />
            <span data-key="t-invoices" className=" me-2">
              الفاتورة الالكترونية
            </span>
            <span className="arrow-icon">
              <MdArrowBackIosNew />
            </span>
          </a>
          <div
            className={
              isInvoiceOpen === false
                ? "menu-dropdown collapse"
                : "menu-dropdown collapse show"
            }
            id="e-invoice">
            <ul className="nav nav-sm flex-column">
              <li className="nav-item">
                <NavLink to="/manulinvoicepage" className="nav-link">
                  إرسال الفاتورة يدوى
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/excelinvoice" className="nav-link">
                  إرسال من اكسيل
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink to="/erpinvoice" className="nav-link">
                  ارسال من Erp
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink to="/internalinvoices" className="nav-link">
                  الفواتير الداخلية
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/advancedresearch" className="nav-link">
                  بحث متقدم
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/packagedata" className="nav-link">
                  تحميل حزمة بيانات
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/invoicereports" className="nav-link">
                  تقارير تفصيلية
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        {/* <li className="nav-item mt-2">
          <a
            className="nav-link menu-link"
            href="#e-receipt"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded={isReceiptOpen}
            aria-controls="e-receipt">
            <BiReceipt />
            <span data-key="t-receipts" className=" me-2">
              الايصال الالكترونى
            </span>
            <span className="arrow-icon">
              <MdArrowBackIosNew />
            </span>
          </a>
          <div
            className={
              isReceiptOpen === false
                ? "menu-dropdown collapse"
                : "menu-dropdown collapse show"
            }
            id="e-receipt">
            <ul className="nav nav-sm flex-column">
              <li className="nav-item">
                <NavLink to="/manulrecipt" className="nav-link">
                  ارسال الايصال يدوى
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/excelrecipt" className="nav-link">
                  ارسال من اكسل
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/erprecipt" className="nav-link">
                  ارسال من erp
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/internalrecipts" className="nav-link">
                  الايصالات الداخلية
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/advancedsesearchrecipts" className="nav-link">
                  بحث متقدم
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/backagerecipts" className="nav-link">
                  تحميل حزمة بيانات
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/reciptreports" className="nav-link">
                  تقارير تفصيلية
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}
        <li className="nav-item mt-2">
          <NavLink to="/contactus" className="nav-link" role="button">
            <AiOutlineMail />
            <span className=" me-2"> اتصل بنا</span>
          </NavLink>
        </li>
        <li className="nav-item mt-2 logout-item">
          <div className="nav-link" role="button" onClick={logOut}>
            <AiOutlineLogout />
            <span className="me-2"> تسجيل الخروج</span>
          </div>
        </li>
      </>

      <div className="logout">
        {" "}
        <LogoutButton />
      </div>
    </div>
  );
};

export default Sidebar;
