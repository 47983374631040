import React, { useEffect, useState } from "react";
import "./Navbar.css";
import LogoutButton from "./LogoutButton";
import { HiMenuAlt3 } from "react-icons/hi";
import baseUrl from "../../Api/baseUrl"
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';

const Navbar = ({ isMenuActive, handleMenu }) => {
  const companyId = localStorage.getItem('companyId');
  const [company, setCompany]=useState(null);
  const [allCompanies, setAllCompanies] =useState([])
  const [isOpen, setIsOpen] = useState(false); 
  const [selectedCompanyId, setSelectedCompanyId] = useState(null)  
  let [isLoading, setisLoading] = useState(false);
  let [disbaled, setDisbaled] = useState(false);
  const handleCompanyChange = (e) =>{
    setSelectedCompanyId(e.target.value)
  }
const changeCompany= async()=>{
  setisLoading(true);
  console.log('selected companyId' , selectedCompanyId);
  try{
    const res = await baseUrl.get(`/companies/getCompany/${selectedCompanyId}`, {
     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
   })
 
   if(res.data.data) {
     localStorage.setItem('companyId' , res.data.data._id)
     setCompany(res.data.data);
     setisLoading(false);
     setIsOpen(false)
       // eslint-disable-next-line
    location.reload()
   }
   }catch(err){
     console.log(err)
   }

}
const handleClose = () => setIsOpen(false);
const getAllCompanies = async()=>{
  try{
    let user = localStorage.getItem('user');
    let parsedUser  =  JSON.parse(user);
    let id = parsedUser._id;
   const res = await baseUrl.get(`/auth/getUserCompanies/${id}`
  //, {
  //   headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  // }
  )

  if(res.data.data) {
    setAllCompanies(res.data.data);
    setIsOpen(true);
  }
  }catch(err){
    console.log(err)
  }
} 


useEffect(() => {
  baseUrl
    .get(`/companies/getCompany/${companyId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((res) => {
      setCompany(res.data.data);
      console.log(res.data.data)
    })
    .catch((err) => {
      if (err.response.data.statusCode === "401") {
        console.log(err)
      }
    });

  }, []);
 
  let user = JSON.parse(localStorage.getItem('user'));
  return (
    <nav className="custom-nav">
       <div>
       <Modal
            show={isOpen}
            onHide={handleClose}
            dialogClassName='form-modal'
            backdrop='static'
            keyboard={false}
            style={{ width:"900px" }}
          >
            <Modal.Header>
              <Modal.Title>
                <div style={{fontWeight:'bold'}}>اختر شركة</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className=''>
                <div className=' m-3'>
                  <div className='row '>
                  
                    <div className=''>
                      <div className='row mb-3'>
                        <label
                          htmlFor='country'
                          className='col-sm-2 col-form-label'
                          style={{fontWeight:'bold'}}
                        >
                          الشركة
                        </label>
                        <div className='col-sm-10'>
                          <select
                            className='form-select'
                            name='country'
                            onChange={handleCompanyChange}
                            id='country'
                          >
                            <option defaultValue='0'>اختر الشركة</option>
                            {allCompanies?.map(c=> (
                              <option key={c._id} value={c._id}>
                                {c.companyName}
                              </option>
                            ))}
                          </select>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className='mt-2 btn-custom successButton' style={{background:'#27ae60',fontWeight:"bold",padding:"10px 20px"}} onClick={handleClose}>
                تراجع
              </button>
              {disbaled ? (
                <button
                  disabled={disbaled}
                  className='mt-2 btn-custom btn-custom-disable '
                >
                  <i className='fa fa-spinner fa-spin'></i>
                </button>
              ) : (
                <button
                style={{fontWeight:"bold",padding:"10px 20px"}}
                  className='mt-2 btn-custom'
                  type='submit'
                  onClick={changeCompany}
                >
                  تغير
                </button>
              )}
            </Modal.Footer>
          </Modal>
       </div>
      <div className="container-fluid d-flex  justify-content-between align-items-center">
        <div className="logo-brand d-flex align-items-center">
          <div>
          <Link to="/dashboard">
            <img src={logo} alt="SkyLink Logo" style={{ maxHeight: "50px" }} />
          </Link>
          <div className="icon" style={{marginRight:'60px'}}>
            <HiMenuAlt3 onClick={handleMenu} />
          </div>
          </div>
          <p style={{fontSize:'20px',marginRight:"1em",background:"#f8f8f8",padding:'10px 20px', borderRadius:'600px',color:"black"}}>مرحبا يا <span style={{fontWeight:'bold'}}>{user?.username}</span> 🤝</p>
          
         
        </div>
        <div>
        <div style={{display:'flex',gap:"5px"}}>
        {company && (
            <div  onClick={getAllCompanies}  style={{fontSize: "18px", marginRight: "1em",fontWeight:'bold' , background:'var(--variant-color)',color:'white', padding:'10px 20px', borderRadius:'5px',cursor:"pointer",transition:"ease-in",transitionDuration:'300ms'}} className='changeCompanyButton'>
               <span>{company.companyName} </span>
               <span style={{fontSize:"13px"}}>({company.companyEnvironment == 'preprod' ? 'تجريبية' : 'فعلية'})</span>
            </div>
          )}
        {/* <button className="successButton btn logout-btn " style={{padding:"10px 20px",fontWeight:"bold",background:"#27ae60"}}>تغيير الشركة</button> */}
        <LogoutButton />
        </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
