import React, { useState } from "react";
import axios from "axios";
import PageTitle from "./../../Components/Uitily/PageTitle";
import { useNavigate } from "react-router-dom";
import baseUrl from "../../Api/baseUrl";

export default function SearchProducts() {
  const navigate = useNavigate();
  let companyId = localStorage.getItem("companyId");
  let [invoices, setInvoices] = useState([]);
  let [productinvoice, setProductinvoice] = useState({
    companyId,
    codeName: "",
    itemCode: "",
    CodeDescription: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  function getDatainvoice(e) {
    let currentInvoice = { ...productinvoice };
    currentInvoice[e.target.name] = e.target.value;
    setProductinvoice(currentInvoice);
  }
  async function searchCode(e) {
    e.preventDefault();
    try {
      const { data } = await baseUrl.post(
        `/invoices/searchMyEGSCode`,
        productinvoice,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setInvoices(data.data);
    } catch (error) {
      if (error.response.status === 401) {
        // Redirect to login page
        navigate("/");
      } else if (error.response.data.status === "fail") {
        // Set invoices to empty array
        setInvoices([]);
      } else {
        // Handle other errors
        console.log(error);
      }
    }
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <PageTitle title="البحث على منتجاتي" />
          <form onSubmit={searchCode}>
            <div className="row ">
              <div className="col-xl-3 col-lg-12">
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control border-info"
                      id="name"
                      placeholder="الاسم "
                      name="codeName"
                      onChange={getDatainvoice}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-12">
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control border-info"
                      id="CodeID"
                      name="CodeID"
                      placeholder="GPC Code "
                      onChange={getDatainvoice}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-12">
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control border-info"
                      id="CodeDescription"
                      placeholder="الوصف"
                      name="CodeDescription"
                      onChange={getDatainvoice}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-12">
                <button className="btn logout-btn">بحث</button>
              </div>
            </div>
          </form>

          <div className="card  mt-4">
            <div className="card-body">
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col">GPC</th>
                    <th scope="col">GPC</th>
                    <th scope="col">الاسم</th>
                    <th scope="col">وصف</th>
                    <th scope="col">الفئة</th>
                    <th scope="col">النوع</th>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="d-flex">
                      <i className="fa fa-spinner fa-spin spinner-table"></i>
                    </td>
                  </tr>
                </tbody>
                {isLoading ? (
                  <tbody>
                    <tr>
                      <td className="d-flex">
                        <i className="fa fa-spinner fa-spin spinner-table"></i>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {invoices.map((invoice) => (
                      <tr key={invoice.codeID}>
                        <td>
                          {invoice.parentItemCode
                            ? invoice.parentItemCode
                            : "------"}
                        </td>
                        <td>{invoice.itemCode ? invoice.itemCode : "-----"}</td>
                        <td>
                          {invoice.codeNameSecondaryLang
                            ? invoice.codeNameSecondaryLang
                            : "-----"}
                        </td>
                        <td>
                          {invoice.descriptionSecondaryLang
                            ? invoice.descriptionSecondaryLang
                            : "-----"}
                        </td>
                        <td>
                          {invoice.codeTypeName
                            ? invoice.codeTypeName
                            : "-----"}
                        </td>
                        <td>
                          {invoice.codeTypeName
                            ? invoice.codeTypeName
                            : "-----"}
                        </td>
                        <td>
                          {invoice.codeNamePrimaryLang
                            ? invoice.codeNamePrimaryLang
                            : "-----"}
                        </td>
                        <td>
                          {invoice.descriptionPrimaryLang
                            ? invoice.descriptionPrimaryLang
                            : "-----"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
