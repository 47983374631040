import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { FaPhone } from "react-icons/fa";
import PageTitle from "./../Components/Uitily/PageTitle";
const ContactUsPage = () => {
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");

  // const onChangeName = (e) => {
  //   setName(e.target.value);s
  // };
  // const onChangeEmail = (e) => {
  //   setEmail(e.target.value);
  // };
  // const onChangeMessage = (e) => {
  //   setMessage(e.target.value);
  // };
  return (
    <>
      <div className="page-content">
        <div className="container-fluid contact">
          <PageTitle title="اتصل بنا" />
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="info-box">
                <AiOutlineWhatsApp />

                <h3>تراسل معنا</h3>
                <p>
                  {" "}
                  <a href="https://wa.me/+201553250155" target="blank">
                    01553250155
                  </a>{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="info-box">
                <AiOutlineMail />
                <h3>البريد الالكترونى</h3>
                <p>
                  <a href="mailto:support@skylink.com.eg">
                    support@skylink-eg.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="info-box">
                <FaPhone />
                <h3>اتصل بنا</h3>
                <p>
                  <a href="tel:+201553250155">01553250155</a>
                </p>
              </div>
            </div>
          </div>

          {/* <div className="card ">
            <div className="card-body m-3">
              <div className="row mb-3">
                <label
                  htmlFor="name"
                  className="col-sm-3 col-lg-2  col-form-label">
                  الاسم
                </label>
                <div className="col-sm-9 col-lg-10">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="ادخل ااسم"
                    value={name}
                    onChange={onChangeName}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="email"
                  className="col-sm-3 col-lg-2  col-form-label">
                  البريد الالكترونى
                </label>
                <div className="col-sm-9 col-lg-10">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="ادخل البريد الالكترونى"
                    value={email}
                    onChange={onChangeEmail}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="message"
                  className="col-sm-3 col-lg-2  col-form-label">
                  الرسالة
                </label>
                <div className="col-sm-9 col-lg-10">
                  <textarea
                    className="form-control"
                    name="message"
                    id="message"
                    rows="5"
                    placeholder="اكتب الرسالة"
                    value={message}
                    onChange={onChangeMessage}></textarea>
                </div>
              </div>
              <button className=" mt-2 btn-custom">حفظ</button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;
