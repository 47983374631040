import React, { useState,useEffect } from "react";
import PageTitle from "./../../Components/Uitily/PageTitle";
import { Tabs, Tab } from "react-bootstrap";
import { AiOutlineSync } from "react-icons/ai";
import "./InvoiceReports.css";
import { useNavigate } from "react-router-dom";
import baseUrl from "../../Api/baseUrl";
const InvoiceReportsPage = () => {
  const [key, setKey] = useState("invoice");
  let companyId = localStorage.getItem("companyId");
  let [Invoices,setInvoices]=useState();
  let [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  async function getInvoiceReport() {
    setisLoading(true);
    try {
      let data = await baseUrl.post(
        `/invoices/getInvoiceReport`,
        { companyId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setisLoading(false);
      setInvoices(data.data.data);
      console.log(Invoices)
    } catch (error) {
      if (error.response.status === 401) {   
        navigate("/");    }


  }}
  useEffect(() => {
    getInvoiceReport();
  }, []);
  return (

    <>
      <div className="page-content reports">
        <div className="container-fluid">
          <PageTitle title="تقارير مباشرة الفواتير" />
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3">
            <Tab eventKey="invoice" title="الفواتير">
              <div className="d-flex justify-content-between align-items-center invoices-number ">
                <div className="d-flex">
                  <p>فاتورة</p>
                </div>
                <AiOutlineSync />
              </div>
              <div className="row ">
                <div className="col-md-6">
                  <h6> احصائيات الفواتير الصحيحة المرسلة (المبيعات)</h6>

                  <table className="table ">
                  <thead>
                    <tr>
                        <th scope="col" className="text-center bg-light"> النوع</th>
                        <th scope="col" className="text-center bg-light">المدفوع </th>
                        <th scope="col" className="text-center bg-light"> الضريبة</th>
                        </tr>
                        </thead>
                    <tbody>
                      <tr className="text-center">
                        <td>فاتورة </td>
                        <td scope="row"> {Invoices?.invoicesPaid}</td>
                        <td scope="row">{Invoices?.invoicesTax}</td>

                      </tr>
                      <tr className="text-center">
                        <td>اشعار خصم </td>
                        <td scope="row"> {Invoices?.creditsPaid}</td>
                        <td scope="row"> {Invoices?.creditsTax}</td>
                      </tr>
                      <tr className="text-center">
                        <td>اشعار اضافة </td>
                        <td scope="row"> {Invoices?.depitsPaid}</td>
                        <td scope="row"> {Invoices?.depitsTax}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6">
                  <h6> احصائيات الفواتير الصحيحة المستلمة (المشتريات)</h6>

                  <table className="table ">
                  <thead>
<tr>
                  <th scope="col" className="text-center bg-light"> النوع</th>
                  <th scope="col" className="text-center bg-light">المدفوع </th>
                  <th scope="col" className="text-center bg-light"> الضريبة</th>
                  </tr>
                  </thead>
                    <tbody>
                      <tr className="text-center">
                         <td>فاتورة </td>
                        <td scope="row">00.00 </td>
                        <td scope="row"> 00.00</td>
                      </tr>

                      <tr className="text-center">
                        <td>اشعار خصم </td>
                        <td scope="row"> 00.00</td>
                        <td scope="row"> 00.00</td>
                      </tr>
                      <tr className="text-center">
                        <td>اشعار اضافة </td>
                        <td scope="row"> 00.00</td>
                        <td scope="row"> 00.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row ">
                <div className="col-12">
                  <h6> احصائيات الفواتير</h6>

                  <table className="table ">
                  <thead>
<tr>
                  <th scope="col" className="text-center bg-light"> الحالة</th>
                  <th scope="col" className="text-center bg-light">الاجمالى </th>
                  <th scope="col" className="text-center bg-light"> الضريبة</th>
                  <th scope="col" className="text-center bg-light"> العدد</th>
                  </tr>
                  </thead>
                    <tbody>
                      <tr className="text-center">
                        <td className="text-success">صحيحة </td>
                        <td scope="row"> {Invoices?.totalAmountValid}</td>
                        <td scope="row"> {Invoices?.totalTaxableFeesValid}</td>
                        <td scope="row"> {Invoices?.totalInvoicesValid}</td>
                      </tr>
                      <tr className="text-center">
                        <td className="text-warning">غير صحيحة</td>
                        <td scope="row"> {Invoices?.totalAmountInValid}</td>
                        <td scope="row"> {Invoices?.totalTaxableFeesInValid}</td>
                        <td scope="row"> {Invoices?.totalInvoicesInValid}</td>
                      </tr>
                      <tr className="text-center">
                        <td className="text-info"> ملغية</td>
                        <td scope="row"> {Invoices?.totalAmountCancelled}</td>
                        <td scope="row"> {Invoices?.totalTaxableFeesCancelled}</td>
                        <td scope="row"> {Invoices?.totalInvoicesCancelled}</td>
                      </tr>
                      <tr className="text-center">
                        <td className="text-danger"> مرفوضة</td>
                        <td scope="row"> {Invoices?.totalAmountRejected}</td>
                        <td scope="row"> {Invoices?.totalTaxableFeesRejected}</td>
                        <td scope="row"> {Invoices?.totalInvoicesRejected}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="info" title="الايصالات"></Tab> */}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default InvoiceReportsPage;
