import React from "react";

import AddProduct from "./../../Components/Product/AddProduct";

const AddProductPage = () => {
  return (
    <>
      <AddProduct />
    </>
  );
};

export default AddProductPage;
