import React from "react";
import SingleCompany from "./../../Components/Admin/SingleCompany";

const SingleCompanyPage = () => {
  return (
    <>
      <SingleCompany />
    </>
  );
};

export default SingleCompanyPage;
