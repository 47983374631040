import React from "react";
import AddClient from "./../../Components/Clients/AddClient";

const AddClientPage = () => {
  return (
    <>
      <AddClient />
    </>
  );
};

export default AddClientPage;
