import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { debounce } from 'lodash';
import notify from "./../../hook/useNotifaction";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { TbFileImport } from "react-icons/tb";
import { TbFileExport } from "react-icons/tb";
import { Navigate } from "react-router-dom";
import { GoCloudDownload } from "react-icons/go";
import PageTitle from "./../Uitily/PageTitle";
import "../Uitily/Pagination.css";
import { saveAs } from "file-saver";
import baseUrl from "./../../Api/baseUrl";
import useDebounce from "../../hook/useDebounce";
const MangeProduct = () => {
  let companyId = localStorage.getItem("companyId");
  const [runUseEffect, setRun] = useState(0);
  let [products, setProducts] = useState([]);
  let [totalResults, setTotalResults] = useState(0);
  const [PageCount, setpageCount] = useState();
  let pageList = new Array(PageCount).fill().map((ele, i) => i + 1);
  let [isLoading, setisLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(1);
  const debouncedGetAllProduct = debounce(getAllproduct, 500);

  async function getAllproduct(currentpage) {
    setisLoading(true);
    let { data } = await baseUrl.post(
      `/products/getProducts?page=${currentpage}&limit=10`,
      { companyId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    setisLoading(false);
    setProducts(data.data);
    setpageCount(Math.ceil(data.results / 10));
  }
  useMemo(() => {
    debouncedGetAllProduct(page);
  }, [runUseEffect, page]);
  const [showDelete, setShowDelete] = useState(false);
  const [id, setId] = useState("");
  const handleDeletClose = () => setShowDelete(false);
  const handleDeleteShow = (itemId) => {
    setShowDelete(true);
    setId(itemId);
  };
  const handleExport = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    await axios
      .post(
        "/products/exportProductsToExcel",
        { companyId },
        config
      )
      .then((res) => {
        const url = res.data.data.fileURL;
        saveAs(url);
        if (res.data.status === "Success") {
          notify(res.data.message, "success");
        }
      })
      .catch((error) => {
        if (error) {
          notify("يوجد مشكلة فى تصدير المنتجات", "error");
        } else if (error.response.status === 401) {
          Navigate("/");
        }
      });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [selectedFile, setSelectedFile] = useState("");

  const onFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };
  const handleImport = (e) => {
    e.preventDefault();
    if (selectedFile === "") {
      notify("اضف الملف ", "error");
      return;
    }

    const formData = new FormData();
    formData.append("productsExcel", selectedFile);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(
        "/products/importProductsFromExcel",
        formData,
        config
      )
      .then((res) => {
        if (res.data.status === "Success") {
          notify(res.data.message, "success");
        }
        setShow(false);
        setRun((prev) => prev + 1);
      })
      .catch((err) => {
        notify("يوجد مشكلة فى استراد المنتجات ", "error");
      });
  };
  const handelDelete = async () => {
    try {
      const res = await baseUrl.delete(
        `/products/deleteProduct/${id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (res.status === 200) {
        notify("تم حذف المنتج بنجاح", "success");
        setShowDelete(false);
        setRun((prev) => prev + 1);
      }
    } catch (err) {
      notify("هناك مشكله فى عملية الحذف", "error");
    }
  };
  const [searchData, setSearchData] = useState("");
  const debouncedValue = useDebounce(searchData, 1000)
  async function searchProduct(pageNumber = 1) {
    try {
      const { data } = await baseUrl.post(
        `/products/searchProduct?page=${pageNumber}&limit=3`,
        {
          companyId,
          itemName: searchData,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (data.status === "Success") {
        setProducts(data.data);
        setTotalResults(data.total);
      } else if (data.status === "fail") {
        setProducts([]);
        setTotalResults(0);
        setpageCount(0);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        Navigate("/");
      } else {
        console.error(error);
      }
    } finally {
      setisLoading(false);
    }
  }
  function handleKeyPress(e) {
    const { value } = e.target;
    setSearchData(value);

  }

  function onPaginate(newPageNumber) {
    setPage(newPageNumber);

    if (debouncedValue) {
      searchData(newPageNumber);
    }
    setActivePage(newPageNumber);
  }

  const nextPage = () => {
    const newPageNumber = page + 1;
    if (newPageNumber <= PageCount) {
      setPage(newPageNumber);
      onPaginate(newPageNumber);
    }
  };
  const prevPage = () => {
    const newPageNumber = page - 1;
    if (newPageNumber >= 1) {
      setPage(newPageNumber);
      onPaginate(newPageNumber);
    }
  };


  useEffect(() => {
    if (debouncedValue) {
      searchProduct(page);
    } else {
      debouncedGetAllProduct(page)
    }
  }, [runUseEffect, page, debouncedValue]);


  return (
    <div className="page-content">
      {/* Modal For Delete User */}
      <Modal show={showDelete} onHide={handleDeletClose}>
        <Modal.Header>
          <Modal.Title>
            {" "}
            <div>تاكيد الحذف</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>هل انتا متاكد من حذف المنتج </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleDeletClose}>
            تراجع
          </Button>
          <Button variant="dark" onClick={handelDelete}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {" "}
            <div> استيراد من اكسل</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              accept=".xlsx"
              type="file"
              name="file"
              onChange={onFileChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            تراجع
          </Button>
          <Button variant="dark" onClick={handleImport}>
            حفظ
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-fluid">
        <div className="d-flex">
          <PageTitle title=" الاصناف" />
          <div className="me-2">
            {isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-sm-6 mb-3 mb-sm-0">
            <Link to="/addproduct" className="btn main-btn">
              اضافة صنف
            </Link>
          </div>
          <div className="col-sm-6">
            <div className="search-box">
              <BsSearch className="search-icon" />
              <input
                type="text"
                className="form-control"
                id="searchMemberList"
                placeholder="ابحث عن النتائج"
                onChange={handleKeyPress}
              />
            </div>
          </div>
        </div>

        <div className="card  mt-4">
          <div className="card-body">
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col"> اسم الصنف</th>
                  <th scope="col">كود الصنف</th>
                  <th scope="col">تعديل</th>
                  <th scope="col">حذف</th>
                </tr>
              </thead>

              {isLoading ? (
                <tbody>
                  <tr>
                    <td className="d-flex">
                      <i className="fa fa-spinner fa-spin spinner-table"></i>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {products?.map((product) => (
                    <tr key={product._id}>
                      <td>
                        <Link to={`/Product/${product._id}`} className="name">
                          {product.itemName}
                        </Link>
                      </td>
                      <td>{product.itemCode}</td>

                      <td>
                        <Link
                          to={`/editProduct/${product._id}`}
                          className="table-icon edit">
                          <AiOutlineEdit />
                        </Link>
                      </td>
                      <td>
                        <span className=" table-icon delete">
                          <AiOutlineDelete
                            onClick={() => {
                              handleDeleteShow(product._id);
                            }}
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            <nav aria-label="...">
              <ul className="pagination">
                <li className="page-item ">
                  <a className="page-link" onClick={prevPage}>
                    السابق
                  </a>
                </li>
                {pageList.map((ele) => (
                  <li
                    className={`page-item ${
                      ele === activePage ? "active-page" : ""
                    }`}
                    onClick={() => onPaginate(ele)}
                    key={ele}>
                    <span className="page-link ">{ele}</span>
                  </li>
                ))}

                <li className="page-item">
                  <a className="page-link" onClick={nextPage}>
                    التالي
                  </a>
                </li>
              </ul>
            </nav>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <p>العدد : {products.length}</p>
              <div className="d-flex">
                <button
                  className="file-icon"
                  title="تصدير"
                  onClick={handleExport}>
                  <TbFileExport />
                </button>
                <button
                  className="file-icon"
                  title="استيراد"
                  onClick={handleShow}>
                  <TbFileImport />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MangeProduct;
