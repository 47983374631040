import React from "react";
import PageTitle from "../../../Components/Uitily/PageTitle";
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import "./advancedReserch.css";
import { Link } from "react-router-dom";
export default function AdvancedResearchRecipts() {
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <PageTitle title="بحث متقدم" />
          <div className="row filter">
            <div className="col-4 ">
              <div className="card h-100">
                <h5 className="card-header">تصفية</h5>
                <div className="card-body " data-bs-spy="scroll">
                  <p className="mt-2 mb-2 filter-btn">
                    <a
                      data-bs-toggle="collapse"
                      href="#collapseExample1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample">
                      الفترة
                      <span>
                        <FaRegArrowAltCircleDown className="me-2" />
                      </span>
                    </a>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckIndeterminate"
                      />
                    </div>
                  </p>

                  <div className="collapse" id="collapseExample1">
                    <div className="card card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="row mb-3">
                            <label
                              htmlFor="startRecipt"
                              className="col-sm-2  col-xl-2  col-form-label">
                              من
                            </label>
                            <div className="col-sm-10  col-xl-10">
                              <input
                                type="date"
                                className="form-control border-info"
                                id="startRecipt"
                                name="startRecipt"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row mb-3">
                            <label
                              htmlFor="endRecipt"
                              className="col-sm-1  col-xl-2  col-form-label">
                              الي
                            </label>
                            <div className="col-sm-11  col-xl-10">
                              <input
                                type="date"
                                className="form-control border-info"
                                id="endRecipt"
                                name="endRecipt"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mt-2 mb-2 filter-btn">
                    <a
                      data-bs-toggle="collapse"
                      href="#collapseExample2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample">
                      رقم الايصال{" "}
                      <span>
                        <FaRegArrowAltCircleDown className="me-2" />
                      </span>
                    </a>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckIndeterminate"
                      />
                    </div>
                  </p>

                  <div className="collapse" id="collapseExample2">
                    <div className="card card-body">
                      <div className="row">
                        <div className="col-12">
                          <input
                            type="text"
                            className="form-control border-info mt-1"
                            placeholder="UID"
                          />
                        </div>
                        <div className="col-12">
                          <input
                            type="text"
                            className="form-control border-info mt-1"
                            placeholder="InternalID"
                          />
                        </div>
                        <div className="col-12">
                          <input
                            type="text"
                            className="form-control border-info mt-1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mt-2 mb-2 filter-btn">
                    <a
                      data-bs-toggle="collapse"
                      href="#collapseExample3"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample">
                      العميل{" "}
                      <span>
                        <FaRegArrowAltCircleDown className="me-2" />
                      </span>
                    </a>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckIndeterminate"
                      />
                    </div>
                  </p>

                  <div className="collapse" id="collapseExample3">
                    <div className="card card-body">
                      <div className="row">
                        <div className="col-12">
                          <input
                            type="text"
                            className="form-control border-info mt-1"
                            placeholder="الرقم الضريبي للعميل"
                          />
                        </div>

                        <div className="col-12">
                          <input
                            type="text"
                            className="form-control border-info mt-1"
                            placeholder="اسم العميل"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mt-2 mb-2 filter-btn">
                    <a
                      data-bs-toggle="collapse"
                      href="#collapseExample4"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample">
                      نوع الايصال{" "}
                      <span>
                        <FaRegArrowAltCircleDown className="me-2" />
                      </span>
                    </a>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckIndeterminate"
                      />
                    </div>
                  </p>
                  <div className="collapse" id="collapseExample4">
                    <div className="card card-body">
                      <div className="row">
                        <div className="col-12">
                          <select
                            className="form-select border-info"
                            id="status"
                            name="status">
                            <option defaultValue hidden value="0">
                              {" "}
                            </option>
                            <option value="Vaild">Vaild</option>
                            <option value="InVaild">InVaild</option>
                          </select>
                        </div>
                        <div className="col-12">
                          <select
                            className="form-select border-info"
                            id="status2"
                            name="status2">
                            <option defaultValue hidden value="0">
                              {" "}
                            </option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="rejected">rejected</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mt-2 mb-2 filter-btn">
                    <a
                      data-bs-toggle="collapse"
                      href="#collapseExample5"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample">
                      الايصالات{" "}
                      <span>
                        <FaRegArrowAltCircleDown className="me-2" />
                      </span>
                    </a>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckIndeterminate"
                      />
                    </div>
                  </p>
                  <div className="collapse" id="collapseExample5">
                    <div className="card card-body">
                      <div className="row">
                        <div className="col-12">
                          <select
                            className="form-select border-info"
                            id="Recipts"
                            name="Recipts">
                            <option defaultValue hidden value="0">
                              {" "}
                            </option>
                            <option value="sales">مبيعات</option>
                            <option value="buys">مشتريات</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className="col-8">
              <div className="card h-100 filter-card">
                <div className="card-header">
                  <div className="row ">
                    <div className="col-8">
                      <div className="row align-items-center">
                        <h5 className="text-end">البيانات</h5>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="row">
                        <label
                          htmlFor="clientName"
                          className="col-sm-4 col-form-label">
                          الصفحة
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="number"
                            className="form-control border-info"
                            id="quantityCate"
                            name="quantityCate"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body ">
                  <table className="table table-hover table-bordered ">
                    <thead>
                      <tr>
                        <th scope="col"> status</th>
                        <th scope="col">تحميل </th>
                        <th scope="col"> Requsted</th>
                        <th scope="col"> data</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        
    </>
  );
}
