import React, { useState, useEffect } from 'react';
import axios from 'axios';
import notify from '../hook/useNotifaction';
import baseUrl from '../Api/baseUrl';
import env from 'react-dotenv';
import moment from 'moment';
import GenerateSignature from './GenerateSignature';
import ClearDecimalPlaces from './ClearDecimalPlaces';
import { toast } from 'react-toastify';

export default async function sendInvoice(invoices,{
  withSignature = true,
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
  // get date with this format 2015-02-13T13:15:00Z
  const date = moment().subtract(2, 'h').subtract(10, 'minute').utc().format();

  try {
    const normalizedInvoices = await invoices.map(async invoice => {
      console.log('new updated invoices', invoice);

      if (invoice?.status === 'Valid' || invoice?.status === 'Submitted') {
        toast.error(`الفاتورة رقم ${invoice.internalId} مرسلة مسبقا`);
        throw new Error('الفاتورة مرسلة مسبقا');
      }

      // clear any property that not belong to the tax authority because the api will return error
      const mappedInvoiceLines = invoice.invoiceLines.map(invoiceLine => {
        const newItem = {
          description: invoiceLine.description,
          itemType: invoiceLine.itemType,
          itemCode: invoiceLine.itemCode,
          unitType: invoiceLine.unitType,
          quantity: invoiceLine.quantity,
          total: invoiceLine.total,
          salesTotal: invoiceLine.salesTotal,
          valueDifference: invoiceLine.valueDifference,
          totalTaxableFees: invoiceLine.totalTaxableFees,
          netTotal: invoiceLine.netTotal,
          itemsDiscount: invoiceLine.itemsDiscount,
          discount: invoiceLine.discount,
          taxableItems: invoiceLine.taxableItems,
        };
        const unitValue = {
          currencySold: invoiceLine.unitValue.currencySold,
          amountEGP: invoiceLine.unitValue.amountEGP,
        };
        // if the currency is not EGP then add the currencyExchangeRate and amountSold
        if (invoiceLine.unitValue.currencySold !== 'EGP') {
          unitValue.currencyExchangeRate =
            invoiceLine.unitValue.currencyExchangeRate;
          unitValue.amountSold = invoiceLine.unitValue.amountSold;
        }
        return {
          ...newItem,
          unitValue,
        };
      });

      // remove any property that the tax authority not require
      const newInvoice = {
        issuer: {
          ...invoice.issuer,
          address: {
            branchId: invoice.issuer.address.branchID,
            country: invoice.issuer.address.country,
            buildingNumber: invoice.issuer.address.buildingNumber,
            governate: invoice.issuer.address.governate,
            regionCity: invoice.issuer.address.regionCity,
            street: invoice.issuer.address.street,
          },
        },
        receiver: invoice.receiver,
        documentType: invoice.documentType,
        documentTypeVersion: '1.0',
        dateTimeIssued: date,
        taxpayerActivityCode: invoice.taxpayerActivityCode,
        internalID: `${invoice.internalId}:${invoice._id}`,
        invoiceLines: mappedInvoiceLines,
        totalSalesAmount: invoice.totalSalesAmount,
        totalDiscountAmount: invoice.totalDiscountAmount,
        totalItemsDiscountAmount: invoice.totalItemsDiscountAmount,
        netAmount: invoice.netAmount,
        taxTotals: invoice.taxTotals,
        extraDiscountAmount: invoice.extraDiscountAmount,
        totalAmount: invoice.totalAmount,
      };
      const ClearedInvoiceData = ClearDecimalPlaces(newInvoice);
      if (withSignature) {
        // return ClearedInvoiceData;
        return GenerateSignature(ClearedInvoiceData);
      } 
      return ClearedInvoiceData;
    });

    const signedInvoices = await Promise.allSettled(normalizedInvoices);

    const resolvedInvoices = signedInvoices
      .filter(invoice => invoice.status === 'fulfilled')
      .map(invoice => invoice.value);

    // remove any decimal places from the invoice max to 5 decimal places
    if (resolvedInvoices.length) {
      const response = await baseUrl.post(
        `/invoices/${withSignature ? 'sendInvoice' : 'sendReceipt'}`,
        {
          documents: resolvedInvoices,
          company: {
            companyId:localStorage.getItem('companyId')
          },
        },
        config
      );
      // TODO: we need to add error messages for all the errors that the api return
      // documentation of the errors can be found on tax authority sdk documentation
      if (response.status === 200) {
        let rejectedInvoices = response.data?.data?.rejectedDocuments;
        if (rejectedInvoices.length) {
          rejectedInvoices.map(i => {
            let invoiceNum = i.internalId;
            i.error.details.map(e => {
              console.log('Error in rejected', e);
              toast.error(
                `عفوا تم رفض الفاتورة رقم ${invoiceNum?.split(':')?.[0]}`
              );
            });
          });
        } else {
          toast.success('تم ارسال الفاتورة بنجاح');
        }
      }
    }
  } catch (error) {
    console.log('Error ❌', error.response.data);
    toast.error(error.response.data.message);
    //TODO: HANDLE OTHER ERRORS like the invoice details component
  }
}
