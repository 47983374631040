import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../../../Components/Uitily/PageTitle';
import notify from '../../../hook/useNotifaction';
import baseUrl from './../../../Api/baseUrl';
import { toast } from 'react-toastify';
import sendInvoice from '../../../utils/SendInvoice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { InputText } from 'primereact/inputtext';
import { Button as PrimeButton } from 'primereact/button';
import { FiSend, FiRefreshCw } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import moment from 'moment';

const statusOptions = [
  { name: 'جميع الحالات', value: null },
  { name: 'Pending', value: 'Pending' },
  { name: 'Invalid', value: 'Invalid' },
  { name: 'Rejected', value: 'Rejected' },
  { name: 'Submitted', value: 'Submitted' },
  { name: 'Valid', value: 'Valid' },
];

export default function InternalInvoices() {
  const searchRef = useRef(null);
  let [Invoices, setInvoices] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [rows, setRows] = useState(10);
  const [PageCount, setpageCount] = useState();
  let [isLoading, setisLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  let companyId = localStorage.getItem('companyId');
  const [company, setCompany] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    data: null,
  });

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const handleDeletClose = () =>
    setShowDeleteModal({
      show: false,
      data: null,
    });

  async function InternalInvoices({
    page,
    search = '',
    status = null,
    rows = 10,
  }) {
    setisLoading(true);
    setSelectedInvoices([]);
    try {
      // removed and replaced with cron job to make it more performant 
      // await baseUrl.patch(
      //   '/invoices/updateInvoiceStatus',
      //   { companyId },
      //   {
      //     headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      //   }
      // );

      let { data } = await baseUrl.post(
        `/invoices/getInternalInvoices?page=${page}&limit=${rows}&search=${search}&status=${status}`,
        { companyId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      setisLoading(false);
      setInvoices(data.data);
      setpageCount(+data.results);
    } catch (e) {
      if (e?.response?.data?.message) {
        notify(`Can't get Invoices: ${e?.response?.data?.message}`, 'error');
      } else {
        notify(`Can't get Invoices: ${e?.response?.data?.error}`, 'error');
      }
      setisLoading(false);
    }
  }

  const handleDeleteConfirm = async () => {
    try {
      const res = await baseUrl.delete(`/invoices/deleteInternalInvoice`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        data: { invoices: showDeleteModal.data },
      });
      if (res.status === 200) {
        notify('تم الحذف بنجاح', 'success');
        InternalInvoices({
          page,
          status: selectedStatus,
          rows,
          search: searchRef.current.value,
        });
        setSelectedInvoices([]);
        setShowDeleteModal({
          show: false,
          data: null,
        });
      }
    } catch (err) {
      notify('هناك مشكله فى عملية الحذف', 'error');
    }
  };

  const sendInvoices = async ({ isBulk }) => {
    setisLoading(true);
    let invoicesToSend = [];

    if (isBulk) {
      invoicesToSend = Invoices;
    } else {
      invoicesToSend = selectedInvoices;
    }

    try {
      await sendInvoice(invoicesToSend,{
        withSignature:true
      });
      InternalInvoices({
        page,
        status: selectedStatus,
        rows,
        search: searchRef.current.value,
      });
    } catch (error) {
      console.log(error);
    }

    setisLoading(false);
  };

  const sendReceipts = async ({isBulk}) => {
    setisLoading(true);
    let receiptsToSend = [];
    if (isBulk) {
      receiptsToSend = Invoices;
    } else {
      receiptsToSend = selectedInvoices;
    }

    try {
      await sendInvoice(receiptsToSend,{
        withSignature:false
      });
      InternalInvoices({
        page,
        status: selectedStatus,
        rows,
        search: searchRef.current.value,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const deleteInvoices = async ({ isBulk }) => {
    let invoicesToDelete = [];

    if (isBulk) {
      invoicesToDelete = Invoices?.map(invoice => invoice._id);
    } else {
      invoicesToDelete = selectedInvoices?.map(invoice => invoice._id);
    }

    setShowDeleteModal({
      show: true,
      data: invoicesToDelete,
    });
  };

  useEffect(() => {
    InternalInvoices({
      page,
      status: selectedStatus,
      rows,
      search: searchRef.current.value,
    });

    if (!companyId) {
      baseUrl
        .get(`/companies/getCompany/${companyId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        .then(res => {
          setCompany(res.data.data);
          console.log(res.data.data);
        })
        .catch(err => {
          if (err.response.data.statusCode === '401') {
            console.log(err);
          }
        });
    }
  }, []);

  const handleSearch = () => {
    setFirst(1);
    InternalInvoices({
      search: searchRef.current.value,
      status: selectedStatus,
      page: 1,
      rows,
    });
  };

  const columns = [
    {
      body: data => (
        <Link to={`/invoicedetails/${data._id}`}>عرض الفاتورة</Link>
      ),
    },
    {
      header: 'الرقم الداخلي',
      field: 'internalId',
    },
    {
      header: 'تاريخ الاصدار',
      field: 'dateTimeIssued',
      body: data => (
        <span>
          {data.dateTimeIssued
            ? moment(data.dateTimeIssued).format('YYYY-MM-DD | hh:mm A')
            : '-------'}
        </span>
      ),
    },
    {
      header: 'نوع الوثيقة',
      field: 'documentType',
    },
    {
      header: 'الحالة',
      body: data => <span className={`${data?.status}`}>{data?.status}</span>,
    },

    {
      header: 'المبلغ الإجمالي',
      field: 'totalAmount',
    },
    // {
    //   header: 'إجمالي مبلغ الخصم',
    //   field: 'totalDiscountAmount',
    // },
    // {
    //   header: 'مبلغ الخصم الإضافي',
    //   field: 'extraDiscountAmount',
    // },
    {
      header: 'صافي المبلغ',
      field: 'netAmount',
    },
    {
      header: 'اسم المُصدر',
      field: 'issuer.name',
    },
    // {
    //   header: 'الرقم التعريفي للفرع المُصدر',
    //   field: 'issuer.address.branchID',
    // },
    // {
    //   header: 'البلد',
    //   field: 'issuer.address.country',
    // },
    // {
    //   header: 'المحافظة',
    //   field: 'issuer.address.governate',
    // },
    {
      header: 'اسم المستقبل',
      field: 'receiver.name',
    },
    {
      header: 'نوع المستقبل',
      field: 'receiver.type',
    },
    // {
    //   header: 'البلد',
    //   field: 'receiver.address.country',
    // },
    // {
    //   header: 'المحافظة',
    //   field: 'receiver.address.governate',
    // },
  ];

  return (
    <>
      <div className='page-content'>
        {showDeleteModal.show && (
          <Modal show={showDeleteModal.show} onHide={handleDeletClose} centered>
            <Modal.Header>
              <Modal.Title>
                {' '}
                <div>تاكيد الحذف</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>هل انتا متاكد من حذف الفواتير </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='success' onClick={handleDeletClose}>
                تراجع
              </Button>
              <Button variant='dark' onClick={handleDeleteConfirm}>
                حذف
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <div className='container-fluid'>
          <PageTitle title='الفواتير الداخلية' />

          <div className='actionBtns'>
            <button
              className='successButton'
              style={{
                backgroundColor: '#27ae60',
                border: 'none',
                color: 'white',
                padding: '10px 20px',
                fontSize: '16px',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                transition: 'ease-in-out',
              }}
              onClick={() =>
                sendInvoices({
                  isBulk: selectedInvoices?.length ? false : true,
                })
              }
              disabled={isLoading}
            >
              <FiSend
                style={{
                  marginTop: '3px',
                }}
              />

              {selectedInvoices?.length
                ? ' ارسال المحدد كفواتير'
                : ' ارسال الجميع كفواتير'}
            </button>
            <button
              className='successButton'
              style={{
                backgroundColor: '#f37070',
                border: 'none',
                color: 'white',
                padding: '10px 20px',
                fontSize: '16px',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                transition: 'ease-in-out',
              }}
              onClick={() =>
                sendReceipts({
                  isBulk: selectedInvoices?.length ? false : true,
                })
              }
              disabled={isLoading}
            >
              <FiSend
                style={{
                  marginTop: '3px',
                }}
              />

              {selectedInvoices?.length
                ? ' ارسال المحدد كإيصالات'
                : ' ارسال الجميع كإيصالات'}
            </button>

            <button
              className='errorButton'
              style={{
                backgroundColor: '#e74c3c',
                border: 'none',
                color: 'white',
                padding: '10px 20px',
                fontSize: '16px',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
              onClick={() =>
                deleteInvoices({
                  isBulk: selectedInvoices?.length ? false : true,
                })
              }
              disabled={isLoading}
            >
              <AiFillDelete
                style={{
                  marginTop: '3px',
                }}
              />

              {selectedInvoices?.length
                ? 'مسح الفواتير المحددة'
                : 'مسح جميع الفواتير'}
            </button>
          </div>
        </div>

        <div className='mt-4'>
          <div className='tableActions_container'>
            <div className='tableSearch_container'>
              <label htmlFor='invoiceId'>بحث</label>
              <InputText
                id='invoiceId'
                placeholder='ابحث عن ارقام داخلية. مثال: 1324,2331'
                ref={searchRef}
              />

              <PrimeButton label='بحث' onClick={handleSearch} />
            </div>

            <div className={`tableFilters_container tableActions`}>
              <label htmlFor='status'>الحالة</label>
              <Dropdown
                value={selectedStatus}
                onChange={e => {
                  setSelectedStatus(e.value);
                  setFirst(1);
                  InternalInvoices({
                    status: e.value,
                    page: 1,
                    rows,
                    search: searchRef.current.value,
                  });
                }}
                options={statusOptions}
                optionLabel='name'
                placeholder='اختر الحالة'
              />
            </div>

            <PrimeButton
              onClick={() =>
                InternalInvoices({
                  page,
                  status: selectedStatus,
                  rows,
                  search: searchRef.current.value,
                })
              }
              style={{
                backgroundColor: '#d04d94',
                color: 'white',
                borderRadius: '5px',
                padding: '5px 10px',
                width: '50px',
                height: '50px',
                border: 'none',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <FiRefreshCw
                style={{
                  fontSize: '20px',
                  animation: 'rotate 2s linear infinite',
                }}
              />
            </PrimeButton>
          </div>

          <DataTable
            scrollable
            value={Invoices}
            stripedRows
            loading={isLoading}
            selection={selectedInvoices}
            onSelectionChange={e => setSelectedInvoices(e.value)}
            dataKey='_id'
          >
            <Column selectionMode='multiple' headerStyle={{ width: '3rem' }} />
            {columns?.map((col, i) => (
              <Column
                key={i}
                header={col.header}
                field={col.field}
                body={col.body}
              />
            ))}
          </DataTable>

          <Paginator
            totalRecords={PageCount}
            first={first}
            rows={rows}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            onPageChange={e => {
              setPage(e.page + 1);
              setFirst(e.first);
              setRows(e.rows);

              console.log(e);

              InternalInvoices({
                page: e.page + 1,
                status: selectedStatus,
                rows: e.rows,
                search: searchRef.current.value,
              });
            }}
          />

          {/*         
                        <td>
                          <Link
                            to={`/editInvoice/${invoice._id}`}
                            className="table-icon edit"
                          >
                            <AiOutlineEdit />
                          </Link>
                        </td>

                        <td>
                          <span className=" table-icon delete">
                            <AiOutlineDelete
                              onClick={() => {
                                handleDeleteShow(invoice._id);
                              }}
                            />
                          </span>
                        </td> */}

          {/* <td>
                        <span className=" table-icon delete">
                          <AiOutlineDelete
                            onClick={() => {
                              handleDeleteShow(invoice._id);
                            }}
                          />
                        </span>
                      </td> */}
          {/* </tr>
                    </React.Fragment> */}
          {/* ))} */}
          {/* </tbody> */}
          {/* )} */}
          {/* </table> */}

          {/* </div>  */}
        </div>
      </div>
    </>
  );
}
