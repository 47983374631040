import React from "react";
import LoginHook from "./../../hook/auth/login-hook";
import Select from "react-select";

const LoginCompoment = () => {
  const [
    password,
    companyOptions,
    onSelectName,
    onChangePassword,
    onSelectCompany,
    onSubmit,
    users,
  ] = LoginHook();
  console.log(companyOptions)

  // const optionsForCompanies = companyOptions.map((c) => ({
  //   value: c._id ,
  //   label: c.companyName,
  // }));

  return (
    <div className="form-content p-5">
      <h5>تسجيل الدخول</h5>
      <form onSubmit={onSubmit}>
        <div className="mt-3">
          <div className="mb-3">
            <select
              name="username"
              onChange={onSelectName}
              className="form-select">
              <option value="0">اسم المستخدم</option>
              {users
                ? users.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item.username}
                      </option>
                    );
                  })
                : null}
            </select>
            
          </div>

          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={onChangePassword}
              placeholder="الباسورد"
            />
          </div>
          <div className="mb-3">
            {/* <select
              name="comapny"
              onChange={onSelectCompany}
              className="form-select">
              <option value="0">اسم الشركة</option>

              {companyOptions
                ? companyOptions.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item.companyName}
                      </option>
                    );
                  })
                : null}
            </select> */}
            {
              companyOptions ? (
                <Select 
                name='company'
                onChange={onSelectCompany}
                options={companyOptions}
                isSearchable
              />
              ):null
            }
           
          </div>
          <button type="submit" className="btn btn-primary">
            تسجيل الدخول
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginCompoment;
