import { useEffect, useState } from "react";

const useDebounce = (value, delay)=>{
    const[debouncedValue, setDebouncedValue] = useState(value);

    useEffect(()=>{
       const id = setTimeout(()=>{
        console.log("seting search data");
        setDebouncedValue(value)

        console.log(debouncedValue)
       }, delay)

       return ()=>{
        clearTimeout(id);
       }
    },[value, delay])

    return debouncedValue;
}

export default useDebounce;