import { toast } from 'react-toastify';
import CryptoJS from "crypto-js";


function parseJSONWithTrailingZeros(jsonString) {
  let parsedData;

  try {
    parsedData = JSON.parse(jsonString, (key, value) => {
      if (typeof value === 'string' && value.endsWith('.0')) {
        return value;
      }
      return value;
    });
  } catch (error) {
    console.error(`Error: ${error.message}`);
    return;
  }

  return parsedData;
}


function serialize(documentStructure) {
  if (typeof documentStructure === undefined || documentStructure === null) {return}
  if (typeof documentStructure !== 'object') {
    if (typeof documentStructure == 'number') {

      return `"${documentStructure.toString()}"`;
    }
    else if (typeof documentStructure === 'string') {
      if (documentStructure.indexOf(".") !== -1 && documentStructure.endsWith("0")) {
        return `"${documentStructure}"`;
      } else {
        return `"${documentStructure}"`;
      }
    } else {
      return `"${documentStructure}"`;
    }
  }

  let serializedString = "";

  for (const element of Object.entries(documentStructure)) {
    const name = element[0].toUpperCase();
    const value = element[1];

    if (!Array.isArray(value)) {
      serializedString += `"${name}"`;
      serializedString += serialize(value);
    } else {
      serializedString += `"${name}"`;
      for (const arrayElement of value) {
        serializedString += `"${name}"`;
        serializedString += serialize(arrayElement);
      }
    }
  }

  return serializedString;
}


function removeTrailingZeros(obj) {
  if (typeof obj !== 'object') {
    return obj.toString().replace(/0+$/, '') || '0';
  }

  const newObj = {};

  for (const [key, value] of Object.entries(obj)) {
    if (key !== 'documentTypeVersion') {
      newObj[key] = removeTrailingZeros(value);
    } else {
      newObj[key] = value;
    }
  }

  return newObj;
}


function hashSerializedOutput(documentStructure) {
  const hash = CryptoJS.SHA256(documentStructure).toString(CryptoJS.enc.Base64);
  return hash;
}
async function generateSignature(data) {
  // const cleanedData = removeTrailingZeros(data);
  console.log(data)
  const serializedData = serialize(data);
  console.log(serializedData)
  const hashedData = hashSerializedOutput(serializedData);
  // for testing uncomment the line below
  // return hashedData;
  //eslint-disable-next-line
  let signature = await SignerDigital.signHashCAdESEg(hashedData, "SHA256");
  return signature;
}

export default async function (invoice) {
  let signature;
 
  signature = await toast.promise(
    generateSignature(invoice),
    {
      pending: 'جاري إنشاء التوقيع الرقمي',
      success: 'تم إنشاء التوقيع الرقمي بنجاح',
      error: 'حدث خطأ أثناء إنشاء التوقيع الرقمي',
    }
  );
  const newInvoiceData = {
    ...invoice,
    signatures: [
      {
        signatureType: 'I',
        value: signature
      }
    ],
  };
  return newInvoiceData;
}
